import cookies from './util.cookies'
import db from './util.db'
import log from './util.log'
import request from '@/plugin/axios'
import constant from './constant'
import { divide, round, multiply, toNumber, add, subtract, slice, cloneDeep, uniq, sortBy } from 'lodash'
import {
    area
}
from '@/api/operate.base.js'

const util = {
    cookies,
    db,
    log
}

/**
 * 判断数组是否为空
 */
util.listNotNull = function(list) {
        if (Array.isArray(list) && list.length > 0) {
            return true
        } else {
            return false
        }
    }
    /**
     * 根据字典数据填充名称
     * @param {*} data
     * @param {*} dicList
     * @param {*} type
     * @param {*} typeName
     */
util.setFielNameByDic = function(data, dicList, type, typeName) {
    dicList.forEach(element => {
        if (element.dataKey == data[type]) {
            data[typeName] = element.dataValue
        }
    });
}

/**
 * 对一个数组内的明细取他的业务字段用逗号拼接成字符串
 * @param {*} el
 * @param {*} itemListName
 * @param {*} length
 * @param {*} businessName
 * @param {*} fieldName
 */
function joinItemListToString(el, itemListName, length, businessName, fieldName) {
    if (util.listNotNull(el[itemListName])) {
        var list = cloneDeep(el[itemListName])
        var arr = list.map(m => m[businessName])
            //去空
        var r = arr.filter(function(s) {
            return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
        });
        // 去重
        var uniqArr = uniq(r)
        removeByValue(uniqArr, "-")
        var uniqArrlist = uniqArr.length < length ? uniqArr : slice(uniqArr, 0, length - 1)
        var arrString = uniqArrlist.join(",")
        if (uniqArr.length >= length) {
            arrString = arrString + '...'
        }
        el[fieldName] = arrString
    }
}
util.joinItemListToString = joinItemListToString

// 判断是否为空值
export function isFalsy(val) {
    return (
        val === undefined ||
        val === null ||
        val === "" ||
        (val.constructor === Array && val.length === 0)
    );
}
util.isFalsy = isFalsy;
/**
 * 去除数组中的特定元素
 * @param {*}  arr
 * @param {*}  val
 */
function removeByValue(arr, val) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
            arr.splice(i, 1);
            break;
        }
    }
}
/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function(titleText) {
        const processTitle = process.env.VUE_APP_TITLE || 'zycf'
        window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function (url) {
  var a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'zycf-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('zycf-link-temp'))
}

/**
 * 基础request调用
 * @param  {[type]} data [description]
 * @param  {[type]} api  [description]
 * @return {[type]}      [description]
 */
util.baseRequest = function(data, api) {
  return request({
        url: api,
        method: 'post',
        data: data
    })
}

util.baseJsonRequest = function(data, api) {
  return request({
        url: api,
        method: 'json',
        data: data
    })
}

util.modalSubimtHandle = function(res) {
  if(constant.IS_SUCCESS(res)) {
    this.$success(constant.MSG_SAVE);
    this.cancel(true)
  }
}

/**
 * 递归数组转化成Tree(节点树具有根节点)
 * @param data object array
 * @param primaryKey 唯一标识的key
 * @param key 父节点标识key
 * @param value 父节点标识的值
 */
util.recursion = (data, primaryKey, key, value, sortFn = null) => {
  let result = []
  let temp
  for (const item of data) {
    if (item[key] == value) {
        item.value=item.id
        item.label=item.name
      temp = util.recursion(data, primaryKey, key, item[primaryKey], sortFn)
      if (temp.length) {
        item.children = temp
      }
      result.push(item)
    }
  }
  if (sortFn) {
    // @ts-ignore
    result = sortBy(result, sortFn)
  }
  return result
}

// 金额数值格式化 10000 --> 10,000.00，保留两位小数
util.moneyString = function(num) {
  var numF = Number(num)
  if(isNaN(numF)) {
    return 0
  }
  num = round(numF, 2)
  num = num || 0
  const sym = num < 0 ? '-' : ''
  const str = Math.abs(num).toString()
  let [int, dec] = str.split('.')
  dec = (dec || '00').padEnd(2, '0')
  const arr = int.split('')
  const temp = []
  for (let i = 1, len = arr.length; i <= len; i++) {
    temp.unshift(arr[len - i])
    if (i % 3 === 0 && i !== len) {
      temp.unshift(',')
    }
  }
  return sym + [temp.join(''), dec].join('.')
}


util.cacheArea = async function() {
  var areaList = []
  try {
    var areaRes = await area({
      pageSize: -1,
      asc: true
    });
    if(areaRes && util.listNotNull(areaRes.aaData)) {
      areaList = areaRes.aaData
    }
  } catch (error) {}
  await this.$store.dispatch("zycf/cache/areaList", areaList)
}
//去除对象属性值是字符串两边或者单纯的字符串两边的空格
util.RemoveSpace=function(content) {
    //对象

    if (!Array.isArray(content) && content != null && typeof (content) === 'object') {
        let {
            keys,
            values,
            entries
        } = Object;
        //let entries=Object.entries(content);
        for (let key of keys(content)) {
            if (typeof (content[key]) === 'string')
                content[key] = content[key].replace(/(^\s*)|(\s*$)/g, "");
        }

    }
    //字符串
    if (typeof (content) === 'string') {
         content=content.replace(/(^\s*)|(\s*$)/g, "");
    }
    return content;
}

util.timeSelectPickerOptionsDefault = function() {
  return {
    start: '00:00',
    end: '23:30'
  }
}

util.measureAmountRender = function(row, field) {//传过来的是重量千克
  var res = ''
  if(row.kg) {
    res = row[field] + '千克'
  }else {
    if(row.measureMethod && row.measureId) {
      //res = row[field] + row.measureMethod + '/' + round(multiply(row[field], row.weight), 3) + '千克'
      res = Math.ceil(divide(row[field], row.weight))+ row.measureMethod + '/' + row[field] + '千克'
    }else {
      res = row[field] + '千克'
    }
  }
  return res
}


/**
 *  @author zgli
 * @param precision 默认的最大精度
 *  添加对数字格式化，如果小数点后面有值，
 *  则按照精度处理，没有则去掉多余 0
 *  */
util.numbertoFixed = function(cellValue, precision) {
  var numF = Number(cellValue)
  if(isNaN(numF)) {
    return 0
  }
  cellValue = round(numF, precision)
  return cellValue;
};

export function renderEltag(h, row, index) {
  return (
    <el-tag>{row.id}</el-tag>
  )
}

//比较数组是否相同
util.compArray=function(array1,array2){
  if((array1&&typeof array1 ==="object"&&array1.constructor===Array)
      &&     (array2&&typeof array2 ==="object"&&array2.constructor===Array)){
      if(array1.length==array2.length){
          for(var i=0;i<array1.length;i++){
              var ggg=util.compObj(array1[i],array2[i]);
              if(!ggg){
                  return false;
              }
          }
      }
      else{
          return false;
      }
  }else{
      throw new Error("argunment is  error ;");
  }
  return true;
}
//比较两个对象是否相等
util.compObj=function(obj1,obj2){
  if(    (obj1&&typeof obj1==="object" && !(obj1.constructor==Array))
      &&     ((obj2 && typeof obj2==="object" && !(obj2.constructor==Array)))){
      var count1=util.propertyLength(obj1);
      var count2=util.propertyLength(obj2);
      if(count1==count2){
          for(var ob in obj1){
              if(obj1.hasOwnProperty(ob)&&obj2.hasOwnProperty(ob))
              {
                  if(obj1[ob].constructor==Array&&obj2[ob].constructor==Array){// 如果属性是数组
                      if(!util.compArray(obj1[ob],obj2[ob])){
                          return false;
                      };
                  }else if(typeof obj1[ob]==="object"&&typeof obj2[ob]==="object"){// 属性是对象
                      if(!util.compObj(obj1[ob],obj2[ob])){
                          return false;
                      };
                  }else if(obj1[ob] && obj2[ob] && typeof obj1[ob] === typeof obj2[ob]){// 纯属性
                      if(obj1[ob]!==obj2[ob]){
                          return false;
                      };
                  }else{
                      return false;
                  }
            }else{
                return false;
            }
          }
      }else{
          return false;
      }
  }else if((obj1 && obj1.constructor==Array) && (obj2 && obj2.constructor==Array)){
      if(!util.compArray(obj1,obj2)){
          return false;
      };
  }else if(obj1 && obj2){
      return obj1 === obj2;
  }else {
      return false;
  }
  return true;
}
//获得对象上的属性个数
util.propertyLength=function(obj){
  var count=0;
  if( obj && typeof obj==="object") {
      for(var ooo in obj) {
          if(obj.hasOwnProperty(ooo)) {
              count++;
          }
      }
      return count;
  }else {
      throw new Error("argunment can not be null;");
  }
}

/**
 * 将blob格式转换成文件并下载
 * @param blob 二进制数据
 * @param fileName 文件名称（注意保）
 */
export const blobToDownload = (blob, arg) => {
  let ext = '.xls'
  let fileName = ''

  if (blob.type === 'application/vnd.ms-excel') {
    ext = '.xls'
  } else if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    ext = '.xlsx'
  }
  if (typeof arg === 'string') {
    fileName = arg + ext
  } else if (arg && arg.headers) {
    fileName = decodeURIComponent(arg.headers['content-disposition'].split(';')[1]).split('=')[1].replace('+', ' ')
  }
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    let URL = window.URL || window.webkitURL
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

/**
 * 订单明细的总价计算算法
 * （销售数量-折扣数量）*销售价 + 折扣数量*（销售价*折扣比例） = 金额
 * @param {*} quantity 
 * @param {*} discountQuantity 
 * @param {*} price 
 * @param {*} discountRate 
 * @returns 
 */
util.orderItemAmount = function(quantity, discountQuantity, price, discountRate) {
  quantity = numberFormat(quantity)
  discountQuantity = numberFormat(discountQuantity)
  price = numberFormat(price)
  discountRate = numberFormat(discountRate)
  // 折扣比例转换成小数
  discountRate = discountRate ? round(divide(discountRate, 100), 2) : 0
	// 按照全额价格计算的数量
	var fullPriceQuantity = subtract(quantity, discountQuantity)
	// 按照全额价格计算的金额
	var fullPriceAmount = multiply(fullPriceQuantity, price)
	// 折扣单价
	var discountPrice = multiply(price, discountRate)
	// 折扣金额
	var discountAmount= multiply(discountPrice, discountQuantity)
	// 总金额
	return round(add(fullPriceAmount, discountAmount), 2)
}

function numberFormat(val) {
  if(typeof val === 'undefined') {
    val = 0
  }
  return toNumber(val)
}
util.numberFormat = numberFormat


export default util
