import request from '@/plugin/axios'

//获取盈亏单详情
export function GetProfitlossDetailList(data) {
    return request({
        url: '/kitchen/ProfitlossDetail/page',
        method: 'get',
        params: data
    })
}
