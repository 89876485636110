<template>
  <el-tooltip effect="dark" content="更新系统缓存" placement="bottom">
    <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
      <d2-icon name="refresh" style="font-size: 18px;" />
    </el-button>
  </el-tooltip>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      GetDataDic: "zycf/cache/GetDataDic"
    }),
    handleClick() {
      this.GetDataDic({})
        .then(d => {
          this.$message({
            message: "更新缓存成功",
            type: "success"
          });
        })
        .catch(err => {
          this.$message.error("更新缓存失败");
        });
    }
  }
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-updatecache/index.vue"
}
</vue-filename-injector>
