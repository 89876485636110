<template>
  <div>
    <el-dropdown size="small" class="d2-mr">
      <span class="btn-text">{{info.name ? `你好 ${info.name}` : '未登录'}}</span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="logOff">
          <d2-icon name="power-off" class="d2-mr-5" />注销
        </el-dropdown-item>
        <el-dropdown-item @click.native="updatePass">
          <d2-icon name="wrench" />修改密码
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <UpdatePass v-if="showmodal" :modal="showmodal" @closemodal="closemodal" />
  </div>
</template>

<script>
import UpdatePass from "./components/UpdatePass.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { UpdatePass },
  data() {
    return {
      showmodal: false,
    };
  },
  computed: {
    ...mapState("zycf/user", ["info"])
  },
  mounted(){
  },
  methods: {
    ...mapActions("zycf/account", ["logout"]),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true
      });
      this.$parent.closeMeassageTime();
    },
    closemodal() {
      this.showmodal = false;
    },
    updatePass() {
      this.showmodal = true;
    },
  }
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
