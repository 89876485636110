import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/category'

var obj = {}
obj.page = function(data) {
    return util.baseRequest(data, rootURL + '/page')
}
obj.getCateGoryTreeList = function(data) {
    return util.baseRequest(data, rootURL + '/getCateGoryTreeList')
}
export default obj