import request from '@/plugin/axios'

// 获取新消息
export const getNewCount = params => request({
  url: '/user/remindMessage/getAllNoReadMsg',
  method: 'post',
  data: params || {}
})
// 删除新消息
export const delNewMsg = idList => request({
  url: '/user/remindMessage/delete',
  method: 'post',
  data: { idList: idList }
})

// 获取所有消息
export const getMsgList = params => request({
  url: '/user/remindMessageHistory/page',
  method: 'post',
  data: params || {}
})

// 读新消息
export const readMsg = idList => request({
  url: '/user/remindMessage/readMessage',
  method: 'post',
  data: { msgId: idList }
})

// 全部已读所有消息
export const allReadMsg = params => request({
  url: '/user/remindMessage/allReadMessage',
  method: 'post',
  data: params || {}
})
