import Vue from 'vue'

import d2Container from './d2-container'

import MultipleFormat from './format-input/multiple-format.vue'

import BaseSelectionDialog from './base-selection-dialog/index.vue'

import multiSelectDialog from './multi-select-dialog/index.vue'
import singleSelectDialog from './single-select-dialog/index.vue'

import tableContainer from './table-container/index.vue'

import BaseDialog from './base-dialog/index.vue'

import BaseDialogForm from './base-dialog/base-dialog-form.vue'

import BaseFormDialog from './base-dialog/base-form-dialog.vue'

import tableExport from './table-export'

import exportExcel from './export-excel'

import exportExcelDropdown from './export-excel-dropdown'

import exportDialog from './export-dialog'

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container)
Vue.component('d2-icon', () => import('./d2-icon'))
Vue.component('d2-icon-svg', () => import('./d2-icon-svg/index.vue'))

//自定义公共组件
Vue.component('EU-elTag', () => import('./zycf-components/components/EU-elTag.vue'))
Vue.component('EU-Img', () => import('./zycf-components/components/EU-Img.vue'))
Vue.component('EU-FenToYuan', () => import('./zycf-components/components/EU-FenToYuan.vue'))
Vue.component('tableOpsBox', () => import('./zycf-components/components/tableOpsBox.vue'))
Vue.component('xyNumeric', () => import('./zycf-components/components/xyNumeric.vue'))
Vue.component('tableOpsBoxBottom', () => import('./zycf-components/components/tableOpsBoxBottom.vue'))
Vue.component('EU-Process', () => import('./zycf-components/components/EU-Process.vue'))
Vue.component('EU-elSelect', () => import('./zycf-components/components/EU-elSelect.vue'))
Vue.component('EU-Unit', () => import('./zycf-components/components/EU-Unit.vue'))
Vue.component('EU-Switch', () => import('./zycf-components/components/EU-Switch.vue'))
Vue.component('MoreConditionBtn', () => import('./zycf-components/components/MoreConditionBtn.vue'))
Vue.component('tableTag', () => import('./zycf-components/components/tableTag.vue'))
Vue.component(MultipleFormat.name, MultipleFormat)

Vue.component(BaseSelectionDialog.name, BaseSelectionDialog)

Vue.component(tableContainer.name, tableContainer)

Vue.component(BaseDialog.name, BaseDialog)

Vue.component(BaseDialogForm.name, BaseDialogForm)

Vue.component(BaseFormDialog.name, BaseFormDialog)

Vue.component(tableExport.name, tableExport)

Vue.component(exportExcel.name, exportExcel)

Vue.component(exportDialog.name, exportDialog)

Vue.component(exportExcelDropdown.name, exportExcelDropdown)

Vue.component(multiSelectDialog.name, multiSelectDialog)
Vue.component(singleSelectDialog.name, singleSelectDialog)
