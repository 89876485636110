import layoutHeaderAside from '@/layout/header-aside'

import orderRouter from './order.js'
import purchaseRouter from './purchase.js'
import detectionRouter from './detection.js'
import baseRouter from './base.js'
import productionRouter from './production.js'
import sortRouter from './sorting.js'
import distributionRouter from './distribution.js'
import warehouseRouter from './warehouse.js'
import suyuanRouter from './suyuan.js'
import developRouter from './develop.js'
import userroleRouter from './userrole.js'
import systemRouter from './system.js'
import producegoodsRouter from './producegoods.js'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const meta = {
  auth: false,
  cache: false
}

/**基础信息 */
export  let operate={
  path: '/operate',
  name: 'operate',
  meta,
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: (pre => [
    ]
    .concat(baseRouter(pre, _import, meta))//基础信息:商品信息、仓库管理、客户管理、供应商管理、设备管理
    .concat(producegoodsRouter(pre, _import, meta))//生产资料
    .concat(userroleRouter(pre, _import, meta))//用户权限
    .concat(suyuanRouter(pre, _import, meta))//溯源管理
  )('operate-')
};
/**供应链 */
export  let supplychain={
    path: '/supplychain',
    name: 'supplychain',
    meta,
    redirect: {
      name: 'index'
    },
    component: layoutHeaderAside,
    children: (pre => [
      ]
      .concat(orderRouter(pre, _import, meta))//订单管理
      .concat(purchaseRouter(pre, _import, meta))//采购管理
      .concat(detectionRouter(pre, _import, meta))//检疫检测
      .concat(sortRouter(pre, _import, meta))//分拣管理
      .concat(distributionRouter(pre, _import, meta))//配送管理
    )('operate-')
  };
/**生产管理 */
export  let production={
  path: '/production',
  name: 'production',
  meta,
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: (pre => [
    ]
    .concat(productionRouter(pre, _import, meta))//生产管理
  )('operate-')
};
/**库存管理 */
export  let warehouse={
  path: '/warehouse',
  name: 'warehouse',
  meta,
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: (pre => [
    ]
    .concat(warehouseRouter(pre, _import, meta))//库存管理
  )('operate-')
};
/**开发人员工具 */
export  let develop={
  path: '/develop',
  name: 'develop',
  meta,
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: (pre => [
    ]
    .concat(developRouter(pre, _import, meta))//开发人员工具
  )('operate-')
};
/**系统 */
export  let system={
    path: '/system',
    name: 'system',
    meta,
    redirect: {
      name: 'index'
    },
    component: layoutHeaderAside,
    children: (pre => [
      ]
      .concat(systemRouter(pre, _import, meta))//系统
    )('operate-')
  };
