import regmixin from './mixin_reg'
import orderMixin from './order.js'
import d2crudmixin from './mixin_d2_crud'
import datemixin from './mixin_reg'
import pageSearchForm from './page-search-form'
import { cloneDeep, subtract, round } from 'lodash'
import dialogDrag from '@/libs/dialog-drag'
import CryptoJS from 'crypto-js'
import util from '@/libs/util.js'
import { MONEY_MAX } from '@/libs/validator'
import { order, orderExternal } from '@/api/order'
import constant from '@/libs/constant.js'
import { dbGet } from '@/libs/util.db'
import moment from 'moment'
var api = {
  queryProgress() {
    return util.baseRequest({}, '/kitchen/Progress/queryProgress')
  },
  startProgress(content) {
    return util.baseRequest({content}, '/kitchen/Progress/start')
  }
}
export default {
  mixins: [regmixin, d2crudmixin, datemixin, pageSearchForm, orderMixin],
  directives: {
    dialogDrag,
  },
  data() {
    return {
      round: round,
      selectRows: [],
      loading: false,
      orderBy: '',
      asc: null,
      tableExport: false,
      moment: moment,
      moreCondition: false,
      printData: [],
      exportNumbers: 100000, //导出默认条数
      exportExcel: false,
      exportLoading: false,
    }
  },
  computed: {
    //获取缓存中的数据字典
    _DataDic() {
      return this.$store.state.zycf.cache.DataDic || []
    },
    //获取缓存中的单位列表
    _MeasureList() {
      return this.$store.state.zycf.cache.MeasureList || []
    },
    //获取缓存中的系统列表
    _SysList() {
      return this.$store.state.zycf.cache.SysList || []
    },
    //获取当前登录人的用户信息
    _thisUser() {
      return this.$store.state.zycf.user.userinfo || {}
    },
    //获取该账户负责的仓库信息
    _permissonWarehouse() {
      return this.$store.state.zycf.cache.permissonWarehouse || {}
    },
    //获取该账户负责的供应商信息
    _permissonSupplier() {
      return this.$store.state.zycf.cache.permissonSupplier || {}
    },
  },
  mounted() {},
  methods: {
    exportExcelCancel() {
      this.exportExcel = false
    },
    async baseHandleSearchTable(fn, api) {
      this.loading = true
      const params = { ...this.form }
      Object.assign(params, {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      })
      fn(params)
      try {
        const result = await api(params)
        if (constant.IS_SUCCESS(result)) {
          this.tabledata = cloneDeep(result.aaData) || []
          this.pagination.total = result.dataCount
        } else {
          this.tabledata = []
          this.pagination.total = 0
          this.$warning(result.msg)
        }
      } catch (error) {
        this.tabledata = []
        this.pagination.total = 0
        this.$warning(error)
      }
      this.loading = false
    },
    /**
        金额数值格式化 10000 --> 10,000.00
         */
    moneyString(num) {
      return util.moneyString(num)
    },
    //筛选出某类型的的数据字典的MAP类型 （找到某个类型数据字典）
    _searchDicMap(dataType) {
      let map = {}      
      this._DataDic.forEach(item => {
        if (item.dataType === dataType) {
          map[item.dataKey] = item.dataValue
        }
      })
      return map
    },
    //筛选出某类型的的数据字典 （找到某个类型数据字典）
    _searchDic(datatype, { extendVal = null } = {}) {
      let key_val = this._DataDic.filter((item, index, arr) => {
        if (extendVal != null) {
          return item.dataType == datatype && item.extendVal == extendVal
        } else {
          return item.dataType == datatype
        }
      })
      return key_val || []
    },
    //根据传入的key值找到value值（数据字典查找）
    _dicValue(key, dic) {
      let value = ''
      dic.forEach(function (el) {
        if (key + '' == el.dataKey + '') {
          value = el.dataValue
        }
        if (typeof key == 'boolean' && typeof el.dataKey == 'string') {
          if (Number(key) == el.dataKey) {
            value = el.dataValue
          }
        }
      })
      return value
    },
    //根据传入的code值找到name值 (单位查找)
    _measureNameByCode(code) {
      let value = ''
      this._MeasureList.forEach(function (el) {
        if (code + '' === el.code + '') {
          value = el.name
        }
      })
      return value
    },
    //根据传入的code值找到对象 (单位查找)
    _measureObjectByCode(code) {
      let value = {}
      this._MeasureList.forEach(function (el) {
        if (code + '' === el.code + '') {
          value = el
        }
      })
      return value
    },
    //根据传入的id值找到name值 (单位查找)
    _measureNameById(id) {
      let value = ''
      this._MeasureList.forEach(function (el) {
        if (id + '' === el.id + '') {
          value = el.name
        }
      })
      return value
    },
    //根据传入的id值找到name值 (系统查找)
    _sysName(id) {
      let value = ''
      this._SysList.forEach(function (el) {
        if (id + '' == el.id + '') {
          value = el.name
        }
      })
      return value
    },
    //获取距离当天的时间
    _getDate(n) {
      const date = moment().subtract(n, 'days').format('YYYY-MM-DD')
      return date
    },
    //获取当前时间
    _getCurrentDate(n) {
      const date = moment().subtract(n, 'days').format('YYYY-MM-DD HH:mm:ss')
      return date
    },
    //获取当前时间(只有 年，月 YYYY-MM)
    _getCurrentDateMonth(n) {
      const date = moment().subtract(n, 'days').format('YYYY-MM')
      return date
    },
    _dateFormat({ date = '', type = 'YYYY-MM-DD' } = {}) {
      if (date) {
        return moment(date).format(type)
      } else {
        return ''
      }
    },
    _momentAddDay({ days = 1, format = 'YYYY-MM-DD' } = {}) {
      return moment().add(days, 'days').format(format)
    },
    _momentSubDay({ days = 1, format = 'YYYY-MM-DD' } = {}) {
      return moment().subtract(days, 'days').format(format)
    },
    _momentSubMonths({ months = 1, format = 'YYYY-MM-DD' } = {}) {
      return moment().subtract(months, 'months').format(format)
    },
    _moment({ format = 'YYYY-MM-DD' } = {}) {
      return moment().format(format)
    },
    /**
     * 获取两个日期之间的所有日期,返回日期数组
     * @return {[type]} [description]
     */
    _rangeDate(arr) {
      if (!Array.isArray(arr) || arr.length != 2) {
        return []
      }
      //初始化起始日期和终止日期
      var startDate = moment(arr[0])
      var endDate = moment(arr[1])
      if (startDate.isSame(endDate)) {
        return startDate.format('YYYY-MM-DD')
      }
      if (!startDate.isBefore(endDate)) {
        var tmp = cloneDeep(startDate)
        startDate = cloneDeep(endDate)
        endDate = cloneDeep(tmp)
      }
      // 计算相隔多少天
      var count = endDate.diff(startDate, 'days')
      // 组装返回值
      var res = []
      while (count >= 0) {
        var tmp = cloneDeep(endDate)
        res.push(tmp.subtract(count, 'days').format('YYYY-MM-DD'))
        count--
      }
      return res
    },
    //A4纸打印，传入HTML标签ID即可
    //(index)为循环下标，只有下标为0或未传时才弹出提示框，避免循环多次弹出提示框
    //type 打印类型0：预览，1：直接打印
    lodopoPreviewA4(id, index, type) {
      if (typeof LODOP === 'undefined') {
        if (index < 1 || !index) {
          this.$notify({
            title: '提示',
            type: 'warning',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: "<br><font  style='float: right;'>打印控件未安装!点击这里<a href='print/CLodop_Setup_for_Win32NT.exe' target='_self' style='color:blue'>下载并安装</a>,安装后请刷新页面或重新进入。</font>",
          })
        }
      } else {
        LODOP.PRINT_INIT(id)
        LODOP.ADD_PRINT_TEXT('1%', '1%', '30%', '10%', '页数：第#页/共&页')
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 2)
        LODOP.SET_PRINT_STYLEA(0, 'Horient', 0)
        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 0)
        LODOP.ADD_PRINT_HTM('3%', '3%', '97%', '100%', document.getElementById(id) ? document.getElementById(id).innerHTML : id)
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 13)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 4)
        LODOP.SET_PRINT_STYLEA(0, 'Horient', 3)
        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 3)
        LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4')
        LODOP.SET_LICENSES('成都九洲电子信息系统股份有限公司', '1DAA3FA17DD9EF73566A29AC39CE300C', '成都九洲電子信息系統股份有限公司', 'F0F8A487D1A0DEBA028F42800034942A')
        LODOP.SET_LICENSES('THIRD LICENSE', '', 'Chengdu Jiuzhou Electronic Information System Co., Ltd.', '1E6D08DE79B42A3871D5EF3423D77853')
        if (type === 1) {
          LODOP.PRINT()
        } else {
          LODOP.PREVIEW()
        }
      }
    },
    //A4纸打印，传入HTML标签ID即可
    //(index)为循环下标，只有下标为0或未传时才弹出提示框，避免循环多次弹出提示框
    //type 打印类型0：预览，1：直接打印
    lodopoPreviewA4H(id, index, type) {
      if (typeof LODOP === 'undefined') {
        if (index < 1 || !index) {
          this.$notify({
            title: '提示',
            type: 'warning',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: "<br><font  style='float: right;'>打印控件未安装!点击这里<a href='print/CLodop_Setup_for_Win32NT.exe' target='_self' style='color:blue'>下载并安装</a>,安装后请刷新页面或重新进入。</font>",
          })
        }
      } else {
        LODOP.PRINT_INIT(id)
        LODOP.ADD_PRINT_TEXT('1%', '1%', '30%', '10%', '页数：第#页/共&页')
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 2)
        LODOP.SET_PRINT_STYLEA(0, 'Horient', 0)
        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 0)
        LODOP.ADD_PRINT_HTM('3%', '3%', '97%', '100%', document.getElementById(id) ? document.getElementById(id).innerHTML : id)
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 13)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 4)
        LODOP.SET_PRINT_STYLEA(0, 'Horient', 3)
        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 3)
        LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4')
        LODOP.SET_LICENSES('成都九洲电子信息系统股份有限公司', '1DAA3FA17DD9EF73566A29AC39CE300C', '成都九洲電子信息系統股份有限公司', 'F0F8A487D1A0DEBA028F42800034942A')
        LODOP.SET_LICENSES('THIRD LICENSE', '', 'Chengdu Jiuzhou Electronic Information System Co., Ltd.', '1E6D08DE79B42A3871D5EF3423D77853')
        if (type === 1) {
          LODOP.PRINT()
        } else {
          LODOP.PREVIEW()
        }
      }
    },
    lodopoPrint(top, left, width, height, html, length) {
      var height = 930
      if (length && length > 7) {
        var count = length - 7
        var page = Math.ceil(count / 11)
        height = height + page * 930
      }
      if (typeof LODOP === 'undefined') {
        this.$message({
          showClose: true,
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: "<br><font color='#FF00FF' style='margin-top: 35px;float: right;'>打印控件未安装!点击这里<a href='CLodop_Setup_for_Win32NT.exe' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>",
        })
      } else {
        LODOP.PRINT_INIT('')
        LODOP.SET_PRINT_PAGESIZE(1, 2100, height, '')
        LODOP.ADD_PRINT_HTM(top, left, width, height, html)
        LODOP.SET_PRINT_MODE('RESELECT_PRINTER', true)
        LODOP.SET_PRINT_MODE('RESELECT_ORIENT', true)
        LODOP.SET_PRINT_MODE('RESELECT_PAGESIZE', true)
        LODOP.SET_PRINT_MODE('RESELECT_COPIES', true)
        LODOP.PREVIEW()
      }
    },
    GprinterPreview(html, url) {
      LODOP.PRINT_INIT('')
      LODOP.SET_PRINT_PAGESIZE(1, 600, 500, '')
      LODOP.ADD_PRINT_HTM(5, 5, '100%', '100%', html)
      LODOP.ADD_PRINT_BARCODE(62, 135, 94, 94, 'QRCode', url)
      LODOP.SET_PRINT_MODE('RESELECT_PRINTER', true)
      LODOP.SET_PRINT_MODE('RESELECT_ORIENT', true)
      LODOP.SET_PRINT_MODE('RESELECT_PAGESIZE', true)
      LODOP.SET_PRINT_MODE('RESELECT_COPIES', true)
      LODOP.PRINT()
    },
    _renderPrintHtml(html) {
      for (var i = 1; i < arguments.length; i++) {
        var reg = new RegExp('{{args' + i + '}}', 'g')
        html = html.replace(reg, arguments[i])
      }
      return html
    },
    SelectAsDefaultPrinter(fn) {
      if (LODOP.CVERSION) {
        LODOP.On_Return = function (TaskID, Value) {
          if (Value >= 0) {
            fn()
          }
        }
        LODOP.SELECT_PRINTER()
        return
      }
      if (LODOP.SELECT_PRINTER() >= 0) {
        fn()
      }
    },
    //AES加密算法
    _AESEncrypt(content) {
      if (typeof content != 'boolean' && typeof content != 'number' && typeof content != 'string') {
        throw new Error('加密内容只能是布尔、数字、字符串类型')
      }
      let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_KEY)
      let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV)
      let content_Utf8 = CryptoJS.enc.Utf8.parse(content)
      let AES_Content = CryptoJS.AES.encrypt(content_Utf8, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
      return AES_Content.toString()
    },
    //AES解密算法
    _AESDecrypt(content) {
      let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_KEY)
      let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV)
      var decrypted = CryptoJS.AES.decrypt(content, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
      return decrypted.toString(CryptoJS.enc.Utf8)
    },
    async getArea(type) {
      if (!util.listNotNull(this.$store.state.zycf.cache[type])) {
        await util.cacheArea.call(this)
      }
      return this.$store.state.zycf.cache[type]
    },
    //去除对象属性值是字符串两边或者单纯的字符串两边的空格
    _RemoveSpace(content) {
      //对象
      if (!Array.isArray(content) && content != null && typeof content === 'object') {
        let { keys, values, entries } = Object
        //let entries=Object.entries(content);
        for (let key of keys(content)) {
          if (typeof content[key] === 'string') content[key] = content[key].replace(/(^\s*)|(\s*$)/g, '')
        }
      }
      //字符串
      if (typeof content === 'string') {
        content = content.replace(/(^\s*)|(\s*$)/g, '')
      }
      return content
    },
    async sortChange(param) {
      this.orderBy = param.prop
      this.asc = param.order == 'descending' ? false : true
      this.handleSearchTable()
    },
    tableExportCancel() {
      this.tableExport = false
    },
    /**
        判断两个时间的大小 s < e 返回负数, s == e 返回0 s > e 返回整数
         */
    compareTime(s, e) {
      var a = this.moment('2020-01-01 ' + s).valueOf()
      var b = this.moment('2020-01-01 ' + e).valueOf()
      return subtract(a, b)
    },
    statusSwitch(row, value) {
      if (value) {
        this.confirmInner([row.id])
      } else {
        this.cancelInner([row.id])
      }
    },
    statusSwitchCol() {
      return {
        title: '启用/停用',
        key: 'statusSwitch',
        component: {
          render: (h, row, index) => {
            var res = row.status ? true : false
            return (
              <el-switch
                v-model={res}
                onChange={() => {
                  this.statusSwitch(row, res)
                }}
              ></el-switch>
            )
          },
        },
      }
    },
    moneyMax(val) {
      if (+val >= MONEY_MAX) {
        throw '超过最大金额限制'
      }
    },
    /**
        订单状态撤回
         */
    async orderStatusBack() {
      if (this.selectRows.length === 0) {
        this.$message({ type: 'warning', message: '请选择行' })
      } else {
        var res = await order.statusBack({ ids: this.selectRows.map(row => row.id) })
        if (constant.IS_SUCCESS(res)) {
          if (util.listNotNull(res.aaData)) {
            this.$warning(res.msg)
          } else {
            this.$success(constant.MSG_OPS)
          }
          this.handleSearchTable()
        } else {
          this.handleSearchTable()
        }
      }
    },
    async orderPrintHandle(dataList, itemSourceList) {
      if (dataList.length === 0) {
        this.$message({ type: 'warning', message: '请选择行' })
        return
      }
      dataList.forEach(el => {
        el.distributionTimeBegin = el.distributionDate + ' ' + el.distributionTimeBegin
        el.distributionTimeEnd = el.distributionDate + ' ' + el.distributionTimeEnd
        var arr = []
        if (util.listNotNull(el.itemVOList)) {
          el.itemVOList.forEach(em => {
            itemSourceList.forEach(ol => {
              if (em.source == ol.dataKey) {
                em.sourceName = ol.dataValue
              }
            })
            var o = {
              id: em.id,
              fullName: em.productName,
              quantityM: em.quantity + em.measureMethod,
              priceM: em.price + '元/' + em.measureMethod,
              itemAmount: em.itemAmount + '元',
              sourceName: em.sourceName,
            }
            arr.push(o)
          })
        }
        el.printItemList = cloneDeep(arr)
      })
      let length = dataList.length
      if (length > 1) {
        //直接打印
        for (let i = 0; i < length; i++) {
          this.printData = dataList[i]
          await this.$nextTick()
          this.lodopoPreviewA4('printTemple', i, 1)
        }
      } else {
        //预览
        this.printData = dataList[0]
        this.$nextTick(() => {
          this.lodopoPreviewA4('printTemple', 0, 0)
        })
      }
    },
    /**
        手动获取按钮权限
         */
    async btnAuthorize(value, pageroute) {
      const ModuleButton = await dbGet({
        dbName: 'sys',
        path: 'buttonItemMap',
        defaultValue: [],
        user: true,
      })

      //得到该页面的权限按钮
      const pagebutton = ModuleButton[pageroute] || []
      //找出该按钮是否在权限内
      const check = pagebutton.filter((item, idex, arr) => {
        return item.name == value
      })
      return check.length <= 0 ? false : true
    },
    async confirm(type) {
      var msg = ''
      if (type == 'del') {
        msg = '删除操作不可逆，确定删除？'
      }
      return await this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
    async ifOrderConf(type) {
      var res = await orderExternal.isConfEnable({ type })
      if (constant.IS_SUCCESS(res)) {
        return res.aaData
      } else {
        return false
      }
    },
    downloadTemplate(url, download) {
      if (!url) {
        return
      }
      let a = document.createElement('a')
      a.setAttribute('href', url)
      // a.setAttribute('download', download)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    exportJsonToExcel({ dataList, filterVal, filename, header }) {
      import('@/plugin/export/Export2Excel').then(
        excel => {
          const data = this.formatJson(filterVal, dataList)
          excel.export_json_to_excel({
            header: header,
            data,
            filename: filename,
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.exportLoading = false
        },
        err => {
          this.exportLoading = false
        },
      )
    },
    exportJsonToSheetList({ sheetList, filename }) {      
      sheetList.forEach(el => {
        const data = this.formatJson(el.filterVal, el.data)
        el.data = cloneDeep(data)
      })
      import('@/plugin/export/Export2Excel').then(
        excel => {
          excel.export_json_to_sheetList({
            sheetList,
            filename,
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.exportLoading = false
        },
        err => {
          this.exportLoading = false
        },
      )
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }),
      )
    },
    async delConfirm() {
      await this.$confirm('删除操作不可逆，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
    async queryProgress(loading) {
      var res = await api.queryProgress()
      if(res.state == 0) {
        if(res.aaData) {
          var progress = {...res.aaData}
          if(progress && progress.status === 'ing' && progress.content.length > 0) {
            loading.setText(progress.content[progress.content.length - 1])
            setTimeout(() => {
              this.queryProgress(loading)
            }, 500);
          }else {
            loading.close();
            return
          }          
        }else {
          loading.close();
          return
        }        
      }else {
        loading.close();
        return
      }      
    },
    async startProgress() {
      await api.startProgress('')
      var loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.queryProgress(loading)
    },
  },
}
