import request from '@/plugin/axios'
import util from '@/libs/util.js'
// 常用菜单
// export function quickNavigationSave(data) {
//   return util.baseJsonRequest({
//     url: '/kitchen/quickNavigation/save',
//     method: 'json',
//     data
//   })
// }
var obj = {}
obj.quickNavigationSave = function(data) {
  return util.baseJsonRequest(data, '/kitchen/quickNavigation/save')
}
obj.quickNavigationQuick = function(data) {
  return util.baseRequest(data, '/kitchen/quickNavigation/quick')
}
export default obj
