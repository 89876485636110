<template>
  <el-tooltip effect="dark" content="搜索菜单" placement="bottom">
    <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
      <d2-icon name="search" style="font-size: 18px;" />
    </el-button>
  </el-tooltip>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-search/index.vue"
}
</vue-filename-injector>
