export default {
    data() {
        return {            
            orderByList: [],
            orderByListRows: [],
            orderByListShow: false,
            orderByListBusinessFiledName: 'value'
        }
    },
    methods: {
        orderByOpen() {
            this.orderByListRows = this.$refs.orderBy.dataRow
            this.orderByListShow = true
        },
        orderByClose() {
            this.orderByListShow = false
        },
        orderByListsubmit(rows) {
          this.$refs.orderBy.setList(rows, 'name', 'value')
          this.orderByClose()     
        },
    }
}