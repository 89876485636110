/**
 * 采购管理路由
 */
export default function (pre, _import, meta) {
  return [
    {
      path: 'purchase/demand',
      name: `${pre}purchase-demand`,
      component: _import('operate/purchase/demand/index'),
      meta: {
        ...meta,
        title: '采购汇总'
      }
    },
    {
      path: 'purchase/purorder',
      name: 'purchasesorder',
      component: _import('operate/purchase/order/index'),
      meta: {
        ...meta,
        title: '采购订单管理'
      }
    },
    {
      path: 'purchase/invoice',
      name: `${pre}purchase-invoice`,
      component: _import('operate/purchase/invoice/index'),
      meta: {
        ...meta,
        title: '发票信息登记'
      }
    },
    {
      path: 'purchase/spread',
      name: `${pre}purchase-spread`,
      component: _import('operate/purchase/spread/index'),
      meta: {
        ...meta,
        title: '红蓝回冲单'
      }
    },
    {
      path: 'purchase/person',
      name: `${pre}purchase-person`,
      component: _import('operate/purchase/person/index'),

      meta: {
        ...meta,
        title: '采购员管理'
      }
    },
    {
      path: 'purchase/purtask',
      name: `${pre}purchase-task`,
      component: _import('operate/purchase/task/index'),
      meta: {
        ...meta,
        title: '采购任务'
      }
    },
    {
      path: 'purchase/inquiry',
      name: `${pre}purchase-inquiry`,
      component: _import('operate/purchase/inquiry/index'),
      meta: {
        ...meta,
        title: '询价管理'
      }
    },
    {
      path: 'purchase/returnorder',
      name: `${pre}purchase-returnorder`,
      component: _import('operate/purchase/returnorder/index'),
      meta: {
        ...meta,
        title: '采购退货'
      }
    },
    {
      path: 'purchase/purchaseReport',
      name: `${pre}purchase-report`,
      component: _import('operate/purchase/report/index'),
      meta: {
        ...meta,
        title: '采购报表'
      }
    },
    {
      path: 'purchase/estimation',
      name: `${pre}purchase-estimation`,
      component: _import('operate/purchase/estimation/index'),
      meta: {
        ...meta,
        title: '暂估发票'
      }
    }
  ]
}
