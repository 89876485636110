<template>
  <div class="d2-layout-header-aside-group" :style="styleLayoutMainGroup" :class="{grayMode: grayActive}">
    <!-- 半透明遮罩 -->
    <div class="d2-layout-header-aside-mask"></div>
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content" flex="dir:top">
      <!-- 顶栏 -->
      <div class="d2-theme-header" :style="{
          opacity: this.searchActive ? 0.5 : 1
        }" flex-box="0" flex>
        <div class="logo-group" :style="{width: asideCollapse ? asideWidthCollapse : asideWidth}" flex-box="0">
          <img
            v-if="asideCollapse"
            :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/icononly.png`"
            style="width:36px;height:34px;margin-top:12px;"
          />
          <img
            v-else
            :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/${$isProcess?'logo-process.png':'logo-purchase.png'}`"
            style="width:125px;height:37px;margin-top:11px;"
          />
        </div>
        <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0">
          <d2-icon name="bars" />
        </div>
        <d2-menu-header flex-box="1" />
        <!-- 顶栏右侧 -->
        <div class="d2-header-right" flex-box="0">
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
          <d2-header-changyong />
          <d2BanBen />
          <d2-header-beidou />
          <d2-header-backlog ref="backlogREF" />
          <d2-header-message ref="messageREF" />
          <d2Headerclearbrowercache />
          <d2-Header-Updatecache />
          <d2-header-search @click="handleSearchClick" />
          <!-- <d2-header-log/> -->
          <d2-header-fullscreen />
          <!-- <d2-header-theme/>  -->
          <!--<d2-header-size/> -->
          <!-- <d2-header-locales/>
          <d2-header-color/>-->
          <d2-header-user />
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <div
          flex-box="0"
          ref="aside"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }"
        >
          <d2-menu-side />
        </div>
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex>
          <!-- 搜索 -->
          <transition name="fade-scale">
            <div v-if="searchActive" class="d2-theme-container-main-layer" flex>
              <d2-panel-search ref="panelSearch" @close="searchPanelClose" />
            </div>
          </transition>
          <!-- 内容 -->
          <transition name="fade-scale">
            <div v-if="!searchActive" class="d2-theme-container-main-layer" flex="dir:top">
              <!-- tab -->
              <div class="d2-theme-container-main-header" flex-box="0">
                <d2-tabs />
              </div>
              <!-- 页面 -->
              <div class="d2-theme-container-main-body" flex-box="1">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :exclude="['warehouseStockcheckAdd']">
                    <router-view />
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import d2MenuSide from './components/menu-side'
import d2MenuHeader from './components/menu-header'
import d2Tabs from './components/tabs'
import d2HeaderFullscreen from './components/header-fullscreen'
import d2HeaderLocales from './components/header-locales'
import d2HeaderSearch from './components/header-search'
import d2HeaderSize from './components/header-size'
import d2HeaderTheme from './components/header-theme'
import d2HeaderUser from './components/header-user'
import d2HeaderLog from './components/header-log'
import d2HeaderColor from './components/header-color'
import d2HeaderUpdatecache from './components/header-updatecache'
import d2HeaderMessage from './components/header-message/msg.vue'
import d2HeaderBeidou from './components/header-beidou'
import d2HeaderChangyong from './components/header-changyong'
import d2HeaderBacklog from './components/header-message/backlog.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import d2BanBen from './components/header-banben/index.vue'
import mixinSearch from './mixins/search'
import d2Headerclearbrowercache from './components/header-clearbrowercache'
export default {
  name: 'd2-layout-header-aside',
  mixins: [mixinSearch],
  components: {
    d2MenuSide,
    d2MenuHeader,
    d2Tabs,
    d2BanBen,
    d2HeaderFullscreen,
    d2HeaderLocales,
    d2HeaderSearch,
    d2HeaderBeidou,
    d2HeaderSize,
    d2HeaderTheme,
    d2HeaderUser,
    d2HeaderLog,
    d2HeaderMessage,
    d2HeaderBacklog,
    d2HeaderColor,
    d2HeaderUpdatecache,
    d2Headerclearbrowercache,
    d2HeaderChangyong
  },
  data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '180px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '64px'
    }
  },
  computed: {
    ...mapState('zycf', {
      /**
       * 切换选项卡后内容页面刷新
       */
      // keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse,
      pageOpenedList: state => state.page.opened
    }),
    ...mapGetters('zycf', {
      themeActiveSetting: 'theme/activeSetting'
    }),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup() {
      return {
        ...(this.themeActiveSetting.backgroundImage
          ? {
              backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`
            }
          : {})
      }
    }
  },
  methods: {
    ...mapActions('zycf/menu', ['asideCollapseToggle']),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle()
    },
    openBackLog() {
      this.$refs.backlogREF.openDiag()
    },
    closeMeassageTime() {
      this.$refs.messageREF.cloaseTimer()
    }
  }
}
</script>

<style lang="scss">
// 注册主题
@import '~@/assets/style/theme/register.scss';
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/layout.vue"
}
</vue-filename-injector>
