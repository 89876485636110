import request from '@/plugin/axios';
import util from '@/libs/util.js';

var rootURL = '/kitchen/order';

var obj = {};
obj.orderPageList = function (data) {
  return util.baseRequest(data, rootURL + '/page');
};
obj.contractOrderPageList = function (data) {
  return util.baseRequest(data, rootURL + '/contractOrderPage');
};
obj.orderCostPage = function (data) {
  return util.baseRequest(data, rootURL + '/orderCostPage');
};
obj.singleOrderPageList = function (data) {
  return util.baseRequest(data, rootURL + '/singleOrderPage');
};
obj.singleOrderPageListT = function (data) {
  return util.baseRequest(data, rootURL + '/singleOrderPageT');
};
obj.SingleOrderDayCount = function (data) {
  return util.baseRequest(data, rootURL + '/SingleOrderDayCount');
};

obj.outOrderPage = function (data) {
  return util.baseRequest(data, rootURL + '/outOrderPage');
};
obj.add = function (data) {
  return util.baseJsonRequest(data, rootURL + '/add');
};
obj.addT = function (data) {
  return util.baseJsonRequest(data, rootURL + '/addT');
};
obj.orderItemSum = function (data) {
  return util.baseRequest(data, rootURL + '/orderItemSum');
};
obj.unMrpOrderCount = function (data) {
  return util.baseRequest(data, rootURL + '/unMrpOrderCount');
};
obj.submit = function (data) {
  return util.baseRequest(data, rootURL + '/submit');
};
obj.checkDailyCount = function (data) {
  return util.baseRequest(data, rootURL + '/checkDailyCount');
};
obj.workflowSubmit = function (data) {
  return util.baseRequest(data, rootURL + '/workflowSubmit');
};
obj.statusBack = function (data) {
  return util.baseRequest(data, rootURL + '/statusBack');
};
obj.statusBackSingleOrder = function (data) {
  return util.baseRequest(data, rootURL + '/statusBackSingleOrder');
};
obj.satelliteSubmit = function (data) {
  return util.baseRequest(data, rootURL + '/satelliteSubmit');
};
obj.deleteByIdTs = function (data) {
  return util.baseRequest(data, rootURL + '/deleteByIdTs');
};
obj.detailById = function (data) {
  return util.baseRequest(data, rootURL + '/detailById');
};
// 获取商品详细信息
obj.queryProductList = function (data) {
  return util.baseRequest(data, 'kitchen/ordOrderItemTemp/queryProductList');
};
obj.mrpHistoryPage = function (data) {
  return util.baseRequest(data, 'kitchen/mrpHistory/page');
};
obj.mrpHistoryQueryById = function (data) {
  return util.baseRequest(data, 'kitchen/mrpHistory/queryById');
};
obj.deleteHTOrder = function (data) {
  return util.baseRequest(data, 'kitchen/order/deleteHTOrder');
};
obj.deleteSXOrder = function (data) {
  return util.baseRequest(data, 'kitchen/order/deleteSXOrder');
};
// 批量更新
obj.batchUpdate = function (data) {
  return util.baseJsonRequest(data, 'kitchen/ordOrderItemTemp/batchUpdate');
};
obj.update = function (data) {
  return util.baseJsonRequest(data, rootURL + '/update');
};
obj.updateT = function (data) {
  return util.baseJsonRequest(data, rootURL + '/updateT');
};
obj.updateSan = function (data) {
  return util.baseJsonRequest(data, rootURL + '/updateSan');
};
obj.copy = function (data) {
  return util.baseJsonRequest(data, rootURL + '/copy');
};
obj.counting = function (data) {
  return util.baseJsonRequest(data, rootURL + '/counting');
};
obj.listByDailymenu = function (data) {
  return util.baseRequest(data, rootURL + '/listByDailymenu');
};
obj.cancelByIds = function (data) {
  return util.baseRequest(data, rootURL + '/cancelByIds');
};
obj.proTmpMapptingListByOrderId = function (data) {
  return util.baseRequest(data, rootURL + '/proTmpMapptingListByOrderId');
};
obj.orderItemPriceEditQuery = function (data) {
  return util.baseJsonRequest(data, rootURL + '/orderItemPriceEditQuery');
};
obj.editItemPriceBySku = function (data) {
  return util.baseJsonRequest(data, rootURL + '/editItemPriceBySku');
};
obj.itemListByOrderId = function (data) {
  return util.baseRequest(data, rootURL + '/itemListByOrderId');
};
obj.orderItemDeptList = function (data) {
  return util.baseRequest(data, rootURL + '/orderItemDeptList');
};
obj.saveOrUpdateBatchOrderItemDept = function (data) {
  return util.baseJsonRequest(data, rootURL + '/saveOrUpdateBatchOrderItemDept');
};
obj.saveSkuDeptSet = function (data) {
  return util.baseJsonRequest(data, rootURL + '/saveSkuDeptSet');
};
obj.cusDeptListBySkuIdListAndCusId = function (data) {
  return util.baseJsonRequest(data, rootURL + '/cusDeptListBySkuIdListAndCusId');
};
obj.unReturnItemList = function (data) {
  return util.baseRequest(data, rootURL + '/unReturnItemList');
};
obj.orderReturn = function (data) {
  return util.baseJsonRequest(data, rootURL + '/orderReturn');
};
obj.getOrderItemList = function (data) {
  return util.baseRequest(data, rootURL + '/getOrderItemPage');
};
obj.getDailymenuIdOfOrderDetaiId = function (data) {
  return util.baseRequest(data, rootURL + '/getDailymenuIdOfOrderDetaiId');
};
obj.queryCustomersByDistributionDate = function (data) {
  return util.baseRequest(data, rootURL + '/queryCustomersByDistributionDate');
};
obj.hbSubmit = function (data) {
  return util.baseRequest(data, rootURL + '/hbSubmit');
};
// 根据id查询被合并的订单
obj.queryOrderByParentId = function (data) {
  return util.baseRequest(data, rootURL + '/queryOrderByParentId');
};
// 修改是否紧急
obj.updateLevel = function (data) {
  return util.baseRequest(data, rootURL + '/updateLevel');
};
export default obj;
