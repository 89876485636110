import {
  uniqueId, sortBy
} from 'lodash'
// 设置文件
import setting from '@/setting.js'


/**
 * 给菜单数据补充上 path 字段
 * https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */
function supplementMenuPath(menu) {
  return menu.map(e => ({
    ...e,
    path: e.path || uniqueId('d2-menu-empty-'),
    ...e.children ? {
      children: supplementMenuPath(e.children)
    } : {}
  }))
}
function sortMenu(menu) {
  menu = [...sortBy(menu, ['sort'])]
  for(var m of menu) {
    if(Array.isArray(m.children) && m.children.length > 0) {
      m.children = [...sortMenu(m.children)]
    }
  }
  return menu
}
/**
 *组装横向菜单（展示菜单用）
 *
 * @param {*} menus 菜单列表
 * @param {*} parentpath 父类路径
 */
function assemblyHeaderMenu(menus, parentpath) {
  for (let item of menus) {
    item.path = parentpath + item.caption;
    item.title = item.name;
    item.icon = item.iconCls || 'cube';
    if (item.hasChildren > 0) {
      assemblyHeaderMenu(item.children, item.path);
    } else {
      delete item.children;
    }
  }
  return menus;
}

/**
 *组装横向菜单并加入按钮（用于角色管理加载菜单按钮权限）
 *
 * @param {*} menus 菜单列表
 */
function assemblyModulesButtons(menus) {
  let newmenu = [];
  for (let item of menus) {
    //排除按钮中默认授权的
    if (item.lag == 0)
      continue;
    let obj = {};
    if (item.zhName) { //按钮名称
      obj.name = '按钮-' + item.zhName;
    } else { //模块名称
      obj.name = item.name;
    }
    if (item.moduleId) {
      obj.moduleId = item.moduleId;
    }
    obj.id = item.id;
    if (item.hasChildren != 1) {
      if (item.butList && item.butList.length > 0) {
        obj.children = assemblyModulesButtons(item.butList);
      }
    } else {
      obj.children = assemblyModulesButtons(item.children);
    }
    newmenu.push(obj);
  }
  return newmenu;
}

/**
 *组装纵向菜单
 *
 * @param {*} menus 菜单列表
 */
function assemblyAsideMenu(menus) {
  let asidemenu = [];
  for (let item of menus) {
    if (item.hasChildren > 0) {
      asidemenu = asidemenu.concat(item.children);
    }
  }
  return asidemenu;
}

/**
 *组装所有页面的路由
 *
 * @param {*} menus 菜单列表
 */
function assemblyPagePaths(menus) {
  let paths = [];
  for (let item of menus) {
    let temp = [];
    if (item.hasChildren === 1) {
      temp = assemblyPagePaths(item.children);
    } else {
      temp.push(item.path);
    }
    paths = [...paths, ...temp];
  }
  return paths;
}

export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse,
    //模块按钮数组
    ModuleButton: [],
    //按钮数组
    buttonItemMap: [],
    //所有页面的路由
    pagePaths: []

  },
  actions: {
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} context
     * @param {Boolean} collapse is collapse
     */
    asideCollapseSet({
      state,
      dispatch
    }, collapse) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = collapse
        // 持久化
        await dispatch('zycf/db/set', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true
        }, {
          root: true
        })
        // end
        resolve()
      })
    },
    /**
     * 切换侧边栏展开和收缩
     * @param {Object} context
     */
    asideCollapseToggle({
      state,
      dispatch
    }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = !state.asideCollapse
        // 持久化
        await dispatch('zycf/db/set', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true
        }, {
          root: true
        })
        // end
        resolve()
      })
    },
    /**
     * 从持久化数据读取侧边栏展开或者收缩
     * @param {Object} context
     */
    asideCollapseLoad({
      state,
      dispatch
    }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = await dispatch('zycf/db/get', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          defaultValue: setting.menu.asideCollapse,
          user: true
        }, {
          root: true
        })
        // end
        resolve()
      })
    },
    /**
     *保存登陆成功后的菜单列表(包括横向和纵向菜单)
     *
     * @param {*} {state,dispatch}
     * @param {*} menus
     */
    storeMenuList({
      state,
      dispatch,
      commit
    }, menus) {
      return new Promise(async (resolve, reject) => {
        try {
          //组装横向菜单
          const headermenu = assemblyHeaderMenu(menus, '');
          commit('headerSet', headermenu);
          //组装所有路由
          const paths = assemblyPagePaths(menus);
          //组装模块按钮数组

          //const ModuleButton = assemblyModulesButtons(headermenu);

          //const menuscopy=[...headermenu];
          //const asidemenu = assemblyAsideMenu(menuscopy);

          commit('pagePathsSet', paths);
          //commit('ModuleButtonSet', ModuleButton);
          //commit('asideSet', asidemenu);
          // store 赋值
          await dispatch('zycf/db/set', {
            dbName: 'sys',
            path: 'headermenus',
            value: headermenu,
            user: true
          }, {
            root: true
          });
          await dispatch('zycf/db/set', {
            dbName: 'sys',
            path: 'asidemenus',
            value: headermenu,
            user: true
          }, {
            root: true
          });

          await dispatch('zycf/db/set', {
            dbName: 'sys',
            path: 'pagePaths',
            value: paths,
            user: true
          }, {
            root: true
          });

          // end
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     *从缓存中加载菜单按钮
     *
     * @param {*} { state, dispatch }
     * @returns
     */
    Loadmodulebutton({
      state,
      dispatch
    }) {
      return new Promise(async resolve => {
        // store 赋值
        state.ModuleButton = await dispatch('zycf/db/get', {
          dbName: 'sys',
          path: 'ModuleButton',
          defaultValue: [],
          user: true
        }, {
          root: true
        })

        state.buttonItemMap = await dispatch('zycf/db/get', {
          dbName: 'sys',
          path: 'buttonItemMap',
          defaultValue: [],
          user: true
        }, {
          root: true
        })
        // end
        resolve()
      })
    }

  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    headerSet(state, menu) {
      // store 赋值
      var tmp = supplementMenuPath(menu)
      state.header = sortMenu(tmp)
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSet(state, menu) {
      // store 赋值
      state.aside = supplementMenuPath(menu)

    },
    /**
     * @description 设置模块按钮数组
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    ModuleButtonSet(state, menu) {
      state.ModuleButton = menu;
    },
    /**
     * @description 设置所有页面的路由数组
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    pagePathsSet(state, menu) {
      state.pagePaths = menu;

    }

  }
}