<template>
  <el-dialog
    custom-class="jz-modal minh approval-detail-modal"
    title="任务审核"
    :visible="modal"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    append-to-body
    v-dialog-drag="true"
    width="50%"
  >
    <div class="header">
      <div class="item">
        <span>任务状态:</span>
        <span>{{ renderResultName(mainInfo.result) }}</span>
      </div>
      <div class="item">
        <span>单据类型:</span>
        <span>{{ mainInfo.modeName }}</span>
      </div>
      <div class="item">
        <span>单据编号:</span>
        <span>{{ mainInfo.recordNum }}</span>
      </div>
      <div class="item">
        <span>创建人:</span>
        <span>{{ mainInfo.createdPerson }}</span>
      </div>
      <div class="item">
        <span>创建时间:</span>
        <span>{{ mainInfo.createdDate }}</span>
      </div>
    </div>
    <component
      v-bind:is="currentComponent"
      v-if="recordInfo"
      :modal="recordInfo"
      @closemodal="closemodal"
      :orderId="mainInfo.recordId"
      :modalObj="{ id: mainInfo.recordId }"
    ></component>
    <div class="steps">
      <el-steps direction="vertical" space="100px">
        <el-step
          :title="levelRemark(item)"
          v-for="(item, key) in detailList"
          icon="el-icon-circle-check"
          :key="key"
        >
          <div slot="description" class="flex-x-start">
            <div
              v-for="(record, key2) in item.recordDetailVO"
              :key="'record_' + key2"
            >
              <div>
                <span>审核结果:</span>
                <span>{{ renderResultName(record.result) }}</span>
              </div>
              <div>
                <span>审核人:</span>
                <span>{{ record.createdPerson }}</span>
              </div>
              <div>
                <span>审核时间:</span>
                <span>{{ record.createdDate }}</span>
              </div>
              <div>
                <span>备注:</span>
                <span>{{ record.remark }}</span>
              </div>
            </div>
          </div>
        </el-step>
        <el-step title="待审核" icon="el-icon-circle-check">
          <div slot="description">
            <el-input v-model="remark" type="textarea"></el-input>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div slot="footer">
      <el-button class="sys-btn" @click="cancel">取消</el-button>
      <el-button
        type="primary"
        class="sys-primary-btn"
        v-if="mainInfo.review != null && mainInfo.review"
        :loading="btnLoading"
        @click="pass"
        >审核通过</el-button
      >
      <el-button
        type="warning"
        class="sys-warning-btn"
        v-if="mainInfo.review != null && mainInfo.review"
        :loading="btnLoading"
        @click="reject"
        >驳回</el-button
      >
      <el-button
        type="warning"
        class="sys-warning-btn"
        v-if="mainInfo.revoke != null && mainInfo.revoke"
        :loading="btnLoading"
        @click="revoke"
        >撤回</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import orderInfo from "@/layout/header-aside/components/header-message/components/info.vue";
import profitloss from "@/views/operate/warehouse/profitloss/profitlossQueryModel/workflowProfitlossDetailQuery.vue";
import transferApply from "@/views/operate/warehouse/SatelliteKitchenTransferApply/components/checkView.vue";
import dailymenu from "@/views/operate/order/dailymenu/components/workflowInfo.vue";
import mixin from "@/plugin/mixins/mixin";
import constant from "@/libs/constant.js";
import util from "@/libs/util.js";
import cloneDeep from "lodash/cloneDeep";
import pullAllBy from "lodash/pullAllBy";
import { divide, round, multiply, toNumber, add, subtract } from "lodash";
import request from "@/plugin/axios";
import Vue from "vue";
var api = {
  queryById(data) {
    return util.baseRequest(data, "/kitchen/workflowRecordMain/queryById");
  },
  pass(data) {
    return util.baseRequest(data, "/kitchen/workflowRecordMain/pass");
  },
  reject(data) {
    return util.baseRequest(data, "/kitchen/workflowRecordMain/overrule");
  },
  revoke(data) {
    return util.baseRequest(data, "/kitchen/workflowRecordMain/revoke");
  },
};

export default {
  mixins: [mixin],
  components: { orderInfo, profitloss, transferApply, dailymenu },
  data() {
    return {
      btnLoading: false,
      currentComponent: "",
      recordInfo: true,
      remark: "",
      mainInfo: {},
      detailList: [],
      dicList: [],
          activeName: "1", //展开name为1的折叠板
    };
  },
  props: {
    modal: {
      default: false,
    },
    approvalId: {
      default: null,
    },
  },
  async mounted() {
    this.dicList = this._searchDic("WORKFLOW_RESULT");
    var res = await this.queryById(this.approvalId);
    if (constant.IS_SUCCESS(res)) {
      this.mainInfo = cloneDeep(res.aaData);
      this.detailList = cloneDeep(res.aaData.levelVO);
      this.currentComponent = this.mainInfo.detailUrl;
    } else {
      this.$error(res.msg);
      this.$emit("closemodal", false);
    }
  },
  created() {},
  computed: {},
  methods: {
    renderResultName(result) {
      var resultName = "";
      this.dicList.forEach((om) => {
        if (om.dataKey == result) {
          resultName = om.dataValue;
        }
      });
      return resultName;
    },
    cancel() {
      this.$emit("closemodal");
    },
    beforeClose(done) {
      this.cancel();
      done();
    },
    async queryById(id) {
      return api.queryById({ id });
    },
    async pass() {
      this.btnLoading = true
      var res = await api.pass({
        ids: [this.mainInfo.id],
        remark: this.remark,
      });
      this.btnLoading = false
      if (constant.IS_SUCCESS(res)) {
        this.$emit("closemodal", true);
      } else {
        this.$error(res.msg);
      }
    },
    async reject() {
      this.btnLoading = true
      var res = await api.reject({
        ids: [this.mainInfo.id],
        remark: this.remark,
      });
      this.btnLoading = false
      if (constant.IS_SUCCESS(res)) {
        this.$emit("closemodal", true);
      } else {
        this.$error(res.msg);
      }
    },
    async revoke() {
      this.btnLoading = true
      var res = await api.revoke({
        ids: [this.mainInfo.id],
        remark: this.remark,
      });
      this.btnLoading = false
      if (constant.IS_SUCCESS(res)) {
        this.$emit("closemodal", true);
      } else {
        this.$error(res.msg);
      }
    },
    async openRecordInfo() {
      this.recordInfo = true;
    },
    closemodal() {
      this.recordInfo = false;
    },
    levelRemark(item) {
      var nodeDetailVO = item.nodeDetailVO;
      if (util.listNotNull(nodeDetailVO)) {
        var append = "";
        nodeDetailVO.forEach((el) => {
          append = append + "【" + el.remark + "】";
        });
        return item.remark + " " + append;
      }
      return item.remark;
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-message/approvalDetail.vue"
}
</vue-filename-injector>
