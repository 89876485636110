<template>
  <el-tooltip effect="dark" content="清空浏览器缓存" placement="bottom">
    <el-button
      class="d2-mr btn-text can-hover"
      type="text"
      @click="handleClick"
    >
      <d2-icon name="remove" style="font-size: 18px;" />
    </el-button>
  </el-tooltip>
</template>

<script>
export default {
  methods: {
    handleClick() {
     this.$notify({
          type: 'warning',
          title: '清空浏览器缓存',
          message: '请同时按下 “Shift+Ctrl+Delete” 键清空浏览器缓存'
        })
    }
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-clearbrowercache/index.vue"
}
</vue-filename-injector>
