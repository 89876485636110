<template>
    <base-dialog :modal="modal" @closemodal="cancel" ref="baseDialog" title="导出" width="30%">
        <el-radio v-model="radio" :label="1">导出选中</el-radio>
        <el-radio v-model="radio" :label="2">导出当前</el-radio>
        <el-radio v-model="radio" :label="3">导出所有</el-radio>
        <div slot="footer" class="dialog-footer flex-x-end">
            <el-button @click="cancel(false)">取消</el-button>
            <el-button type="primary" @click="submit">提交</el-button>
        </div>
    </base-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
import util from "@/libs/util.js"
export default {
  name: "table-export",
  mixins: [mixin],
  data() {
    return {
        radio: 1
    }
  },
  props: {
    modal: {
      default: false
    },
    currentData: {
      default: function() {
        return []
      }
    },
    selection: {
      default: function() {
        return []
      }
    },
    allData: {
      default: function() {
        return []
      }
    },
    columns: {
      default: function() {
        return []
      }
    },
    title: '',
    header: '',
    merges: ''
  },
  methods: {
    beforeClose(done) {
      this.cancel();
      done();
    },
    cancel() {
      this.$emit("cancel");
    },
    async submit() {
      var dataList = []
      if(this.radio == 1) {
        dataList = this.selection
      }
      if(this.radio == 2) {
        dataList = this.currentData
      }
      if(this.radio == 3) {
        dataList = this.allData
      }

      if(!util.listNotNull(dataList)) {
        this.$warning("暂无数据")
        this.cancel()
        return
      }
      try {
        await this.$export.excel({
                    columns: this.columns,
                    data: dataList,
                    title: this.title,
                    header: this.header,
                    merges: this.merges
                  })
      }catch (error) {
        this.$error("导出出错")
        return
      }
      this.$success("导出成功")
      this.cancel()
    }
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/table-export/index.vue"
}
</vue-filename-injector>
