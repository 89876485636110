export default {
  namespaced: true,
  state: {
    // 用户名称
    info: {},
    //用户信息
    userinfo: {},
  },
  getters: {
    userinfo: (state) => state.userinfo,
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set({ state, dispatch }, info) {
      return new Promise(async (resolve) => {
        // store 赋值
        state.info = info;
        // 持久化
        await dispatch(
          'zycf/db/set',
          {
            dbName: 'sys',
            path: 'user.info',
            value: info,
            user: true,
          },
          { root: true }
        );
        // end
        resolve();
      });
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load({ state, dispatch }) {
      return new Promise(async (resolve) => {
        // store 赋值
        state.info = await dispatch(
          'zycf/db/get',
          {
            dbName: 'sys',
            path: 'user.info',
            defaultValue: {},
            user: true,
          },
          { root: true }
        );
        // end
        resolve();
      });
    },
    /**
     *获取浏览器存储的用户信息
     *
     * @param {*} { state, dispatch }
     * @returns
     */
    getuserinfo({ state, dispatch }) {
      return new Promise(async (resolve) => {
        // store 赋值
        state.userinfo = await dispatch(
          'zycf/db/get',
          {
            dbName: 'sys',
            path: 'loginUser',
            defaultValue: {},
            user: true,
          },
          { root: true }
        );
        // end
        resolve(state.userinfo);
      });
    },
  },
};
