import { Message, MessageBox } from 'element-ui';
import util from '@/libs/util.js';
import router from '@/router';
import { AccountLogin } from '@api/sys.login';
import { UpdateMyPassword } from '@api/operate.user';
import { category } from '@/api/sku';
import { dbClear } from '@/libs/util.db';
import constant from '@/libs/constant.js';

export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login({ dispatch, commit }, { openId, loginName, password, authCode, flag, systemId, encrypt } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        AccountLogin({
          openId,
          loginName,
          password,
          authCode,
          flag,
          systemId,
          encrypt,
        })
          .then(async (d) => {
            // 设置 cookie 一定要存 uuid 和 token 两个 cookie
            // 整个系统依赖这两个数据进行校验和存储
            // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
            // token 代表用户当前登录状态 建议在网络请求中携带 token
            // 如有必要 token 需要定时更新，默认保存一天
            if (d.state !== 0) {
              throw new Error(d.msg);
            } else {
              util.cookies.set('uuid', d.loginUser.userId); //d.loginUser.loginName+'_'+d.loginUser.userId);
              util.cookies.set('token', d.token);
              //保存菜单信息
              await dispatch('zycf/menu/storeMenuList', d.menuItemLlist, {
                root: true,
              });
              // 设置 vuex 用户名（昵称）
              await dispatch(
                'zycf/user/set',
                {
                  name: d.loginUser.nickName,
                },
                {
                  root: true,
                }
              );
              //保存用户基本信息
              dispatch(
                'zycf/db/set',
                {
                  dbName: 'sys',
                  path: 'loginUser',
                  value: d.loginUser,
                  user: true,
                },
                {
                  root: true,
                }
              );
              //保存按钮列表数组用于控制按钮权限
              dispatch(
                'zycf/db/set',
                {
                  dbName: 'sys',
                  path: 'buttonItemMap',
                  value: d.buttonItemMap,
                  user: true,
                },
                {
                  root: true,
                }
              );

              //保存用户Session信息
              dispatch(
                'zycf/db/set',
                {
                  dbName: 'sys',
                  path: 'sessionUser',
                  value: d.sessionUser,
                  user: true,
                },
                {
                  root: true,
                }
              );
              //从接口获取各种缓存数据并保存至缓存数据库（异步）
              dispatch(
                'zycf/cache/GetDataDic',
                {},
                {
                  root: true,
                }
              );
              window.localStorage.setItem('sessionUser', JSON.stringify(d.sessionUser));
              const result = await category.page({});
              if (constant.IS_SUCCESS(result)) {
                // 递归，将数组转换为树形数据
                const treeData = util.recursion(result.aaData, 'id', 'parentId', '0', (item) => item.sort);
                // 写入数据商品分类数据
                dispatch(
                  'zycf/db/set',
                  {
                    dbName: 'sys',
                    path: 'categoryList',
                    value: treeData,
                    user: true,
                  },
                  {
                    root: true,
                  }
                );
              }
              // 用户登录后从持久化数据加载一系列的设置
              dispatch('load');
              // 结束
              resolve();
            }
          })
          .catch((err) => {
            console.log('err: ', err);
            reject(err);
          });
      });
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      async function logout() {
        //防止登录账户过多，缓存数据较大时造成localstorage 超储
        //清除数据存储
        dbClear({
          dbName: 'database',
        });
        //清除系统存储
        dbClear({
          dbName: 'sys',
        });
        // // 清空 vuex 用户名称
        // await dispatch('zycf/user/set', {}, {
        //   root: true
        // })
        // // 清空 vuex 用户信息
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'loginUser',
        //   value: {},
        //   user: true
        // }, {
        //   root: true
        // });
        // //清空用户session
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'sessionUser',
        //   value: {},
        //   user: true
        // }, {
        //   root: true
        // });
        // //清空菜单
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'headermenus',
        //   value: [],
        //   user: true
        // }, {
        //   root: true
        // });
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'asidemenus',
        //   value: [],
        //   user: true
        // }, {
        //   root: true
        // });
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'ModuleButton',
        //   value: [],
        //   user: true
        // }, {
        //   root: true
        // });
        // await dispatch('zycf/db/set', {
        //   dbName: 'sys',
        //   path: 'buttonItemMap',
        //   value: [],
        //   user: true
        // }, {
        //   root: true
        // });
        // 删除cookie
        util.cookies.remove('token');
        util.cookies.remove('uuid');
        // 跳转路由
        router.push({
          name: 'login',
        });
      }
      // 判断是否需要确认
      if (confirm) {
        commit('zycf/gray/set', true, {
          root: true,
        });
        MessageBox.confirm('确定要注销当前用户吗', '注销用户', {
          type: 'warning',
        })
          .then(() => {
            commit('zycf/gray/set', false, {
              root: true,
            });
            logout();
          })
          .catch(() => {
            commit('zycf/gray/set', false, {
              root: true,
            });
            Message({
              message: '取消注销操作',
            });
          });
      } else {
        logout();
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load({ dispatch }) {
      return new Promise(async (resolve) => {
        // DB -> store 加载用户名
        await dispatch('zycf/user/load', null, {
          root: true,
        });
        // DB -> store 加载用户信息
        await dispatch('zycf/user/getuserinfo', null, {
          root: true,
        });
        // DB -> store 加载主题
        await dispatch('zycf/theme/load', null, {
          root: true,
        });
        // DB -> store 加载页面过渡效果设置
        await dispatch('zycf/transition/load', null, {
          root: true,
        });
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('zycf/page/openedLoad', null, {
          root: true,
        });
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('zycf/menu/asideCollapseLoad', null, {
          root: true,
        });
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('zycf/size/load', null, {
          root: true,
        });
        // DB -> store 持久化数据加载颜色设置
        await dispatch('zycf/color/load', null, {
          root: true,
        });

        //从浏览器缓存中加载数据字典
        dispatch('zycf/cache/LoadDataDic', null, {
          root: true,
        });

        //从浏览器缓存中加载模块和按钮
        dispatch('zycf/menu/Loadmodulebutton', null, {
          root: true,
        });

        // end
        resolve();
      });
    },
    /**
     *修改密码
     *
     * @param {*} {
     *       dispatch,
     *       commit
     *     }
     * @param {*} {
     *       oldPassword,
     *       newPassword
     *     }
     * @returns
     */
    updatePass({ dispatch, commit }, { oldPassword, newPassword, encrypt }) {
      return new Promise(async (resolve, reject) => {
        UpdateMyPassword({
          oldPassword,
          newPassword,
          encrypt,
        })
          .then((d) => {
            if (d.state == 0) {
              Message({
                duration: 2000,
                message: '修改成功！将会跳转到登录界面重新登录',
                type: 'success',
                onClose: function (pa) {
                  dispatch('logout', {
                    confirm: false,
                  });
                },
              });
            } else {
              Message({
                message: '修改失败！',
                type: 'warning',
              });
            }
            resolve();
          })
          .catch((err) => {
            console.log('err: ', err);
            reject(err);
          });
      });
    },
  },
};
