import request from '@/plugin/axios';
import util from '@/libs/util.js';

var rootURL = '/kitchen/contractorder';

var obj = {};
obj.pageDataList = function (data) {
  return util.baseRequest(data, rootURL + '/page');
};
obj.add = function (data) {
  return util.baseJsonRequest(data, rootURL + '/createContractorder');
};
obj.update = function (data) {
  return util.baseJsonRequest(data, rootURL + '/updateById');
};
obj.delete = function (data) {
  return util.baseRequest(data, rootURL + '/deleteByIds');
};
obj.cancel = function (data) {
  return util.baseRequest(data, rootURL + '/cancelByIds');
};
obj.confirm = function (data) {
  return util.baseRequest(data, rootURL + '/confirmByIds');
};
obj.queryById = function (data) {
  return util.baseRequest(data, rootURL + '/queryById');
};
obj.detailPage = function (data) {
  return util.baseRequest(data, rootURL + '/detailPage');
};
obj.createOrderAuto = function (data) {
  return util.baseJsonRequest(data, rootURL + '/createOrderAuto');
};
obj.detailByMainIdList = function (data) {
  return util.baseRequest(data, rootURL + '/detailByMainIdList');
};
obj.querycontractorderdetailList = function (data) {
  return util.baseRequest(data, rootURL + '/querycontractorderdetailList');
};
obj.dailyProTmp = function (data) {
  return util.baseJsonRequest(data, rootURL + '/dailyProTmp');
};
export default obj;
