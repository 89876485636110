<template>
  <el-dialog
    custom-class="jz-modal"
    title="修改密码"
    :visible="modal"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    v-dialog-drag="true"
    width="40%"
  >
    <el-form :model="form" ref="form" :inline="true" :rules="rules" label-width="80px">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="旧密码" prop="oldPwd">
            <el-input
              placeholder="请输入旧密码"
              v-model="form.oldPwd"
              type="password"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="新密码" prop="newPwd">
            <el-input
              placeholder="请输入新密码"
              v-model="form.newPwd"
              type="password"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="确认密码" prop="confirmPwd">
            <el-input
              placeholder="请输入确认密码"
              v-model="form.confirmPwd"
              type="password"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer flex-x-end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [mixin],
  data() {
    let vm = this;
    let vConfirmPwd = function(rule, value, callback) {
      let val = value == null ? "" : value.trim();
      if (val !== vm.form.newPwd.trim()) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: ""
      },
      rules: {
        oldPwd: [this._ruleRequired("旧密码")],
        newPwd: [this._ruleRequired("新密码"), this._rulePassword()],
        confirmPwd: [
          this._ruleRequired("确认密码"),
          { validator: vConfirmPwd, trigger: "blur" }
        ]
      }
    };
  },
  props: {
    modal: {
      default: false
    }
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    ...mapActions("zycf/account", ["updatePass"]),
    cancel() {
      this.$refs.form.resetFields();
      this.$emit("closemodal");
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.updatePass({
            oldPassword: this._AESEncrypt(this.form.oldPwd.trim()),
            newPassword: this._AESEncrypt(this.form.newPwd.trim()),
            encrypt: 1 //是否加密了密码 0否1是
          });
        }
      });
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      this.cancel();
      done();
    }
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/components/UpdatePass.vue"
}
</vue-filename-injector>
