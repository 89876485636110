<template>
  <el-collapse v-model="activeName" style="padding: 10px">
    <el-collapse-item name="1">
      <template slot="title">
        <span style="font-weight: bold">单据详情</span>
      </template>
      <el-card class="box-card" style="margin-top: 0px">
        <el-form
          :model="form"
          ref="form"
          :inline="true"
          :rules="rules"
          :label-width="labelWidth"
        >
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="套餐名称">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.tmpName }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="配送日期">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.distributionDate }}</label
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <d2-crud
          ref="itemCrud"
          flex-box="1"
          :columns="columns"
          :data="dataList"
          @row-delete="rowDelete"
          @cell-data-change="handleCellDataChange"
          index-row
        ></d2-crud>
        <div slot="footer"></div>
        <!-- 选择商品弹框 -->
        <base-selection-dialog
          title="选择商品"
          placeholder="名称/编号"
          v-if="skuShow"
          :modal="skuShow"
          :queryApi="api.sku.page"
          businessFiledName="skuId"
          :haveChosenRows="haveChosenRowsSku"
          :multiple="true"
          @closemodal="skuclosemodal"
          :outerDataList="dataList"
          keyField="skuId"
          :submitHandle="skuSubmitHandle"
          @return-value="(row) => this.skuSubmit(row)"
        >
          <el-table-column label="商品编码" prop="skuCode"></el-table-column>
          <el-table-column label="商品名称" prop="skuName"></el-table-column>
          <el-table-column label="单位" prop="skuMeasure"></el-table-column>
        </base-selection-dialog>
      </el-card>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import dialogDrag from "@/libs/dialog-drag";
import mixin from "@/plugin/mixins/mixin";
import common from "../common.js";
import mountedMixin from "../../common/mixin/mounted";
import { Dailymenu, customer, officeMember, proTmp } from "@api/order";
import { sku } from "@api/sku";
import constant from "@/libs/constant.js";
import util from "@/libs/util.js";
import cloneDeep from "lodash/cloneDeep";
import validators from "@/libs/validator";

/**
 * 提交前对数据的处理
 * @param  {[type]} skuList [description]
 * @return {[type]}         [description]
 */
function itemListFromSkuList(skuList) {
  if (!Array.isArray(skuList) || skuList.length == 0) {
    return [];
  }
  var res = [];
  skuList.forEach(function (el) {
    var item = {};
    item.skuId = el.skuId;
    item.productId = el.productId;
    item.productName = el.productName;
    item.productId = el.productId;
    item.productName = el.productName;
    item.skuName = el.skuName;
    item.price = el.price;
    item.quantity = el.quantity;
    item.itemTotal = el.itemTotal;
    res.push(item);
  });
  return res;
}

export default {
  mixins: [mixin, mountedMixin, common],
  directives: {
    dialogDrag,
  },
  data() {
    var vm = this;
    return {
      haveChosenRowsBusiness: [],
      businessListShow: false,
      api: {
        customer,
        sku,
        officeMember,
      },
      skuShow: false,
      haveChosenRows: [],
      dataList: [],
      activeName: "1", //展开name为1的折叠板
      columns: [
        {
          title: "商品名称",
          key: "proName",
        },
        /*{
          title: "净含量(千克)",
          key: "weight",
        },
        {
          title: "分类",
          key: "type",
          component: {
            render: (h, row, index) => {
              var res = "";
              this.proTypeList.forEach((el) => {
                if (el.dataKey == row.type) {
                  res = el.dataValue;
                }
              });
              return <span>{res}</span>;
            },
          },
        },*/
      ],
      cusListShow: false,
      form: {
        name: "",
        orderNumber: "",
        id: null,
        buyerName: "",
        contractAmount: "",
        contractDate: "",
        effectiveDate: "",
        dueDate: "",
        holidayDeliver: 0,
        receivePhone: "",
        payType: "",
        paymentStatus: "",
        receiveAddress: "",
        receivePerson: "",
        payAmount: "",
        distributionTimeBegin: "",
        distributionTimeEnd: "",
        orderRemark: "",
        customerId: null,
        itemList: [],
        shippingTime: "",
        distributionDate: "",
        orderAmount: "",
        remark: "",
        payStatus: 0,
        paymentSerialNumber: "",
        businessPersonList: [],
        cusList: [],
      },
      businessPersonIdList: [],
      roleList: [],
      rules: {
        name: [this._ruleRequired("套餐名称")],
      },
      isstatus: false,
      isrole: false,
      isuser: false,
      payType: [],
      payStatus: [],
      rowHandle: {
        width: "80px",
        custom: [
          {
            text: "删除",
            type: "danger",
            emit: "row-delete",
          },
        ],
      },
      proTypeList: [],
    };
  },
  props: {
    modal: {
      default: false,
    },
    infoId: {
      default: null,
    },
    modalObj: {
      default() {
        return {};
      },
    },
  },
  async mounted() {
    this.proTypeList = this._searchDic("PROTMP_DETAIL_TYPE");
    var res = await Dailymenu.detailById({
      id: this.modalObj.id,
    });
    this.form = cloneDeep(res.aaData);
    this.dataList = cloneDeep(res.aaData.itemList);
  },
  created() {},
  computed: {
    showPayInfo: function () {
      return this.form.payStatus == 1;
    },
  },
  methods: {
    businessListOpen() {
      this.haveChosenRowsBusiness = this.$refs.businessSelect.dataRow;
      this.businessListShow = true;
    },
    closemodalBusiness(issearch) {
      this.businessListShow = false;
    },
    handleReturnValueBusiness(rows) {
      this.$refs.businessSelect.setList(rows, "name", "id");
      this.closemodalBusiness();
    },
    // 合计金额
    totalMoney() {
      let total = 0;
      this.dataList.forEach((item) => {
        total =
          (total * 1000 +
            (Number(item.quantity || 0) *
              1000 *
              (Number(item.price || 0) * 1000)) /
              1000) /
          1000;
      });
      return total;
    },
    moneyString(num) {
      this.form.orderAmount = this.totalMoney();
      return util.moneyString(num);
    },
    cusListOpen() {
      this.cusListShow = true;
    },
    closemodal(issearch) {
      this.cusListShow = false;
    },
    skuclosemodal() {
      this.skuShow = false;
    },
    cancel(flag) {
      this.$refs.form.resetFields();
      this.$emit("closemodal", flag);
    },
    async submit() {
      try {
        await this.$refs["form"].validate();
      } catch (err) {
        return;
      }
      this.form.itemList = this.itemListFromSkuList(this.dataList);
      var param = cloneDeep(this.form);
      var result = await proTmp.update(param);
      util.modalSubimtHandle.call(this, result);
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      this.cancel(false);
      done();
    },
    handleReturnValue(row) {
       this.$refs.cusSelect.setList([row], "name", "id");
    },
    rowDelete({ index, row }) {
      this.dataList.splice(index, 1);
      this.form.orderAmount = this.totalMoney();
    },
    handleCellDataChange({ rowIndex, key, value, row }) {
      this.dataList[rowIndex][key] = value;
      this.dataList = cloneDeep(this.dataList);
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/operate/order/dailymenu/components/workflowInfo.vue"
}
</vue-filename-injector>
