import regmixin from './mixin_reg'
import d2crudmixin from './mixin_d2_crud'
import datemixin from './mixin_reg'
import pageSearchForm from './page-search-form'
import { cloneDeep, subtract } from "lodash";
import dialogDrag from '@/libs/dialog-drag'
import CryptoJS from "crypto-js";
import util from '@/libs/util.js'
import {MONEY_MAX} from '@/libs/validator'
import {order} from '@/api/order'
import constant from '@/libs/constant.js'

import moment from 'moment';
export default {
    data() {
        return {
            
        }
    },
    computed: {},
    mounted() { },
    methods: {
        async rowReturn({row}) {            
            const result = await order.unReturnItemList({orderId: row.id});
            if(constant.IS_SUCCESS(result)) {
                if(!util.listNotNull(result.aaData)) {
                    this.$warning('无可退货商品')
                    return
                }
            }else {
                this.$warning(result.msg)
                return
            }
            this.curRow = cloneDeep(row)
            this.orderReturnShow = true
        }
    }
}