import request from '@/plugin/axios'

/**
 *批量分配货位
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function AllocateInstorageLocationBatch(data) {
    return request({
        url: '/kitchen/entryorderdetail/allocateInstorageLocationBatch',
        method: 'post',
        data: data
    })

}


/**
 *获取仓库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function WholeImport(data) {
    return request({
      url: '/kitchen/entrytraylocalmap/wholeImport',
      method: 'get',
      params: data
    })
  }
/**
 *获取入库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetEntrymainorderList(data) {
    return request({
        url: '/kitchen/entrymainorder/page',
        method: 'get',
        params: data
    })

}

/**
 *根据ID获取入库信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetEntrymainorderById(data) {
    return request({
        url: '/kitchen/entrymainorder/queryById',
        method: 'get',
        params: data
    })

}
/**
 *删除入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DelEntrymainorder(data) {
    return request({
        url: '/kitchen/entrymainorder/delete',
        method: 'post',
        data: data
    })

}

/**
 *软删除入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function SoftDelEntrymainorder(data) {
    return request({
        url: '/kitchen/entrymainorder/softDelete',
        method: 'post',
        data: data
    })

}

/**
 *软删除入库明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function SoftDelEntrydetailorder(data) {
    return request({
        url: '/kitchen/entryorderdetail/softDelete',
        method: 'post',
        data: data
    })

}

/**
 *更新入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateEntrymainorder(data) {
    return request({
        url: '/kitchen/entrymainorder/update',
        method: 'post',
        data: data
    })

}

/**
 *批量更新入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchUpdateEntrymainorder(data) {
    return request({
        url: '/kitchen/entrymainorder/batchUpdate',
        method: 'json',
        data: data
    })

}


/**
 *新增入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddEntrymainorder(data) {
    return request({
        url: '/kitchen/entrymainorder/create',
        method: 'post',
        data: data
    })

}

/**
 *获取商品列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetProductSkuList(data) {
    return request({
        url: '/kitchen/productSku/page',
        method: 'get',
        params: data
    })

}


/**
 *新增入库单明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddEntryorderdetail(data) {
    return request({
        url: '/kitchen/entryorderdetail/batchCreate',
        method: 'post',
        data: data
    })

}

/**
 *获取入库明细列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetEntryorderdetailList(data) {
    return request({
        url: '/kitchen/entryorderdetail/page',
        method: 'get',
        params: data
    })

}

/**
 *删除入库单明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteEntryorderdetail(data) {
    return request({
        url: '/kitchen/entryorderdetail/delete',
        method: 'post',
        data: data
    })

}

/**
 *分配货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AllocateInstorageLocation(data) {
    return request({
        url: '/kitchen/entryorderdetail/allocateInstorageLocation',
        method: 'post',
        data: data
    })

}

/**
 *获取托盘绑定列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetEntrytraylocalmapList(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/page',
        method: 'get',
        params: data
    })

}

/**
 *确认订单时判断每个明细是否都有分配货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function checkDetailedBind(data) {
    return request({
        url: '/kitchen/entryorderdetail/checkDetailedBind',
        method: 'post',
        data: data
    })

}

/**
 *入库完成操作
 *
 * @export
 * @param {*} data
 * @returns
 */
export function InstoreComplete(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/InstoreComplete',
        method: 'post',
        data: data
    })

}

/**
 *获取出库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOutmainorderList(data) {
    return request({
        url: '/kitchen/outmainorder/page',
        method: 'get',
        params: data
    })

}

/**
 *批量软删除出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function SoftDeleteOutmainorder(data) {
    return request({
        url: '/kitchen/outmainorder/softDelete',
        method: 'post',
        data: data
    })

}

/**
 *更新出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateOutmainorder(data) {
    return request({
        url: '/kitchen/outmainorder/update',
        method: 'post',
        data: data
    })

}


/**
 *批量更新出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchUpdateOutmainorder(data) {
    return request({
        url: '/kitchen/outmainorder/batchUpdate',
        method: 'json',
        data: data
    })

}

/**
 *获取库存列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetInventorymainList(data) {
    return request({
        url: '/kitchen/inventorymain/page',
        method: 'get',
        params: data
    })

}

/**
 *新增出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddOutmainorder(data) {
    return request({
        url: '/kitchen/outmainorder/create',
        method: 'post',
        data: data
    })

}

/**
 *新增出库单明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddOutorderdetail(data) {
    return request({
        url: '/kitchen/outorderdetail/batchCreate',
        method: 'json',
        data: data
    })

}


/**
 *确认出库订单时判断每个明细是否都有分配货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function checkOutOrderDetailedBind(data) {
    return request({
        url: '/kitchen/outorderdetail/checkOutOrderDetailedBind',
        method: 'post',
        data: data
    })

}

/**
 *软删除出库明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function SoftDelOutorderdetail(data) {
    return request({
        url: '/kitchen/outorderdetail/softDelete',
        method: 'post',
        data: data
    })

}

/**
 *获取出库托盘绑定列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOuttraylocalmapList(data) {
    return request({
        url: '/kitchen/outtraylocalmap/page',
        method: 'get',
        params: data
    })

}

/**
 *分配出库货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function allocateOutstorageLocation(data) {
    return request({
        url: '/kitchen/outorderdetail/allocateOutstorageLocation',
        method: 'post',
        data: data
    })

}

/**
 *批量分配出库货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchAllocateOutstorageLocation(data) {
    return request({
        url: '/kitchen/outorderdetail/batchAllocateOutstorageLocation',
        method: 'post',
        data: data
    })

}

/**
 *出库完成
 *
 * @export
 * @param {*} data
 * @returns
 */
export function OutstoreComplete(data) {
    return request({
        url: '/kitchen/outtraylocalmap/OutstoreComplete',
        method: 'post',
        data: data
    })

}

/**
 *根据ID获取出库信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOutmainorderById(data) {
    return request({
        url: '/kitchen/outmainorder/queryById',
        method: 'get',
        params: data
    })

}

/**
 *获取出库明细列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOutorderdetailList(data) {
    return request({
        url: '/kitchen/outorderdetail/page',
        method: 'get',
        params: data
    })

}

/**
 *获取调整单主表列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetPositionadjustmainList(data) {
    return request({
        url: '/kitchen/positionadjustmain/page',
        method: 'get',
        params: data
    })

}

/**
 *删除调整单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeletePositionadjustmain(data) {
    return request({
        url: '/kitchen/positionadjustmain/delete',
        method: 'post',
        data: data
    })

}

/**
 *更新调整单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdatePositionadjustmain(data) {
    return request({
        url: '/kitchen/positionadjustmain/update',
        method: 'post',
        data: data
    })

}

/**
 *添加调整单主信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddPositionadjustmain(data) {
    return request({
        url: '/kitchen/positionadjustmain/create',
        method: 'post',
        data: data
    })

}
/**
 *批量添加调整单明细信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddPositionadjustdetail(data) {
    return request({
        url: '/kitchen/positionadjustdetail/batchCreate',
        method: 'json',
        data: data
    })

}

/**
 *批量更新调整单明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdatePositionadjustdetailDesLocal(data) {
    return request({
        url: '/kitchen/positionadjustdetail/UpdateDesLocal',
        method: 'post',
        data: data
    })

}

/**
 *获取明细列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetPositionadjustdetailList(data) {
    return request({
        url: '/kitchen/positionadjustdetail/page',
        method: 'get',
        params: data
    })

}

/**
 *删除明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeletePositionadjustdetail(data) {
    return request({
        url: '/kitchen/positionadjustdetail/delete',
        method: 'post',
        data: data
    })

}

/**
 *获取正常状态非虚拟库存
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetInventorytraylocalmapListWithOutVirtual(data) {
    return request({
        url: '/kitchen/inventorytraylocalmap/pageWithNull',
        method: 'get',
        params: data
    })

}

/**
 *根据ID获取调整单主信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetPositionadjustmainByID(data) {
    return request({
        url: '/kitchen/positionadjustmain/queryById',
        method: 'get',
        params: data
    })

}

/**
 *确认完成调整明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdatePositionadjustdetail(data) {
    return request({
        url: '/kitchen/positionadjustdetail/TZComplite',
        method: 'post',
        data: data
    })

}

/**
 *查询库存预警列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getInventAlertList(data) {
    return request({
        url: '/kitchen/inventorymain/getInventAlertList',
        method: 'get',
        params: data
    })

}

/**
 *更新入库绑定
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateEntrytraylocalmap(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/update',
        method: 'post',
        data: data
    })

}


/**
 *批量修改托盘rfid和实入数量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchUpdateTrayRFIDAndRealQuantity(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/batchUpdateTrayRFIDAndRealQuantity',
        method: 'json',
        data: data
    })

}

/**
 *根据选择的来源单号生成入库单主信息和明细信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function generateEntryByOriginNumber(data) {
    return request({
        url: '/kitchen/entrymainorder/generateEntryByOriginNumber',
        method: 'post',
        data: data
    })

}

/**
 *入库调整货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function localAdjust(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/localAdjust',
        method: 'post',
        data: data
    })

}

/**
 *查询领料单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPicklistsMainList(data) {
    return request({
        url: '/kitchen/picklists/page',
        method: 'get',
        params: data
    })

}


/**
 *根据选择的来源单号生成出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function generateOutByOriginNumber(data) {
    return request({
        url: '/kitchen/outmainorder/generateOutByOriginNumber',
        method: 'post',
        data: data
    })

}

/**
 *出库调整货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function outLocalAdjust(data) {
    return request({
        url: '/kitchen/outtraylocalmap/outLocalAdjust',
        method: 'post',
        data: data
    })

}


/**
 *批量入库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchEntryComplite(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/batchEntryComplite',
        method: 'json',
        data: data
    })

}

/**
 *批量出库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchOutComplete(data) {
    return request({
        url: '/kitchen/outtraylocalmap/batchOutComplete',
        method: 'post',
        data: data
    })

}

/**
 *查询剩余量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getOutstoragesurplusList(data) {
    return request({
        url: '/kitchen/outstoragesurplus/page',
        method: 'get',
        params: data
    })

}

/**
 *更新剩余量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateOutstoragesurplusInfo(data) {
    return request({
        url: '/kitchen/outstoragesurplus/update',
        method: 'post',
        data: data
    })
}

/**
 *报损报溢
 *
 * @export
 * @param {*} data
 * @returns
 */
export function overOrLossReport(data) {
    return request({
        url: '/kitchen/outstoragesurplus/overOrLossReport',
        method: 'post',
        data: data
    })
}

/**
 *查询调拨单列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferordermainList(data) {
    return request({
        url: '/kitchen/transferordermain/page',
        method: 'get',
        params: data
    })

}

/**
 *根据仓库查询库存商品
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getInstorageByWare(data) {
    return request({
        url: '/kitchen/transferorderdetail/getInstorageByWare',
        method: 'get',
        params: data
    })

}

/**
 *创建主明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function createMainDetail(data) {
    return request({
        url: '/kitchen/transferordermain/createMainDetail',
        method: 'json',
        data: data
    })

}

/**
 *根据id查询主信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferordermainById(data) {
    return request({
        url: '/kitchen/transferordermain/queryById',
        method: 'get',
        params: data
    })

}

/**
 *获取详情列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferorderdetails(data) {
    return request({
        url: '/kitchen/transferorderdetail/page',
        method: 'get',
        params: data
    })

}


/**
 *获取调拨单打印信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferorderPrintData(data) {
    return request({
        url: '/kitchen/transferordermain/getPrintData',
        method: 'get',
        params: data
    })

}


/**
 *获取出库单打印信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getOutmainorderPrintData(data) {
    return request({
        url: '/kitchen/outmainorder/getPrintData',
        method: 'get',
        params: data
    })

}

/**
 *获取出库单打印信息
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function getOutmainorderPrintDataBatch(data) {
    return request({
        url: '/kitchen/outmainorder/getPrintDataBatch',
        method: 'get',
        params: data
    })

}


/**
 *删除详情
 *
 * @export
 * @param {*} data
 * @returns
 */
export function delTransferorderdetailById(data) {
    return request({
        url: '/kitchen/transferorderdetail/delete',
        method: 'post',
        data: data
    })

}

/**
 *更新主明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateMainDetail(data) {
    return request({
        url: '/kitchen/transferordermain/updateMainDetail',
        method: 'json',
        data: data
    })

}

/**
 *删除调拨单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function delTransferordermainByIds(data) {
    return request({
        url: '/kitchen/transferordermain/delete',
        method: 'post',
        data: data
    })

}

/**
 *批量确认调拨单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchConfirmTransferorder(data) {
    return request({
        url: '/kitchen/transferordermain/batchConfirm',
        method: 'post',
        data: data
    })

}

/**
 *导出出库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportOutOrderToExcel(data) {
    return request({
        url: '/kitchen/outmainorder/exportToExcel',
        method: 'get',
        params: data
    })

}

/**
 *导入入库单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function importExcelToEntryOrder(data) {
    return request({
        url: '/kitchen/entrymainorder/importExcelToOrder',
        method: 'json',
        data: data
    })

}
/**
 *撤销调拨单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function cancelTransferorder(data) {
    return request({
        url: '/kitchen/transferordermain/cancelTransferorder',
        method: 'post',
        data: data
    })

}
export function getPrintEntryorderdetailList(data) {
    return request({
        url: '/kitchen/entryorderdetail/getPrintEntryorderdetailList',
        method: 'get',
        params: data
    })

}
export function getPrintEntryorderdetailListBatch(data) {
    return request({
        url: '/kitchen/entryorderdetail/getPrintEntryorderdetailListBatch',
        method: 'get',
        params: data
    })

}
export function getPrintOutderdetailList(data) {
    return request({
        url: '/kitchen/outorderdetail/getPrintOutderdetailList',
        method: 'get',
        params: data
    })

}

/**
 * 判断出库单详情数量是否发生改变
 * @param {*} data 
 */
export function checkNumber(data){
    return request({
        url: '/kitchen/outorderdetail/checkNumber',
        method: 'post',
        data: data
    })

}


/**
 * 更新出库详情草稿
 * @param {*} data 
 */
export function updateList(data){
    return request({
        url: '/kitchen/outorderdetail/updateList',
        method: 'post',
        data: data
    })

}


/**
 *修改成本价
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateNotaxPrice(data) {
    return request({
        url: '/kitchen/entryorderdetail/updateNotaxPrice',
        method: 'json',
        data: data
    })

}
/**
 *获取剩余量详情
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getOutstorageSurplusDetail(data) {
    return request({
        url: '/kitchen/outstoragesurplus/getOutstorageSurplusDetail',
        method: 'get',
        params: data
    })

}

/**
 *获取剩余量详情
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferApplyMainPages(data) {
    return request({
        url: '/kitchen/transferApplyMain/page',
        method: 'get',
        params: data
    })
}

/**
 *新增调拨申请主明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function createTransferApplyMainDetail(data) {
    return request({
        url: '/kitchen/transferApplyMain/createMainDetail',
        method: 'json',
        data: data
    })
}

/**
 *根据id获取主信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferapplymainById(data) {
    return request({
        url: '/kitchen/transferApplyMain/queryById',
        method: 'get',
        params: data
    })
}

/**
 *根据主表ID获取明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferapplydetails(data) {
    return request({
        url: '/kitchen/transferApplyDetail/page',
        method: 'get',
        params: data
    })
}


/**
 *更新调拨申请主明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateTransferApplyMainDetail(data) {
    return request({
        url: '/kitchen/transferApplyMain/updateMainDetail',
        method: 'json',
        data: data
    })
}

/**
 *审核申请单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function auditApplyOrder(data) {
    return request({
        url: '/kitchen/transferApplyMain/auditApplyOrder',
        method: 'post',
        data: data
    })
}


/**
 *删除申请单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function deleteApplyOrder(data) {
    return request({
        url: '/kitchen/transferApplyMain/delete',
        method: 'post',
        data: data
    })
}

/**
 *撤销申请单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function revokeApplyOrder(data) {
    return request({
        url: '/kitchen/transferApplyMain/revokeApplyOrder',
        method: 'post',
        data: data
    })
}


/**
 *打印申请单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getTransferApplyMainPrintData(data) {
    return request({
        url: '/kitchen/transferApplyMain/getPrintData',
        method: 'get',
        params: data
    })
}

/**
 *确认剩余量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function confirmSurplus(data) {
    return request({
        url: '/kitchen/outmainorder/confirmSurplus',
        method: 'post',
        data: data
    })

}


/**
 *判断是否分配了货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function checkIsDistributeLocals(data) {
    return request({
        url: '/kitchen/entrymainorder/checkIsDistributeLocals',
        method: 'get',
        params: data
    })
}

/**
 *查询可用库存根据仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getAvaliableStorageByWareCode(data) {
    return request({
        url: '/kitchen/inventorytraylocalmap/getAvaliableStorageByWareCode',
        method: 'get',
        params: data
    })
}


/**
 *根据商品ids获取成本价
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getAveragePriceBySkuIds(data) {
    return request({
        url: '/kitchen/outorderdetail/getAveragePriceBySkuIds',
        method: 'get',
        params: data
    })
}

/**
 *exportEntryOrder
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportEntryOrder(data) {
    return request({
        url: '/kitchen/entrymainorder/download/exportEntryOrder',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *exportEntryOrder
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportOutOrder(data) {
    return request({
        url: '/kitchen/outmainorder/download/exportOutOrder',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *exportPositionAdjust
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportPositionAdjust(data) {
    return request({
        url: '/kitchen/positionadjustmain/download/exportPositionAdjust',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *exportOutstorageSurplus
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportOutstorageSurplus(data) {
    return request({
        url: '/kitchen/outstoragesurplus/download/exportOutstorageSurplus',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *exportSatelliteKitchen
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportSatelliteKitchen(data) {
    return request({
        url: '/kitchen/transferApplyMain/download/exportSatelliteKitchen',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *exportTransferOrder
 *
 * @export
 * @param {*} data
 * @returns
 */
export function exportTransferOrder(data) {
    return request({
        url: '/kitchen/transferordermain/download/exportTransferOrder',
        method: 'post',
        data: data,
        responseType:'arraybuffer',
    })
}

/**
 *testRepeatRequest
 *
 * @export
 * @param {*} data
 * @returns
 */
export function testRepeatRequest(data) {
    return request({
        url: '/kitchen/entrymainorder/testRepeatRequest',
        method: 'post',
        data: data,
    })
}

/**
 * 撤销分配货位
 * @param data
 * @returns {AxiosPromise}
 */
export function cancelAllocationLocal(data) {
    return request({
        url: '/kitchen/outorderdetail/cancelAllocationLocal',
        method: 'post',
        data: data
    })

}

/**
 * 异常货位分配，分页获取未被占用的库房货位信息
 * @param data
 * @returns {AxiosPromise}
 */
 export function notTakeUpAbnormalGoodLocationPage(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/notTakeUpAbnormalGoodLocationPage',
        method: 'post',
        data: data
    })

}

/**
 * 异常货位分配，插入入库库存托盘货位信息
 * @param data
 * @returns {AxiosPromise}
 */
 export function insertEntrytarylocalmapData(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/insertEntrytarylocalmapData',
        method: 'post',
        data: data
    })

}


/**
 *判断是否能异常货位分配
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function checkIsAbnormalGoodLocationDistribution(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/checkIsAbnormalGoodLocationDistribution',
        method: 'get',
        params: data
    })
}

/**
 *删除数据
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function remove(data) {
    return request({
        url: '/kitchen/entrytraylocalmap/remove',
        method: 'get',
        params: data
    })
}

/**
 *单个出库完成，返回异常货位信息
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function outstoreCompleteValidateStock(data) {
    return request({
        url: '/kitchen/outtraylocalmap/outstoreCompleteValidateStock',
        method: 'post',
        data: data
    })

}

/**
 *批量出库，返回异常货位信息
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function batchOutCompleteValidateStock(data) {
    return request({
        url: '/kitchen/outtraylocalmap/batchOutCompleteValidateStock',
        method: 'post',
        data: data
    })

}

/**
 *出库单明细批量出库，返回异常货位信息
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function batchOutDetailCompleteValidateStock(data) {
    return request({
        url: '/kitchen/outtraylocalmap/batchOutDetailCompleteValidateStock',
        method: 'post',
        data: data
    })

}

/**
 *释放货位信息
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function releaseLocations(data) {
    return request({
        url: '/kitchen/outtraylocalmap/releaseLocations',
        method: 'post',
        data: data
    })

}



