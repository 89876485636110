<template>
	<div style="height: 100%;" class="d2-crud-custom">
		<slot></slot>
	</div>
</template>
<script>
	export default {name: 'table-container'}
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/table-container/index.vue"
}
</vue-filename-injector>
