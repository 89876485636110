<template>
  <el-collapse v-model="activeName" style="padding: 10px">
    <el-collapse-item name="1">
      <template slot="title">
        <span style="font-weight: bold">盈亏单详情</span>
      </template>
      <el-card class="box-card" style="margin-top: 0px">
        <el-form :model="form" :inline="true" :label-width="labelWidth">
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="9">
              <el-form-item label="规格名称">
                <el-input
                  v-model="form.skuName"
                  placeholder="规格名称"
                  style="width: 150px"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="9">
              <el-form-item label="商品名称">
                <el-input
                  v-model="form.productName"
                  placeholder="商品名称"
                  style="width: 150px"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-button
                type="primary"
                @click="handleSearchTable"
                class="sys-primary-btn"
                >查询</el-button
              >
              <el-button @click="handleFormReset" class="sys-btn">
                <d2-icon name="refresh" />重置
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <d2-crud
            ref="d2Table"
            :columns="columns"
            :data="tabledata"
            :loading="loading"
          ></d2-crud>
        </div>
        <div>
          <el-pagination
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            :page-sizes="pagination.pageSizeArr"
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: -10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <div slot="footer" class="dialog-footer flex-x-end">
          <el-button type="primary" @click="cancel" class="sys-btn"
            >关闭</el-button
          >
        </div>
      </el-card>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import { GetProfitlossDetailList } from "@api/operate.profitlossDetailQueryInfo";
import mixin from "@/plugin/mixins/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      form: {
        id: "",
        skuName: "",
        productName: "",
      },
      labelWidth: "110px",
      loading: false,
      tabledata: [],
      activeName:"1",
      columns: [
        {
          title: "批次号",
          key: "batchNo",
          showOverflowTooltip: true,
        },
        {
          title: "商品名称",
          key: "productName",
          showOverflowTooltip: true,
        },
        {
          title: "规格名称",
          key: "skuName",
          showOverflowTooltip: true,
        },
        {
          title: "账面数",
          key: "bookAmount",
          showOverflowTooltip: true,
          component: {
            name: "EU-Unit",
            props: {
              valuekey: "bookAmount",
            },
          },
        },
        {
          title: "规格账面数",
          key: "skuBookAmount",
          showOverflowTooltip: true,
          formatter: function (row, column, cellValue, index) {
            if (!row.skuBookAmount) {
              return 0 + "(" + row.measureName + ")";
            } else {
              return row.skuBookAmount + "(" + row.measureName + ")";
            }
          },
        },
        {
          title: "实盘数",
          key: "actualAmount",
          showOverflowTooltip: true,
          component: {
            name: "EU-Unit",
            props: {
              valuekey: "actualAmount",
            },
          },
        },
        {
          title: "规格实盘数",
          key: "skuActualAmount",
          showOverflowTooltip: true,
          formatter: function (row, column, cellValue, index) {
            if (!row.skuActualAmount) {
              return 0 + "(" + row.measureName + ")";
            } else {
              return row.skuActualAmount + "(" + row.measureName + ")";
            }
          },
        },
        {
          title: "差异数",
          key: "differenceAmount",
          showOverflowTooltip: true,
          component: {
            name: "EU-Unit",
            props: {
              valuekey: "differenceAmount",
            },
          },
        },
        {
          title: "规格差异数",
          key: "skuDifferenceAmount",
          showOverflowTooltip: true,
          formatter: function (row, column, cellValue, index) {
            if (!row.skuDifferenceAmount) {
              return 0 + "(" + row.measureName + ")";
            } else {
              return row.skuDifferenceAmount + "(" + row.measureName + ")";
            }
          },
        },
        {
          title: "类型",
          key: "type",
          component: {
            name: "EU-elTag",
            props: {
              datatype: "PROFITLOSS_TYPE",
            },
          },
        },
        {
          title: "rfid编号",
          key: "trayRfid",
          showOverflowTooltip: true,
        },
        {
          title: "货位描述",
          key: "localDescript",
          showOverflowTooltip: true,
        },
        {
          title: "创建人",
          key: "createdPerson",
          showOverflowTooltip: true,
        },
        {
          title: "创建时间",
          key: "createdDate",
          showOverflowTooltip: true,
        },
      ],
    };
  },
  props: {
    modal: {
      default: false,
    },
    modaltype: {
      default: "search",
    },
    modalObj: {
      default: {},
    },
  },
  computed: {},
  async mounted() {
    Object.assign(this.form, this.modalObj);
    this.handleSearchTable();
  },
  created() {},
  methods: {
    handleFormSubmit() {
      this.handleSearchTable();
    },
    handleFormReset() {
      this.form.skuName = null;
      this.form.productName = null;
      this.handleSearchTable();
    },
    async handleSearchTable() {
      this.loading = true;
      const params = {};
      Object.assign(params, {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        mainId: this.form.id,
        skuName: this.form.skuName,
        productName: this.form.productName,
        conditionLikeFields: "skuName",
        conditionLikeFields: "productName",
      });
      const result = await GetProfitlossDetailList(params);
      this.tabledata = result.aaData;
      this.pagination.total = result.dataCount;
      this.loading = false;
      //this.$log.capsule("结果", JSON.stringify(result), "primary");
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.handleSearchTable();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.handleSearchTable();
    },
    cancel() {
      this.$emit("closemodal", false);
    },
    beforeClose(done) {
      this.cancel();
      done();
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/operate/warehouse/profitloss/profitlossQueryModel/workflowProfitlossDetailQuery.vue"
}
</vue-filename-injector>
