// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
import {
  dbGet
} from '@/libs/util.db'
// 核心插件
import d2Admin from '@/plugin/d2admin'
// store
import store from '@/store/index'

// [ 可选组件 ]D2-Crud
// import D2Crud from '@d2-projects/d2-crud'
// 引用修改过后的表格组件
import D2Crud from '@/components/d2-crud-custom'

import VCharts from 'v-charts'
import 'v-charts/lib/style.css'

// [ 可选插件 ] 数据导入导出
import pluginExport from '@d2-projects/vue-table-export'

// [ 可选插件 ] 表格导入
import pluginImport from '@/plugin/vue-table-import' // 菜单和路由设置
import router from './router'
//引入VueNumeric
import VueNumeric from 'vue-numeric'
import VueAMap from 'vue-amap';
import '@/assets/style/fixed/flex.scss'
import util from '@/libs/util.js'

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '50be3ad63dc294d654467785049c72eb',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', "AMap.MouseTool"],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

Vue.use(VueNumeric)
//import menuHeader from '@/menu/header'
//import menuAside from '@/menu/aside'
import {
  frameInRoutes
} from '@/router/routes'
import Print from 'vue-print-nb'
Vue.use(Print);

// 核心插件
Vue.use(d2Admin)
// 可选插件组件
Vue.use(D2Crud)

Vue.use(VCharts)

Vue.use(pluginExport)

Vue.use(pluginImport)


//20191129邓文熙增加全局自定义指令用于显示隐藏按钮
Vue.directive('has', {
  inserted: async function (el, binding, vnode) {
    try {
      //20210119邓文熙添加自定义指令用于控制按钮重复提交
      if (waitTime && waitTime > 0) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true;
            let classs = el.getAttribute("class");
            classs = classs.concat(" is-disabled");
            el.setAttribute("class", classs);
            setTimeout(() => {
              el.disabled = false;
              let classs = el.getAttribute("class");
              classs = classs.replace(" is-disabled", "");
              el.setAttribute("class", classs);
            }, waitTime) //等待时间在index.html 配置
          }
        })
      }
      if (binding.value && binding.value != '') {
        //得到该页面url
        const baseURI = vnode.context.$el.baseURI || vnode.elm.baseURI;
        //获取有权限的所有页面按钮
        const ModuleButton = await dbGet({
          dbName: 'sys',
          path: 'buttonItemMap',
          defaultValue: [],
          user: true
        });
        //debugger;
        //得到该页面的路由名称
        const pageroute = baseURI.substring(baseURI.lastIndexOf('/'));
        //得到该页面的权限按钮
        const pagebutton = ModuleButton[pageroute] || [];
        //找出该按钮是否在权限内
        const check = pagebutton.filter((item, idex, arr) => {
          return item.name == binding.value;
        })
        if (check.length <= 0)
          el.parentNode.removeChild(el);
      }
    } catch (error) {
      throw error;
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  async created() {
    // 缓存省市区数据    
    util.cacheArea.call(this)

    // 处理路由 得到每一级的路由设置
    this.$store.commit('zycf/page/init', frameInRoutes)
    // 设置顶栏菜单
    // 设置顶栏菜单
    const menuHeader = await this.$store.dispatch('zycf/db/get', {
      dbName: 'sys',
      path: 'headermenus',
      defaultValue: [],
      user: true
    }, {
      root: true
    });
    this.$store.commit('zycf/menu/headerSet', menuHeader)
    // 设置侧边栏菜单
    //this.$store.commit('zycf/menu/asideSet', menuAside)
    // 初始化菜单搜索功能
    this.$store.commit('zycf/search/init', menuHeader)

    //设置所有路由列表
    const pagepaths = await this.$store.dispatch('zycf/db/get', {
      dbName: 'sys',
      path: 'pagePaths',
      defaultValue: [],
      user: true
    }, {
      root: true
    });
    this.$store.commit('zycf/menu/pagePathsSet', pagepaths)
  },
  mounted() {
    // 展示系统信息
    this.$store.commit('zycf/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('zycf/account/load')
    // 获取并记录用户 UA
    this.$store.commit('zycf/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('zycf/fullscreen/listen')
  },
  watch: {
    '$route.path': {
      async handler(to, from) {
        var matched = this.$route.matched
        if (matched.length > 0) {
          const menuAside = await this.$store.dispatch('zycf/db/get', {
            dbName: 'sys',
            path: 'asidemenus',
            defaultValue: [],
            user: true
          }, {
            root: true
          });

          //默认取第一个路径
          let path = matched[0].path;
          //当第一个路径为空字符串时，表示只有一级菜单如首页
          if (matched[0].path == '') {
            path = matched[1].path;
            if(path === '/index/selltongji' || path === '/index/processtongji') {
              path = '/index'
            }
          }

          const _side = menuAside.filter(menu => menu.path === path);


          if (matched[0].path == '') { //此逻辑控制当只有一级菜单时，左侧菜单栏也需要显示该菜单，以前是没有
            this.$store.commit('zycf/menu/asideSet', _side.length > 0 ? _side[0].children : _side);
          } else {
            this.$store.commit('zycf/menu/asideSet', _side.length > 0 ? _side[0].children : []);
          }
        }
      }
    },
    // 检测路由变化切换侧边栏内容
    '$route.matched': {
      async handler(matched) {
      },
      immediate: true
    }
  }
}).$mount('#app')