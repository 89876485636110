<template>
  <el-tooltip effect="dark" content="常用功能" placement="bottom">
    <el-dropdown trigger="click">
      <el-button class="d2-mr btn-text can-hover" type="text">
        <d2-icon name="th-large" style="font-size: 18px;" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item,index) in pathDatas"
          :key="index"
          @click.native="pathmenu(item)"
          style="width:104px;text-align:center;padding:5px 10px"
          class="text"
        >{{item.name}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-tooltip>
</template>

<script>
import mixin from '@/plugin/mixins/mixin'
import quickNavigation from '@/api/quicknavigation.js'
export default {
  mixins: [mixin],
  data() {
    return {
      systemPath: '',
      newData: [],
      pathDatas: []
    }
  },
  mounted() {
    this.getPath()
  },
  methods: {
    async getPath() {
      let res = await quickNavigation.quickNavigationQuick()
      const menuAside = await this.$store.dispatch(
        'zycf/db/get',
        {
          dbName: 'sys',
          path: 'asidemenus',
          defaultValue: [],
          user: true
        },
        {
          root: true
        }
      )
      let pathData = []
      let datamenu = this.menudata(menuAside)
      res.aaData.forEach(item => {
        datamenu.forEach(obj => {
          if (item.url === obj.path) {
            pathData.push(obj)
          }
        })
      })
      this.pathDatas = pathData
    },
    pathmenu(row) {
      this.$router.push(row.path)
    },
    menudata(arr) {
      arr.forEach(item => {
        if (item.children) {
          this.menudata(item.children)
        } else {
          this.newData.push({
            name: item.name,
            path: item.path
          })
          return
        }
      })
      return this.newData
    }
  }
}
</script>
<style scoped>
.text {
  font-size: 14px !important;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-changyong/index.vue"
}
</vue-filename-injector>
