import {
  mapState
} from 'vuex'
import menuMixin from '../mixin/menu'
import {
  elMenuItem,
  elSubmenu
} from '../libs/util.menu'
import BScroll from 'better-scroll'

/**
 *获取存在页面数里的路由
 *
 * @param {*} pagepaths 所有页面路由数组 
 * @param {*} currentpath  当前路由
 * @param {*} number  递归次数,避免无限递归
 * @returns
 */
function getParentPath(pagepaths, currentpath, number) {
  if (pagepaths.has(currentpath) || number <= 0) return currentpath;
  return getParentPath(pagepaths, currentpath.substring(0, currentpath.lastIndexOf('/')), number - 1)
}

export default {
  name: 'd2-layout-header-aside-menu-side',
  mixins: [
    menuMixin
  ],
  render(createElement) {
    
    return createElement('div', {
      attrs: {
        class: 'd2-layout-header-aside-menu-side'
      }
    }, [
      createElement('el-menu', {
        props: {
          collapse: this.asideCollapse,
          uniqueOpened: true,
          defaultActive: this.active,
          defaultOpeneds: this.opensubmenu
        },
        ref: 'menu',
        on: {
          select: this.handleMenuSelect
        }
      }, this.aside.map(menu => (menu.children === undefined ? elMenuItem : elSubmenu).call(this, createElement, menu))),
      ...this.aside.length === 0 && !this.asideCollapse ? [
        createElement('div', {
          attrs: {
            class: 'd2-layout-header-aside-menu-empty',
            flex: 'dir:top main:center cross:center'
          }
        }, [
          createElement('d2-icon', {
            props: {
              name: 'inbox'
            }
          }),
          createElement('span', {}, '没有侧栏菜单')
        ])
      ] : []
    ])
  },
  data() {
    return {
      active: '',
      asideHeight: 300,
      BS: null,
      opensubmenu: []
    }
  },
  computed: {
    ...mapState('zycf/menu', [
      'aside',
      'asideCollapse',
      'pagePaths'
    ]),
    defaultActive() {
      //return '/' + this.$route.path.split('/').reverse()[0];
      return this.$route.path;
    }
  },
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse(val) {
      this.scrollDestroy()
      setTimeout(() => {
        this.scrollInit()
      }, 500)
    },
    // 监听路由 控制侧边栏激活状态
    '$route.fullPath': {
      handler(value) {
        /**20200729 dwx 添加处理打开没菜单的子页面**/
        let _active = value;
        //找出该路由在左侧栏是否存在
        if (this.pagePaths.length > 0) {
          let pagePaths_set = new Set(this.pagePaths);
          _active = getParentPath(pagePaths_set, value, 5);
        }
        this.active = _active;
        //设置展开的submenu
        let submenuindex = this.active.substring(0, this.active.lastIndexOf('/'));

        this.opensubmenu = [submenuindex];
        setTimeout(() => {
          // 防止时序问题
          if (this.aside.length > 0) {
            this.$refs.menu.activeIndex = this.active;
            //let submenuindex=this.active.substring(0,this.active.lastIndexOf('/'));
            //this.$refs.menu.open(submenuindex);
          }
        }, 500);
        // this.$nextTick(() => {
        //   // 防止时序问题
        //   if (this.aside.length > 0) {
        //     this.$refs.menu.activeIndex = this.active;
        //     //let submenuindex=this.active.substring(0,this.active.lastIndexOf('/'));
        //     //this.$refs.menu.open(submenuindex);
        //   }
        // });

      },
      immediate: true
    }
  },
  mounted() {
    this.scrollInit()
  },
  beforeDestroy() {
    this.scrollDestroy()
  },
  methods: {
    scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true
        // 如果你愿意可以打开显示滚动条
        // scrollbar: {
        //   fade: true,
        //   interactive: false
        // }
      })
    },
    scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy()
      } catch (e) {
        delete this.BS
        this.BS = null
      }
    },

  }
}