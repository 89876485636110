/**
 * 生产资料路由
 */
export default function (pre, _import, meta) {
    return [{
            path: 'producegoods/workshop',
            name: `${pre}warehouse-workshop`,
            component: _import('operate/warehouse/workshop/index'),
            props: true,
            meta: {
                cache: true,
                title: '车间管理'
            }
        },
        {
            path: 'producegoods/workgroup',
            name: `${pre}user-workgroup`,
            component: _import('operate/user/workgroup/index'),
            meta: {
                ...meta,
                title: '班组管理'
            }
        },
        {
            path: 'producegoods/bom',
            name: `${pre}bom-bom`,
            component: _import('operate/product/bom/index'),
            meta: {
                ...meta,
                title: '成品BOM管理'
            }
        },
        {
            path: 'producegoods/bomReplace',
            name: `${pre}bomReplace-bomReplace`,
            component: _import('operate/product/bom-replace/index'),
            meta: {
                ...meta,
                title: '成品BOM替代料'
            }
        },
        {
            path: 'producegoods/packingBox',
            name: `${pre}packingBox-packingBox`,
            component: _import('operate/packing/bom/index'),
            meta: {
                ...meta,
                title: '周转器皿管理'
            }
        },
        {
            path: 'producegoods/experimentMateriel',
            name: `${pre}experimentMateriel-experimentMateriel`,
            component: _import('operate/experimentMateriel/index'),
            meta: {
                ...meta,
                title: '实验物料管理'
            }
        },
        {
            path: 'producegoods/experimentPicking',
            name: `${pre}experimentPicking-experimentPicking`,
            component: _import('operate/experimentPicking/index'),
            meta: {
                ...meta,
                title: '实验物料领用'
            }
        },
        {
            path: 'producegoods/productOutputForecast',
            name: `${pre}productOutputForecast`,
            component: _import('operate/product/output-forecast/index'),
            meta: {
                ...meta,
                title: '商品产出量预测'
            }
        },
        {
            path: 'producegoods/semimanufacturesbom',
            name: `${pre}semimanufacturesbom-bom`,
            component: _import('operate/product/semimanufacturesBom/index'),
            meta: {
                ...meta,
                title: '半成品BOM管理'
            }
        },
        {
            path: 'producegoods/semimanufacturesbomReplace',
            name: `${pre}semimanufacturesbomReplace-bomReplace`,
            component: _import('operate/product/semimanufacturesBom-replace/index'),
            meta: {
                ...meta,
                title: '半成品BOM替代料'
            }
        },
        {
            path: 'producegoods/propack',
            name: `${pre}packingBox-propack`,
            component: _import('operate/product/pack/index'),
            meta: {
                ...meta,
                title: '包装管理'
            }
        },
    ]
}
