import request from '@/plugin/axios';


/**
 * 基地数据分页
 * 
*/
export function page(data) {
    return request({
        url: '/kitchen/satelliteKitchen/page',
        method: 'get',
        params: data
    })
}

/**
 * 创建一个新的卫星厨房
 * 
*/
export function create(data) {
    return request({
        url: '/kitchen/satelliteKitchen/create',
        method: 'json',
        data: data,
        headers: {'Content-Type': 'application/json'}
    })
}


/**
 * 更新一个卫星厨房
 * 
*/
export function update(data) {
    return request({
        url: '/kitchen/satelliteKitchen/update',
        method: 'json',
        data: data,
        headers: {'Content-Type': 'application/json'}
    })
}

/**
 * 删除一个卫星厨房
*/
export function remove(data) {
    return request({
        url: '/kitchen/satelliteKitchen/delete',
        method: 'post',
        data: data
    })
}

export function switchEnable(data) {
    return request({
        url: '/kitchen/satelliteKitchen/switchEnable',
        method: 'post',
        data: data
    })
}

/**
 * 查询一个卫星厨房
 * 
*/
export function get(data) {
    return request({
        url: '/kitchen/satelliteKitchen/queryById',
        method: 'get',
        params: data
    })
}

/**
 *导出
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function ExportExcelData(data) {
    return request({
      url: '/kitchen/satelliteKitchen/download/exportExcel',
      method: 'post',
      responseType: 'blob',
      data: data
    })
  }


/**
 * 查询卫星厨房下拉框
 * 
 */
export function listSatelliteKitchen(data) {
    return request({
        url: '/kitchen/satelliteKitchen/listSatelliteKitchen',
        method: 'get',
        params: data
    })
}