import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/OrderStatistics'


var obj = {}
obj.indexTotalTradStatistics = function(data) {
	return util.baseJsonRequest(data, rootURL + '/indexTotalTradStatistics')
}
obj.indexTradingTrendStatistics = function(data) {
	return util.baseJsonRequest(data, rootURL + '/indexTradingTrendStatistics')
}
obj.categoryOfBestSellers = function(data) {
	return util.baseJsonRequest(data, rootURL + '/categoryOfBestSellers')
}
obj.approachSituation = function(data) {
	return util.baseJsonRequest(data, rootURL + '/approachSituation')
}
obj.purchaseRanking = function(data) {
	return util.baseJsonRequest(data, rootURL + '/purchaseRanking')
}
obj.sellRanking = function(data) {
	return util.baseJsonRequest(data, rootURL + '/sellRanking')
}
obj.purchaseSkuRanking = function(data) {
	return util.baseJsonRequest(data, rootURL + '/purchaseSkuRanking')
}
export default obj