// Element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// flex 布局库
import 'flex.css'
// 组件
import '@/components'
// svg 图标
import '@/assets/svg-icons'
// 国际化
import i18n from '@/i18n.js'

// 功能插件
import pluginError from '@/plugin/error'
import pluginLog from '@/plugin/log'
import pluginOpen from '@/plugin/open'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

export default {
  async install(Vue, options) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    // https://cn.vuejs.org/v2/api/#productionTip
    Vue.config.productionTip = false
    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV
    // 当前的 baseUrl
    Vue.prototype.$baseUrl = process.env.BASE_URL
    // 当前版本
    Vue.prototype.$version = process.env.VUE_APP_VERSION
    // 构建时间
    Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME
    // 当前系统ID
    Vue.prototype.$sysId = process.env.VUE_APP_SYS_ID
    //当前系统是加工系统
    Vue.prototype.$isProcess = process.env.VUE_APP_SYS_NAME === 'process' ? true : false;
    //当前系统是采购系统
    Vue.prototype.$isPurchase = process.env.VUE_APP_SYS_NAME === 'purchase' ? true : false;

    // Element消息提示封装
    Vue.prototype.$success = message =>
      ElementUI.Message({
        message,
        type: 'success'
      })
    Vue.prototype.$warning = message =>
      ElementUI.Message({
        message,
        type: 'warning'
      })
    Vue.prototype.$error = message =>
      ElementUI.Message({
        message,
        type: 'error'
      })
    Vue.prototype.$info = message =>
      ElementUI.Message({
        message,
        type: 'info'
      })
    // Element
    Vue.use(ElementUI, {
      i18n: (key, value) => i18n.t(key, value)
    })
    // 插件
    Vue.use(pluginError)
    Vue.use(pluginLog)
    Vue.use(pluginOpen)
    Vue.use(Viewer);
  }
}