import request from '@/plugin/axios'

/*****************************
 * 采购明细 后端API
 *
 * @author 蒙赛
 * @Date 2020/04/26
 *****************************/

/**
 * 分页获取
 *
 * @export
 * @param {*} data
 * @returns
 */
export function QueryPurchaseDemandDetailPage(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/page',
    method: 'get',
    params: data,
  })
}

export function QueryPurchaseDemandDetailPageT(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/pageT',
    method: 'get',
    params: data,
  })
}

/**
 * 根据ID 查询信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function QueryPurchaseDemandDetailById(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/queryById',
    method: 'get',
    params: data,
  })
}

/**
 * 新增
 *
 * @export
 * @param {*} data
 * @returns
 */
export function CreatePurchaseDemandDetail(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/create',
    method: 'post',
    data: data,
  })
}

/**
 * 修改
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdatePurchaseDemandDetail(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/update',
    method: 'post',
    data: data,
  })
}

/**
 * 删除
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeletePurchaseDemandDetail(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/delete',
    method: 'post',
    data: data,
  })
}

/**
 * 根据主表ID获取详情列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function queryListByMainId(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/queryListByMainId',
    method: 'get',
    params: data,
  })
}

/**
 * 根据规格ID列表获取他的分拣定级商品
 *
 * @export
 * @param {*} data
 * @returns
 */
export function querySortGradBySkuId(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/querySortGradBySkuId',
    method: 'get',
    params: data,
  })
}

/**
 * 根据需求明细ID获取定级商品比例列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getSortgradRatioList(data) {
  return request({
    url: '/kitchen/SortgradRatio/page',
    method: 'get',
    params: data,
  })
}

/**
 * 根据需求明细ID获取定级商品比例列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchCreateSortgradRatio(data) {
  return request({
    url: '/kitchen/SortgradRatio/batchCreate',
    method: 'json',
    data: data,
  })
}
/**
 * 获取输入规格量转换基本量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getSkuBasicUnitQuality(data) {
  return request({
    url: '/kitchen/ProductSku/getSkuBasicUnitQuality',
    method: 'get',
    params: data,
  })
}
/**
 * 获取输入规格量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getSkuUnitQuality(data) {
  return request({
    url: '/kitchen/ProductSku/getSkuUnitQuality',
    method: 'get',
    params: data,
  })
}
/**
 * 获取输入规格量
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getToTalRatioByDemandDetailId(data) {
  return request({
    url: '/kitchen/SortgradRatio/getToTalRatioByDemandDetailId',
    method: 'get',
    params: data,
  })
}

/**
 * 获取销售单明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getSaleOrderDetails(data) {
  return request({
    url: '/kitchen/purchaseDemandDetail/getSaleOrderDetails',
    method: 'get',
    params: data,
  })
}
