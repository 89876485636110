import request from '@/plugin/axios'
/**
 *登录
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AccountLogin(data) {
  return request({
    baseURL: '',
    url: '/login/login',
    method: 'post',
    data
  })
}

/**
 *获取验证码
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetAuthCode(data) {
  return request({
    responseType: 'arraybuffer',
    baseURL: '',
    url: '/login/auth/code',
    method: 'get',
    params: data
  })
}
/**
 *获取数据字典
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDataDic(data) {
  return request({
    url: '/kitchen/datadic/page',
    method: 'get',
    params: data
  })
}
/**
 *获取日志列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetLogBusinesslist(data) {
  return request({
    url: '/user/logBusiness/list',
    method: 'get',
    params: data
  })
}
/**
 *获取系统列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetSysList(data) {
  return request({
    url: '/permission/tsysSys/list',
    method: 'get',
    params: data
  })
}

/**
 *获取改用户包含的角色的所有模块和按钮列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function initMenusAllTree(data) {
  return request({
    url: '/permission/tsysRoleModlePermission/initMenusAllTree',
    method: 'get',
    params: data
  })
}

/**
 *新增字典
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDataDic(data) {
  return request({
    url: '/kitchen/datadic/create',
    method: 'post',
    data: data
  })
}

/**
 *编辑字典
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateDataDic(data) {
  return request({
    url: '/kitchen/datadic/update',
    method: 'post',
    data: data
  })
}


/**
 *删除字典
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteDataDic(data) {
  return request({
    url: '/kitchen/datadic/delete',
    method: 'post',
    data: data
  })
}
/**
 *获取仓库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWarList(data) {
  return request({
    url: '/kitchen/warehouseinfo/page',
    method: 'get',
    params: data
  })
}
/**
 *获取货位类型列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetLocalTypeList(data) {
  return request({
    url: '/kitchen/localtype/page',
    method: 'get',
    params: data
  })
}
/**
 *生成货位
 * @export
 * @param {*} data
 * @returns
 */
export function generateLocations(data) {
  return request({
    url: '/kitchen/locationinfo/generateLocations',
    method: 'post',
    data: data
  })
}

/**
 * 获取openid
 * @export
 * @param {*} data
 * @returns
 */
export function scanLogin(data) {
  return request({
    url: '/user/wx/scanLogin',
    method: 'post',
    data: data,
    overapi: 1
  })
}

