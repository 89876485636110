/**
 * 检疫检测
 */
export default function(pre, _import, meta) {
	return [
		{
	      path: 'detection/detectionmanage',
	      name: `${pre}detection-detectionmanage`,
	      component: _import('operate/detection/detectionmanage/index'),
	      meta: {
	        ...meta,
	        title: '检测单管理'
	      }
  		},
	    {
	      path: 'detection/checkitem',
	      name: `${pre}detection-checkitem`,
	      component: _import('operate/detection/checkitem/index'),
	      meta: {
	        ...meta,
	        title: '检测项管理'
	      }
	    },
	]
}