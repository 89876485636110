/**
 * 用户权限路由
 */
export default function (pre, _import, meta) {
    return [{
            path: 'userrole/organizemanage',
            name: `${pre}user-organizemanage`,
            component: _import('operate/user/organizemanage/index'),
            meta: {
                ...meta,
                title: '组织管理'
            }
        },
        {
            path: 'userrole/postmanage',
            name: `${pre}user-postmanage`,
            component: _import('operate/user/postmanage/index'),
            meta: {
                ...meta,
                title: '职位管理'
            }
        },
        {
            path: 'userrole/officemember',
            name: `${pre}user-officemember`,
            component: _import('operate/user/officemember/index'),
            meta: {
                ...meta,
                title: '职员管理'
            }
        },
        {
            path: 'userrole/usermanage',
            name: `${pre}user-usermanage`,
            component: _import('operate/user/usermanage/index'),
            meta: {
                ...meta,
                title: '用户管理'
            }
        },
        {
            path: 'userrole/rolemanage',
            name: `${pre}user-rolemanage`,
            component: _import('operate/user/rolemanage/index'),
            meta: {
                ...meta,
                title: '角色管理'
            }
        },

    ]
}