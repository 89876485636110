<template>
  <el-dialog custom-class="jz-modal" :title="title" :visible="modal" :before-close="beforeClose" :close-on-click-modal="closeOnClickModal" v-dialog-drag="vDialogDrag" :append-to-body="appendToBody" :width="width">
    <slot></slot>
    <span slot="footer" class="dialog-footer">
        <slot name="footer"></slot>
    </span>    
  </el-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
export default {
  name: "base-dialog",
  mixins: [mixin],
  data() {
    return {}
  },
  props: {
    title: {
      default: '新增'
    },
    closeOnClickModal: {
      default: false
    },
    vDialogDrag: {
      default: true
    },
    width: {
      default: '80%'
    },
    appendToBody: {
      default: true
    },
    modal: {
      default: false
    }
  },
  methods: {
    beforeClose(done) {
      this.cancel();
      done();
    },
    cancel() {
      this.$emit("closemodal");
    },
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/base-dialog/index.vue"
}
</vue-filename-injector>
