import request from '@/plugin/axios'

/**
 *获取仓库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWarehouseinfoList(data) {
  return request({
    url: '/kitchen/Warehouseinfo/pages',
    method: 'get',
    params: data
  })
}
/**
 *获取仓库列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWarehouseinfoListBypage(data) {
  return request({
    url: '/kitchen/Warehouseinfo/page',
    method: 'get',
    params: data
  })
}
export function GetWarehouseinfoListBypages(data) {
  return request({
    url: '/kitchen/Warehouseinfo/pages',
    method: 'get',
    params: data
  })
}
/**
 *新增仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddWarehouseinfo(data) {
  return request({
    url: '/kitchen/Warehouseinfo/create',
    method: 'post',
    data: data
  })
}

/**
 *新增仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddWarehouseinfoByJson(data) {
  return request({
    url: '/kitchen/Warehouseinfo/createByJson',
    method: 'json',
    data: data
  })
}

/**
 *编辑仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateWarehouseinfo(data) {
  return request({
    url: '/kitchen/Warehouseinfo/update',
    method: 'post',
    data: data
  })
}

/**
 *编辑仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateWarehouseinfoByJson(data) {
  return request({
    url: '/kitchen/Warehouseinfo/updateByJson',
    method: 'json',
    data: data
  })
}

/**
 *删除仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteWarehouseinfo(data) {
  return request({
    url: '/kitchen/Warehouseinfo/delete',
    method: 'post',
    data: data
  })
}

/**
 *批量生成货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GenerateLocations(data) {
  return request({
    url: '/kitchen/entrytraylocalmap/GenerateLocations',
    method: 'post',
    data: data
  })
}

/**
 *根据用户获取权限仓库
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPermissionWarehouseByUserId(data) {
  return request({
    url: '/kitchen/Warehouseinfo/getPermissionWarehouseByUserId',
    method: 'get',
    params: data
  })
}
/**
 *获取对应仓库传感器数据
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetSensorFreezer(data) {
  return request({
    url: '/kitchen/sensorFreezer/page',
    method: 'get',
    params: data
  })
}

/**
 *全部生成货位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GenerateAllLocations(data) {
  return request({
    url: '/kitchen/entrytraylocalmap/GenerateAllLocations',
    method: 'post',
    data: data
  })
}
/**
 *批量设置仓库负责人
 *
 * @export
 * @param {*} data
 * @returns
 */
export function batchSetLearder(data) {
    return request({
        url: '/kitchen/warehouseinfo/batchSetLearder',
        method: 'post',
        data: data
    })
}



/**
 *获取温度预警类型(数据字典)
 * @export
 * @param {*} data
 * @returns
 */
export function GetSensorTypeListByDataDic(data) {
  return request({
    url: '/kitchen/datadic/page',
    method: 'post',
    data: data
  })
}