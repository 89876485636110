import request from '@/plugin/axios'

/**
 *根据地址id查询
 *
 * @export
 * @param {*} data
 * @returns
 */
export function addressQueryById(data) {
  return request({
    url: '/kitchen/Address/queryById',
    method: 'post',
    data: data
  })
}

/**
 *根据地址id删除
 *
 * @export
 * @param {*} data
 * @returns
 */
export function addressDeleteById(data) {
  return request({
    url: '/kitchen/Address/deleteById',
    method: 'post',
    data: data
  })
}

/**
 *查询行政区域
 *
 * @export
 * @param {*} data
 * @returns
 */
export function area(data) {
  return request({
    url: '/kitchen/comArea/page',
    method: 'post',
    data: data,
    overapi: 1
  })
}

/**
 *获取部门列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDepartmentList(data) {
  return request({
    url: '/kitchen/department/page',
    method: 'get',
    params: data
  })
}

/**
 *新增部门
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDepartment(data) {
  return request({
    url: '/kitchen/department/create',
    method: 'post',
    data: data
  })
}

/**
 *更新部门
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateDepartment(data) {
  return request({
    url: '/kitchen/department/update',
    method: 'post',
    data: data
  })
}

/**
 *删除部门
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteDepartment(data) {
  return request({
    url: '/kitchen/department/delete',
    method: 'post',
    data: data
  })
}

/**
 *根据ID获取部门信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDepartmentById(data) {
  return request({
    url: '/kitchen/department/queryById',
    method: 'get',
    params: data
  })
}

/**
 *获取职位列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetPostsList(data) {
  return request({
    url: '/kitchen/posts/page',
    method: 'get',
    params: data
  })
}

/**
 *新增职位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddPosts(data) {
  return request({
    url: '/kitchen/posts/create',
    method: 'post',
    data: data
  })
}

/**
 *更新职位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdatePosts(data) {
  return request({
    url: '/kitchen/posts/update',
    method: 'post',
    data: data
  })
}

/**
 *删除职位
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeletePosts(data) {
  return request({
    url: '/kitchen/posts/delete',
    method: 'post',
    data: data
  })
}

/**
 *根据ID获取职位信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetPostsById(data) {
  return request({
    url: '/kitchen/posts/queryById',
    method: 'get',
    params: data
  })
}

/**
 *获取单位列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetMeasuremethodList(data) {
  return request({
    url: '/kitchen/measureMethod/page',
    method: 'get',
    params: data
  })
}
/**
 *获取职员列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOfficeMember(data) {
  return request({
    url: '/kitchen/officeMember/page',
    method: 'get',
    params: data
  })
}

/**
 *获取职员列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOfficeMemberNoLike(data) {
  return request({
    url: '/kitchen/officeMember/pageNoLike',
    method: 'get',
    params: data
  })
}

/**
 *获取职员未绑定班组列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetOfficeMembers(data) {
  return request({
    url: '/kitchen/officeMember/pages',
    method: 'get',
    params: data
  })
}
/**
 *新增职员
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddOfficeMember(data) {
  return request({
    url: '/kitchen/officeMember/creates',
    method: 'post',
    data: data
  })
}
/**
 *修改职员
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateOfficeMember(data) {
  return request({
    url: '/kitchen/officeMember/updates',
    method: 'post',
    data: data
  })
}
/**
 *删除职员
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteOfficeMember(data) {
  return request({
    url: '/kitchen/officeMember/deletes',
    method: 'post',
    data: data
  })
}
/**
 *获取班组列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWorkGroupMain(data) {
  return request({
    url: '/kitchen/workGroupMain/page',
    method: 'get',
    params: data
  })
}

/**
 *获取班组列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWorkGroupMainNoLike(data) {
  return request({
    url: '/kitchen/workGroupMain/pageNoLike',
    method: 'get',
    params: data
  })
}
/**
 *获取名称列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWorkGroupMainNoLikeName(data) {
  return request({
    url: '/kitchen/workGroupMain/conditionChange',
    method: 'get',
    params: data
  })
}

/**
 *新增班组
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddWorkGroupMain(data) {
  return request({
    url: '/kitchen/workGroupMain/create',
    method: 'post',
    data: data
  })
}
/**
 *新增班组和明细信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddWorkGroupMains(data) {
  return request({
    url: '/kitchen/workGroupMain/creates',
    method: 'json',
    data: data
  })
}
/**
 *修改班组
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateWorkGroupMain(data) {
  return request({
    url: '/kitchen/workGroupMain/update',
    method: 'json',
    data: data
  })
}
/**
 *删除班组
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteWorkGroupMain(data) {
  return request({
    url: '/kitchen/workGroupMain/delete',
    method: 'post',
    data: data
  })
}
/**
 *获取班组明细列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWorkGroupDetail(data) {
  return request({
    url: '/kitchen/workGroupDetail/pages',
    method: 'get',
    params: data
  })
}
/**
 *删除班组明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteWorkGroupDetail(data) {
  return request({
    url: '/kitchen/workGroupDetail/delete',
    method: 'post',
    data: data
  })
}

/**
 *根据ID获取来访申请信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetVisitById(data) {
  return request({
    url: '/kitchen/visit/queryById',
    method: 'get',
    params: data
  })
}

/**
 *获取来访申请
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetVisitList(data) {
  return request({
    url: '/kitchen/visit/getVisits',
    method: 'get',
    params: data
  })
}
/**
 *新增来访申请
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddVisit(data) {
  return request({
    url: '/kitchen/visit/create',
    method: 'post',
    data: data
  })
}

/**
 *更新来访申请
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateVisit(data) {
  return request({
    url: '/kitchen/visit/update',
    method: 'post',
    data: data
  })
}

/**
 *删除来访申请
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteVisit(data) {
  return request({
    url: '/kitchen/visit/batchDelete',
    method: 'post',
    data: data
  })
}

/**
 *新增来访申请明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddVisitors(data) {
  return request({
    url: '/kitchen/visitors/batchCreate',
    method: 'post',
    data: data
  })
}

/**
 *获取来访申请明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetVisitorsList(data) {
  return request({
    url: '/kitchen/visitors/page',
    method: 'get',
    params: data
  })
}

/**
 *更新来访申请明细 （批量修改和新增是同一个接口）
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateVisitors(data) {
  return request({
    url: '/kitchen/visitors/batchCreate',
    method: 'post',
    data: data
  })
}

/**
 *删除来访申请明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteVisitors(data) {
  return request({
    url: '/kitchen/visitors/delete',
    method: 'post',
    data: data
  })
}
/**
 *获取设备列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDeviceList(data) {
  return request({
    url: '/kitchen/device/page',
    method: 'get',
    params: data
  })
}

/**
 *获取设备列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDeviceListPage(data) {
  return request({
    url: '/kitchen/device/getPage',
    method: 'get',
    params: data
  })
}

/**
 *获取设备类型列表--加工工艺选择专用
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDeviceTypeList(data) {
  return request({
    url: '/kitchen/deviceType/pageLast',
    method: 'get',
    params: data
  })
}
/**
 *获取设备类型列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDeviceTypeLists(data) {
  return request({
    url: '/kitchen/deviceType/page',
    method: 'get',
    params: data
  })
}

/**
 * 删除设备类型
 *
 */

export function deleteDeviceType(data) {
  return request({
    url: '/kitchen/deviceType/delete',
    method: 'get',
    params: data
  })
}

/**
 *获取车间列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetWorkshopList(data) {
  return request({
    url: '/kitchen/processWorkshop/page',
    method: 'get',
    params: data
  })
}

/**
 *新增设备
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDevice(data) {
  return request({
    url: '/kitchen/device/create',
    method: 'post',
    data: data
  })
}

/**
 *新增设备类型
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDeviceType(data) {
  return request({
    url: '/kitchen/deviceType/create',
    method: 'post',
    data: data
  })
}

/**
 *更新设备
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateDevice(data) {
  return request({
    url: '/kitchen/device/update',
    method: 'post',
    data: data
  })
}

/**
 *更新设备类型
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateDeviceType(data) {
  return request({
    url: '/kitchen/deviceType/update',
    method: 'post',
    data: data
  })
}

/**
 *查询设备类型
 *
 * @export
 * @param {*}
 * @returns
 */
export function GetDeviceDic(data) {
  return request({
    url: 'kitchen/deviceDetail/pageMainDeviceList',
    method: 'get',
    params: data
  })
}
/**
 *根据字典查询设备类型对应的模板产出品
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDeviceTemplateOutput(data) {
  return request({
    url: '/kitchen/deviceDetail/pageTempleOutput',
    method: 'get',
    params: data
  })
}
/**
 *根据字典查询设备类型对应的模板产出品
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDeviceDetail(data) {
  return request({
    url: '/kitchen/deviceDetail/createDeviceDetail',
    method: 'post',
    data: data
  })
}
/**
 *根据字典查询设备类型对应的模板产出品
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdeteDeviceDetail(data) {
  return request({
    url: '/kitchen/deviceDetail/update',
    method: 'json',
    data: data
  })
}
/**
 *根据设备类型查询设备明细
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getDeviceDetailByDeviceType(data) {
  return request({
    url: '/kitchen/deviceDetail/page',
    method: 'post',
    params: data
  })
}

/**
 *获取消杀列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetDisinfectionList(data) {
  return request({
    url: '/kitchen/disinfection/page',
    method: 'get',
    params: data
  })
}
/**
 *新增消杀
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddDisinfection(data) {
  return request({
    url: '/kitchen/disinfection/create',
    method: 'post',
    data: data
  })
}

/**
 *更新消杀
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateDisinfection(data) {
  return request({
    url: '/kitchen/disinfection/update',
    method: 'post',
    data: data
  })
}

/**
 *删除消杀
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteDisinfection(data) {
  return request({
    url: '/kitchen/disinfection/delete',
    method: 'post',
    data: data
  })
}

/**
 *获取传送系统列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getConveyorList(data) {
  return request({
    url: '/kitchen/conveyor/quaryAlls',
    //url: 'kitchen/conveyor/quaryAlls',
    method: 'post',
    params: data
  })
}

/**
 *创建传送系统
 *
 * @export
 * @param {*} data
 * @returns
 */
export function createConveyor(data) {
  return request({
    url: '/kitchen/conveyor/creates',
    method: 'json',
    data: data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 *编辑传送系统
 *
 * @export
 * @param {*} json
 * @returns
 */
export function updateConveyor(data) {
  return request({
    url: '/kitchen/conveyor/updates',
    method: 'json',
    data: data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 *删除传送系统
 *
 * @export
 * @param {*} data
 * @returns
 */
export function deleteConveyor(data) {
  return request({
    url: '/kitchen/conveyor/deletes',
    method: 'get',
    params: data
  })
}

/**
 *职位选择
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPositionList(data) {
  return request({
    url: '/kitchen/posts/page',
    method: 'get',
    params: data
  })
}
/**
 *实验物料新增
 *
 * @export
 * @param {*} data
 * @returns
 */
export function experimentMaterielCreate(data) {
  return request({
    url: '/kitchen/experimentalMaterials/create',
    method: 'get',
    params: data
  })
}
/**
 *实验物料新增
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getExperimentMateriel(data) {
  return request({
    url: '/kitchen/experimentalMaterials/page',
    method: 'get',
    params: data
  })
}
/**
 *实验物料更新
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateExperimentMateriel(data) {
  return request({
    url: '/kitchen/experimentalMaterials/update',
    method: 'get',
    params: data
  })
}
/**
 *实验物料名称查重
 *
 * @export
 * @param {*} data
 * @returns
 */
export function ExperimentMaterielDelDuplicate(data) {
  return request({
    url: '/kitchen/experimentalMaterials/confirmProductName',
    method: 'get',
    params: data
  })
}
/**
 *实验物料规格查重
 *
 * @export
 * @param {*} data
 * @returns
 */
export function ExperimentMaterielSkuDelDuplicate(data) {
  return request({
    url: '/kitchen/experimentalMaterials/confirmSkuName',
    method: 'get',
    params: data
  })
}
/**
 *实验物料删除
 *
 * @export
 * @param {*} data
 * @returns
 */
export function delExperimentMateriel(data) {
  return request({
    url: '/kitchen/experimentalMaterials/delete',
    method: 'get',
    params: data
  })
}
/**
 *实验物料确认
 *
 * @export
 * @param {*} data
 * @returns
 */
export function confirmExperimentMateriel(data) {
  return request({
    url: '/kitchen/experimentalMaterials/confirm',
    method: 'get',
    params: data
  })
}
/**
 *实验物料领取提交
 *
 * @export
 * @param {*} data
 * @returns
 */
export function experimentalOrderCreate(data) {
  return request({
    url: '/kitchen/experimentalOrder/create',
    method: 'json',
    data: data
  })
}
/**
 *实验物料领取提交
 *
 * @export
 * @param {*} data
 * @returns
 */
export function experimentalOrderUpdate(data) {
  return request({
    url: '/kitchen/experimentalOrder/update',
    method: 'json',
    data: data
  })
}
/**
 *实验物料领取列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function experimentalOrderPage(data) {
  return request({
    url: '/kitchen/experimentalOrder/page',
    method: 'get',
    params: data
  })
}
/**
 *实验物料领用确认
 *
 * @export
 * @param {*} data
 * @returns
 */
export function experimentalOrderConfirm(data) {
  return request({
    url: '/kitchen/experimentalOrder/confirm',
    method: 'json',
    data: data
  })
}
/**
 *实验物料领用删除
 *
 * @export
 * @param {*} data
 * @returns
 */
export function delexperimentalOrder(data) {
  return request({
    url: '/kitchen/experimentalOrder/delete',
    method: 'get',
    params: data
  })
}

/**
 *获取绑定采购员的职员列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPurchaseMemberPage(data) {
  return request({
    url: '/kitchen/officeMember/getPurchaseMemberPage',
    method: 'get',
    params: data
  })
}
/**
 * 查询职员列表
 * @export
 * @param {*} data
 * @returns
 */
export function GetpageOfWareHouse(data) {
  return request({
    url: '/kitchen/officeMember/pageOfWareHouse',
    method: 'post',
    data: data
  })
}
/**
 * 查询消息列表
 * @export
 * @param {*} data
 * @returns
 */
export function GetpageWechatmsgInfoMain(data) {
  return request({
    url: '/kitchen/wechatmsgInfoMain/page',
    method: 'post',
    data: data
  })
}

export function createpageWechatmsgInfoMain(data) {
  return request({
    url: '/kitchen/wechatmsgInfoMain/create',
    method: 'post',
    data: data
  })
}
export function updatepageWechatmsgInfoMain(data) {
  return request({
    url: '/kitchen/wechatmsgInfoMain/update',
    method: 'post',
    data: data
  })
}
export function deletepageWechatmsgInfoMain(data) {
  return request({
    url: '/kitchen/wechatmsgInfoMain/delete',
    method: 'post',
    data: data
  })
}
export function queryByIdpageWechatmsgInfoMain(data) {
  return request({
    url: '/kitchen/wechatmsgInfoMain/queryById',
    method: 'post',
    data: data
  })
}
