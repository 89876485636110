/** 缓存系统不常更改的数据，如数据字典、菜单、按钮等*/
import {
    GetDataDic,
    GetSysList,
    initMenusAllTree
} from '@api/sys.login'
import {
    GetMeasuremethodList
} from '@api/operate.base';
import {
    getPermissionWarehouseByUserId
} from '@api/operate.warehouseInfo'
import {
    getPermissionCusSuppliersByUserId
} from '@api/operate.supplier'
import {
    cloneDeep
} from "lodash";
export default {
    namespaced: true,
    state: {
        //菜单和按钮数组
        ModuleButtons: [],
        //数据字典
        DataDic: [],
        //系统列表
        SysList: [],
        //单位数组
        MeasureList: [],
        areaList: [], // 省市区数据
        areaLevel1: [], // 省
        areaLevel2: [], // 市
        areaLevel3: [], // 区
        areaTree: [], // 省市区树形结构
        permissonWarehouse: {}, //该登录账号所负责的仓库信息
        permissonSupplier: {} //该登录账号所负责的供应商信息
    },
    getters: {
        getAreaList(state) {
            return state.areaList;
        },
    },
    actions: {
        /**省市区数据 */
        areaList: ({
            commit
        }, obj) => {
            return new Promise((resolve, reject) => {
                commit('areaList', obj);
                resolve()
            });
        },
        /**
         * @description 获取数据字典
         * @param {Object} context
         */
        GetDataDic({
            state,
            dispatch,
            commit
        }, {}) {
            return new Promise(async (resolve, reject) => {
                GetDataDic({
                        pageNum: 1,
                        pageSize: 100000,
                        enabled: true
                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存数据字典到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'datadic',
                                value: d.aaData,
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            state.DataDic = d.aaData || [];
                        }
                        return GetSysList({
                            pageNum: 1,
                            pageSize: 100000
                        });

                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存系统列表到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'syslist',
                                value: d.aaData,
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            state.SysList = d.aaData || [];
                        }
                        const userinfo = await dispatch('zycf/db/get', {
                            dbName: 'sys',
                            path: 'loginUser',
                            defaultValue: {},
                            user: true
                        }, {
                            root: true
                        })
                        return initMenusAllTree({
                            userId: userinfo.userId
                        });
                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存菜单列表到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'ModuleButton',
                                value: d.aaData || [],
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            commit('zycf/menu/ModuleButtonSet', d.aaData || [], {
                                root: true
                            });
                        }
                        return GetMeasuremethodList({
                            pageNum: 1,
                            pageSize: 100000,
                            published: true,
                            deleted: false
                        });
                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存单位列表到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'MeasureList',
                                value: d.aaData || [],
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            state.MeasureList = d.aaData || [];
                        }
                        const userinfo = await dispatch('zycf/db/get', {
                            dbName: 'sys',
                            path: 'loginUser',
                            defaultValue: {},
                            user: true
                        }, {
                            root: true
                        })
                        return getPermissionWarehouseByUserId({
                            userId: userinfo.userId
                        });
                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存账号负责的仓库到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'permissonWarehouse',
                                value: d.aaData || {},
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            state.permissonWarehouse = d.aaData || {};

                        }
                        const userinfo = await dispatch('zycf/db/get', {
                            dbName: 'sys',
                            path: 'loginUser',
                            defaultValue: {},
                            user: true
                        }, {
                            root: true
                        })
                        return getPermissionCusSuppliersByUserId({
                            userId: userinfo.userId
                        })
                    })
                    .then(async d => {
                        if (d.state == 0) {
                            //保存账号负责的供应商到浏览器
                            await dispatch('zycf/db/set', {
                                dbName: 'sys',
                                path: 'permissonSupplier',
                                value: d.aaData || {},
                                user: true
                            }, {
                                root: true
                            });
                            //保存到状态管理
                            state.permissonSupplier = d.aaData || {};

                        }
                        // end
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        /**
         *从缓存中加载数据字典
         *
         * @param {*} { state, dispatch }
         * @returns
         */
        LoadDataDic({
            state,
            dispatch
        }) {
            return new Promise(async resolve => {
                // store 赋值
                state.DataDic = await dispatch('zycf/db/get', {
                    dbName: 'sys',
                    path: 'datadic',
                    defaultValue: [],
                    user: true
                }, {
                    root: true
                })
                // store 赋值
                state.SysList = await dispatch('zycf/db/get', {
                    dbName: 'sys',
                    path: 'syslist',
                    defaultValue: [],
                    user: true
                }, {
                    root: true
                })

                // store 赋值
                state.MeasureList = await dispatch('zycf/db/get', {
                    dbName: 'sys',
                    path: 'MeasureList',
                    defaultValue: [],
                    user: true
                }, {
                    root: true
                })
                // store 赋值
                state.permissonWarehouse = await dispatch('zycf/db/get', {
                    dbName: 'sys',
                    path: 'permissonWarehouse',
                    defaultValue: {},
                    user: true
                }, {
                    root: true
                })
                // store 赋值
                state.permissonSupplier = await dispatch('zycf/db/get', {
                    dbName: 'sys',
                    path: 'permissonSupplier',
                    defaultValue: {},
                    user: true
                }, {
                    root: true
                })
                // end
                resolve()
            })
        }
    },
    mutations: {
        /**
         * @description 设置数据字典
         * @param {Object} state state
         * @param {Array} list list setting
         */
        DataDicSet(state, list) {
            // store 赋值
            state.DataDic = list || [];
        },
        /**省市区数据 */
        areaList(state, obj) {
            state.areaList = obj
            var l1 = []
            var l2 = []
            var l3 = []
            var areaTree = []
            state.areaList.forEach(element => {
                if (element.level == 1) {
                    element.children = []
                    l1.push(element)
                    areaTree.push(element)
                }
                if (element.level == 2) {
                    element.children = []
                    l2.push(element)
                }
                if (element.level == 3) {
                    l3.push(element)
                }
            });
            l2.forEach(el => {
                l3.forEach(em => {
                    if (el.areaCode == em.parentAreaCode) {
                        el.children.push(em)
                    }
                })
            })
            areaTree.forEach(el => {
                l2.forEach(em => {
                    if (el.areaCode == em.parentAreaCode) {
                        el.children.push(em)
                    }
                })
            })
            state.areaTree = cloneDeep(areaTree)
            state.areaLevel1 = cloneDeep(l1)
            state.areaLevel2 = cloneDeep(l2)
            state.areaLevel3 = cloneDeep(l3)
        },

    }
}