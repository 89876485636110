import util from '@/libs/util.js'
import quickNavigation from '@/api/quicknavigation.js'

export default {
  methods: {
    handleMenuSelect(index, indexPath, val) {
      if (/^d2-menu-empty-\d+$/.test(index) || index === undefined) {
        this.$message.warning('临时菜单')
      } else if (/^https:\/\/|http:\/\//.test(index)) {
        util.open(index)
      } else {
        let res = quickNavigation.quickNavigationSave({ url: index })
        let ress = quickNavigation.quickNavigationQuick()

        this.$router.push({
          path: index
        })
      }
    }
  }
}
