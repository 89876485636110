/**
 * 库存管理
 */
export default function(pre, _import, meta) {
    return [{
            path: 'instorage',
            name: `${pre}warehouse-instorage`,
            component: _import('operate/warehouse/instorage/index'),
            meta: {
                ...meta,
                title: '入库管理'
            }
        },
        {
            path: 'instorage/add',
            name: `${pre}warehouse-instorage-add`,
            component: _import('operate/warehouse/instorage/components/Add'),
            props: true,
            meta: {
                ...meta,
                title: '新增入库'
            }
        },
        {
            path: 'instorage/edit/:id',
            name: `${pre}warehouse-instorage-edit`,
            component: _import('operate/warehouse/instorage/components/Edit'),
            props: true,
            meta: {
                ...meta,
                title: '编辑入库'
            }
        },
        {
            path: 'inventoryQuery',
            name: `${pre}warehouse-inventoryQuery`,
            component: _import('operate/warehouse/inventoryQuery/index'),
            meta: {
                ...meta,
                title: '库存查询'
            }
        },
        {
            path: 'ventoryAccountsQuery',
            name: `${pre}warehouse-ventoryAccountsQuery`,
            component: _import('operate/warehouse/ventoryAccountsQuery/index'),
            meta: {
                ...meta,
                title: '库存流水查询'
            }
        },
        {
            path: 'Inventoryflow',
            name: `${pre}warehouse-Inventoryflow`,
            component: _import('operate/warehouse/Inventoryflow/index'),
            meta: {
                ...meta,
                title: '库存流向追溯'
            }
        },
        {
            path: 'Inventoryflow/InventoryBack',
            name: `${pre}warehouse-InventoryBack`,
            component: _import('operate/warehouse/Inventoryflow/InventoryBack'),
            meta: {
                ...meta,
                title: '流向追溯详情'
            }
        },
        {
            path: 'statisticalanalysis/inventoryStatistics',
            name: `${pre}warehouse-inventoryStatistics`,
            component: _import('operate/warehouse/inventoryStatistics/index'),
            meta: {
                ...meta,
                title: '库存统计'
            }
        },
        {
            path: 'statisticalanalysis/LibraryStatistics',
            name: `${pre}warehouse-LibraryStatistics`,
            component: _import('operate/warehouse/LibraryStatistics/index'),
            meta: {
                ...meta,
                title: '入库统计'
            }
        },
        {
            path: 'statisticalanalysis/OutboundStatistics',
            name: `${pre}warehouse-OutboundStatistics`,
            component: _import('operate/warehouse/OutboundStatistics/index'),
            meta: {
                ...meta,
                title: '出库统计'
            }
        },
        {
            path: 'statisticalanalysis/GoodsStatistics',
            name: `${pre}warehouse-GoodsStatistics`,
            component: _import('operate/warehouse/GoodsStatistics/index'),
            meta: {
                ...meta,
                title: '货位统计'
            }
        },
        {
            path: 'LossStatistical',
            name: `${pre}warehouse-LossStatistical`,
            component: _import('operate/warehouse/LossStatistical/index'),
            meta: {
                ...meta,
                title: '实时库存'
            }
        },
        {
            path: 'stockcheck',
            name: `${pre}warehouse-stockcheck`,
            component: _import('operate/warehouse/stockcheck/index'),
            meta: {
                ...meta,
                title: '盘点管理'
            }
        },
        {
            path: 'stockcheck/AddAndEdit',
            name: `${pre}warehouse-stockcheckAddAndEdit`,
            component: _import('operate/warehouse/stockcheck/stockcheckModel/stockcheckAddEdit'),
            meta: {
                ...meta,
                title: '新增/编辑盘点'
            }
        },
        {
            path: 'stockcheck/add',
            name: `warehouseStockcheckAdd`,
            component: _import('operate/warehouse/stockcheck/stockcheckModel/stockcheckAdd'),
            meta: {
                ...meta,
                title: '新增盘点'
            }
        },
        {
            path: 'stockcheck/complete',
            name: `${pre}warehouse-stockcheckComplete`,
            component: _import('operate/warehouse/stockcheck/stockcheckModel/stockcheckComplete'),
            meta: {
                ...meta,
                title: '提交盘点'
            }
        },
        {
            path: 'stockcheck/toView',
            name: `${pre}warehouse-stockcheckToView`,
            component: _import('operate/warehouse/stockcheck/stockcheckModel/stockcheckToView'),
            props: true,
            meta: {
                ...meta,
                title: '查看盘点'
            }
        },
        {
            path: 'profitloss',
            name: `${pre}warehouse-profitloss`,
            component: _import('operate/warehouse/profitloss/index'),
            meta: {
                ...meta,
                title: '盈亏单管理'
            }
        },
        {
            path: 'InventoryBack',
            name: `${pre}warehouse-InventoryBack`,
            component: _import('operate/warehouse/Inventoryflow/InventoryBack'),
            meta: {
                ...meta,
                title: '库存追溯'
            }
        },
        {
            path: 'instorage/viewdetail/:id',
            name: `${pre}warehouse-instorage-viewdetail`,
            component: _import('operate/warehouse/instorage/components/ViewDetail'),
            props: true,
            meta: {
                ...meta,
                title: '查看详情'
            }
        },
        {
            path: 'outstorage',
            name: `${pre}warehouse-outstorage`,
            component: _import('operate/warehouse/outstorage/index'),
            meta: {
                ...meta,
                title: '出库管理'
            }
        },
        {
            path: 'outstorage/add',
            name: `${pre}warehouse-outstorage-add`,
            component: _import('operate/warehouse/outstorage/components/Add'),
            props: true,
            meta: {
                ...meta,
                title: '新增出库'
            }
        },
        {
            path: 'outstorage/edit/:id',
            name: `${pre}warehouse-outstorage-edit`,
            component: _import('operate/warehouse/outstorage/components/Edit'),
            props: true,
            meta: {
                ...meta,
                title: '编辑出库'
            }
        },
        {
            path: 'batchaddlocals',
            name: `${pre}warehouse-batchaddlocals`,
            component: _import('operate/warehouse/BatchAddLocal/index'),
            props: true,
            meta: {
                ...meta,
                title: '批量生成货位'
            }
        },
        {
            path: 'outstorage/viewdetail/:id',
            name: `${pre}warehouse-outstorage-viewdetail`,
            component: _import('operate/warehouse/outstorage/components/ViewDetail'),
            props: true,
            meta: {
                ...meta,
                title: '查看详情'
            }
        },
        {
            path: 'localadjustment',
            name: `${pre}warehouse-localadjustment`,
            component: _import('operate/warehouse/localAdjustment/index'),
            props: true,
            meta: {
                ...meta,
                title: '货位调整'
            }
        },
        {
            path: 'localadjustment/add',
            name: `${pre}warehouse-localadjustment-add`,
            component: _import('operate/warehouse/localAdjustment/components/Add'),
            props: true,
            meta: {
                ...meta,
                title: '新增货位调整'
            }
        },
        {
            path: 'localadjustment/edit/:id',
            name: `${pre}warehouse-localadjustment-edit`,
            component: _import('operate/warehouse/localAdjustment/components/Edit'),
            props: true,
            meta: {
                ...meta,
                title: '编辑货位调整'
            }
        },
        {
            path: 'localadjustment/viewdetail/:id',
            name: `${pre}warehouse-localadjustment-viewdetail`,
            component: _import('operate/warehouse/localAdjustment/components/ViewDetail'),
            props: true,
            meta: {
                ...meta,
                title: '完成调整货位'
            }
        },
        {
            path: 'localadjustment/toViewPage/:id',
            name: `${pre}warehouse-localadjustment-toViewPage`,
            component: _import('operate/warehouse/localAdjustment/components/toViewPage'),
            props: true,
            meta: {
                ...meta,
                title: '查看调整单详情'
            }
        },
        {
            path: 'inventoryalert',
            name: `${pre}warehouse-inventoryalert`,
            component: _import('operate/warehouse/InventoryAlert/index'),
            props: true,
            meta: {
                ...meta,
                title: '库存预警'
            }
        },
        {
            path: 'outstoragesurplus',
            name: `${pre}warehouse-outstoragesurplus`,
            component: _import('operate/warehouse/outStorageSurplus/index'),
            props: true,
            meta: {
                ...meta,
                title: '剩余量管理'
            }
        },
        {
            path: 'transferorder',
            name: `${pre}warehouse-transferorder`,
            component: _import('operate/warehouse/TranseferOrder/index'),
            props: true,
            meta: {
                ...meta,
                title: '调拨单'
            }
        },
        {
            path: 'satellitekitchentransferapply',
            name: `${pre}warehouse-satellitekitchentransferapply`,
            component: _import('operate/warehouse/SatelliteKitchenTransferApply/index'),
            props: true,
            meta: {
                ...meta,
                title: '卫星厨房调拨申请'
            }
        },
        {
            path: 'wareCostRange',
            name: `${pre}warehouse-wareCostRange`,
            component: _import('operate/warehouse/wareCostRange/index'),
            props: true,
            meta: {
                ...meta,
                title: '调拨价设置'
            }
        },
    ]
}