<template>
  <el-collapse v-model="activeName" style="padding: 10px">
    <el-collapse-item name="1">
      <template slot="title">
        <span style="font-weight: bold">单据详情</span>
      </template>
      <el-card class="box-card" style="margin-top: 0px">
        <!-- 页面头部 -->
        <el-form :model="form" ref="form" :inline="true" label-width="100px">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="申请单号:">
                <span>{{ form.applyNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="状态:">
                <span>{{
                  _dicValue(form.status, TRANSFER_APPLY_MAIN_STATUS)
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="卫星厨房:" prop="satelliteKitchenName">
                <span>{{ form.satelliteKitchenName }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item label="需求时间:" prop="needDatetime">
                <span>{{ form.needDatetime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="申请原因:" prop="applyReason">
                <span>{{ form.applyReason }}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="备注:" prop="remark">
                <span>{{ form.remark }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <br />
        <table-container>
          <d2-crud
            ref="d2Table"
            :columns="columns"
            :data="tabledata"
            :options="options"
            :loading="loading"
          >
          </d2-crud>
        </table-container>

        <div slot="footer" class="dialog-footer flex-x-end">
          <el-button @click="cancel" class="sys-btn">关闭</el-button>
          <el-button
            v-if="modalType == 'audit'"
            @click="submit(2)"
            class="sys-success-btn"
            >审核通过</el-button
          >
          <el-button
            v-if="modalType == 'audit'"
            @click="submit(3)"
            class="sys-danger-btn"
            >审核退回</el-button
          >
        </div>
      </el-card>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import {
  GetProductSkuList,
  getTransferapplymainById,
  getTransferapplydetails,
  auditApplyOrder,
} from "@api/operate.warebusiness";
import { getSkuBasicUnitQuality } from "@api/operate.purchaseDemandDetail";
import { page } from "@api/operate.satellitekitchen";
import {
  GetWarehouseinfoListBypage,
  GetWarehouseinfoList,
} from "@api/operate.warehouseInfo";
import mixin from "@/plugin/mixins/mixin";
import axios from "@/plugin/axios/index";
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
export default {
  mixins: [mixin],
  data() {
    return {
      form: {
        satelliteKitchenId: "",
        satelliteKitchenName: "",
        status: "0",
        applyReason: "",
        remark: "",
        needDatetime: null,
      },
      activeName:"1",
      options: {
        stripe: true,
        border: true,
        maxHeight: "250",
      },
      loading: false,
      tabledata: [],
      columns: [
        {
          title: "调入仓库",
          key: "wareName",
        },
        {
          title: "商品名称",
          key: "fullName",
        },
        {
          title: "需求量",
          key: "needQuantity",
          width: "140px",
          component: {
            render: (h, row, column, index) => {
              if (row.openWeight == 1) {
                return (
                  <div>
                    <span>
                      {row.needQuantity +
                        "千克" +
                        "/" +
                        row.skuNeedQuantity +
                        row.skuUnit}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div>
                    <span>{row.needQuantity + row.skuUnit}</span>
                  </div>
                );
              }
            },
          },
        },
        {
          title: "实际调拨量",
          key: "skuActualQuantity",
          formatter: (row, column, cellValue, index) => {
            if (row.openWeight == 1) {
              return (
                row.actualQuantity +
                "千克/" +
                row.skuActualQuantity +
                row.skuUnit
              );
            } else {
              return row.actualQuantity + "千克";
            }
          },
        },
        {
          title: "状态",
          key: "status",
          component: {
            name: "EU-elTag",
            props: {
              datatype: "TRANSFER_APPLY_DETAIL_STATUS",
            },
          },
        },
        {
          title: "备注",
          key: "remark",
        },
      ],
      warehouseArr: [],
      TRANSFER_APPLY_MAIN_STATUS: [],
      TRANSFER_APPLY_DETAIL_STATUS: [],
    };
  },
  props: {
    modal: {
      default: false,
    },
    maininfo: {
      default: {},
    },
    modalType: {
      default: "view",
    },
    orderId: {
      default: null,
    },
  },
  watch: {
    tabledata: function (val, oldVal) {
      this.haveChosenRowsSku = val.concat();
    },
  },
  computed: {
    //获取用户信息
    thisUser() {
      return this.$store.state.sjx.user.userinfo;
    },
  },
  mounted() {
    this.TRANSFER_APPLY_MAIN_STATUS = this._searchDic(
      "TRANSFER_APPLY_MAIN_STATUS"
    );
    this.TRANSFER_APPLY_DETAIL_STATUS = this._searchDic(
      "TRANSFER_APPLY_DETAIL_STATUS"
    );
    this.getMainInfo();
    this.getDetails();
  },
  created() {},
  methods: {
    async getMainInfo() {
      const main = await getTransferapplymainById({ id: this.orderId });
      if (main.state == 0) {
        Object.assign(this.form, main.aaData);
      }
    },
    async getDetails() {
      const detals = await getTransferapplydetails({
        pageSize: 10000,
        pageNum: 1,
        mainId: this.orderId,
      });
      if (detals.state == 0) {
        this.tabledata = detals.aaData;
      }
    },

    cancel() {
      this.$emit("closemodal", false);
    },

    beforeClose(done) {
      this.cancel();
      done();
    },
    async submit(status) {
      const result = await auditApplyOrder({
        mainId: this.orderId,
        status: status,
      });
      if (result.state == 0) {
        this.$message({ type: "success", message: "审核成功" });
        this.$emit("closemodal", true);
      }
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/operate/warehouse/SatelliteKitchenTransferApply/components/checkView.vue"
}
</vue-filename-injector>
