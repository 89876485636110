import request from '@/plugin/axios';
import util from '@/libs/util.js';

var rootURL = '/kitchen/ProTmp';

var obj = {};
obj.page = function (data) {
  return util.baseRequest(data, rootURL + '/page');
};
obj.pageT = function (data) {
  return util.baseRequest(data, rootURL + '/pageT');
};
obj.add = function (data) {
  return util.baseJsonRequest(data, rootURL + '/add');
};
obj.detailById = function (data) {
  return util.baseRequest(data, rootURL + '/detailById');
};
obj.update = function (data) {
  return util.baseJsonRequest(data, rootURL + '/update');
};
obj.itemListByMainId = function (data) {
  return util.baseRequest(data, rootURL + '/itemListByMainId');
};

obj.itemListByMain = function (data) {
  return util.baseRequest(data, rootURL + '/itemListByMain');
};
obj.queryAllByBomSkuId = function (data) {
  return util.baseRequest(data, rootURL + '/queryAllByBomSkuId');
};
obj.deleteByIds = function (data) {
  return util.baseRequest(data, rootURL + '/deleteByIds');
};
obj.importExcel = function (data) {
  return util.baseJsonRequest(data, rootURL + '/importExcel');
};
// 删掉套餐里的成品
obj.deleteByTemAndSku = function (data) {
  return util.baseRequest(data, rootURL + '/deleteByTemAndSku');
};
// 套餐里添加菜品
obj.createProTmpmapping = function (data) {
  return util.baseJsonRequest(data, rootURL + '/createProTmpmapping');
};
// 更改套餐里面菜品量
obj.updateProTmpmapping = function (data) {
  return util.baseJsonRequest(data, rootURL + '/updateProTmpmapping');
};
export default obj;
