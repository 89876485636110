export default {
  data() {
    return {
      orderByTypeList: [
        {
          dataKey: true,
          dataValue: '正序'
        },
        {
          dataKey: false,
          dataValue: '倒序'
        }
      ],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
        pageSizeArr: [10, 20, 50, 100]
      },
      d2crudOptions: {
        size: 'mini',
        stripe: true
        // border: true,
        // height: '100%',
      },
      otherTableOptions: {
        size: 'mini',
        stripe: true,
        border: true
      },
      otherOptions: {
        size: 'mini',
        height: 292,
        stripe: true,
        border: true
      }
    }
  },
  methods: {}
}
