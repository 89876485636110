/**
 * 订单管理路由（销售业务）
 */
export default function (pre, _import, meta) {
  return [
    {
      path: 'order/contract',
      name: `${pre}order-contract`,
      component: _import('operate/order/contract/contract.vue'),
      meta: {
        ...meta,
        title: '合同管理'
      }
    },
    {
      path: 'order/orderinfo',
      name: `orderorderinfo`,
      component: _import('operate/order/order/orderinfo.vue'),
      meta: {
        ...meta,
        title: '合同订单'
      }
    },
    {
      path: 'order/cusgroup',
      name: `${pre}order-cusgroup`,
      component: _import('operate/order/cusgroup/index'),
      meta: {
        ...meta,
        title: '客户分组'
      }
    },
    {
      path: 'order/cusquo',
      name: `${pre}order-cusquo`,
      component: _import('operate/order/cusquo/index'),
      meta: {
        ...meta,
        title: '商品客户分组'
      }
    },
    {
      path: 'order/cusprom',
      name: `${pre}order-cusprom`,
      component: _import('operate/order/cusprom/index'),
      meta: {
        ...meta,
        title: '价格策略'
      }
    },
    {
      path: 'order/np',
      name: `${pre}order-np`,
      component: _import('operate/order/np/index.vue'),
      meta: {
        ...meta,
        title: '需求汇总'
      }
    },
    {
      path: 'order/ordprotmp',
      name: `${pre}order-protmp`,
      component: _import('operate/order/protmp/index.vue'),
      meta: {
        ...meta,
        title: '套餐管理'
      }
    },
    {
      path: 'order/dailymenu',
      name: `${pre}order-dailymenu`,
      component: _import('operate/order/dailymenu/index.vue'),
      meta: {
        ...meta,
        title: '每日菜谱'
      }
    },
    {
      path: 'order/cost',
      name: `${pre}order-cost`,
      component: _import('operate/order/cost/index.vue'),
      meta: {
        ...meta,
        title: '销售毛利分析'
      }
    },
    {
      path: 'order/singleorder',
      name: 'ordersingle',
      component: _import('operate/order/singleorder/index.vue'),
      meta: {
        ...meta,
        title: '散销订单'
      }
    },
    {
      path: 'order/singleorderReport',
      name: `${pre}order-singleReport`,
      component: _import('operate/order/singleorderReport/index.vue'),
      meta: {
        ...meta,
        title: '散销报表'
      }
    },
    {
      path: 'order/kitchenPurorder',
      name: `${pre}order-kitchenPurorder`,
      component: _import('operate/order/kitchenPurorder/index.vue'),
      meta: {
        ...meta,
        title: '星厨采购单'
      }
    },
    {
      path: 'order/price',
      name: `${pre}order-price`,
      component: _import('operate/order/price/index.vue'),
      meta: {
        ...meta,
        title: '订单商品统一调价'
      }
    },
    {
      path: 'order/outside',
      name: `supplychainorderoutside`,
      component: _import('operate/order/outside/index.vue'),
      meta: {
        ...meta,
        title: '卫星厨房订单'
      }
    },
    {
      path: 'order/return',
      name: `${pre}order-return`,
      component: _import('operate/order/return/index.vue'),
      meta: {
        ...meta,
        title: '销售退货'
      }
    },
    {
      path: 'order/saleMember',
      name: `${pre}order-officemember`,
      component: _import('operate/order/officemember/index.vue'),
      meta: {
        ...meta,
        title: '业务员统计'
      }
    }
  ]
}
