<template>
  <base-form-dialog :modal="modal" @closemodal="cancel" :form="form" ref="formDialog" title="待办事项">
    <el-form-item label="关键字" prop="keyword" class="inline approval-form-item">
        <el-input v-model="form.keyword" placeholder="单据编号/模块名" clearable></el-input>
    </el-form-item>
    <el-form-item  class="inline approval-form-item">
        <el-button type="primary" @click="approvalSearch" class="sys-primary-btn">查询</el-button>
    </el-form-item>
    <el-form-item  class="inline approval-form-item">
        <el-button @click="handleFormReset" class="sys-btn">重置</el-button>
    </el-form-item>
    <d2-crud
      slot="crud"
      ref="itemCrud"
      flex-box="1"
      :columns="columns"
      :data="tabledata"
      :rowHandle="rowHandle"
      @row-approval="rowApproval"
      :loading="loading"
      index-row
    >
    </d2-crud>
    <div class="pagination-container" slot="footer">
      <el-pagination
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :page-sizes="pagination.pageSizeArr"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>


    <approvalDetail v-if="approvalDetailShow" :modal="approvalDetailShow" @closemodal="closemodal" :approvalId="approvalId"></approvalDetail>


  </base-form-dialog>
</template>
<script>
import approvalDetail from "./approvalDetail.vue";
import mixin from "@/plugin/mixins/mixin";
import util from '@/libs/util.js'
import {cloneDeep}  from 'lodash'
var api = {
    approvalSearch(data) {
        return util.baseRequest(data, '/kitchen/workflowRecordMain/page')
    },
}

export default {
  mixins: [mixin],
  components:{approvalDetail},
  data() {
    return {
      loading: false,
      approvalId: null,
      approvalDetailShow: false,
      tabledata: [],
      keywords: '',
      dataList: [],
      form: {
          keyword: '',
      },
      columns: [
          {
              title: '单据号',
              key: 'recordNum',
          }, {
              title: '单据类型',
              key: 'modeName',
          }, {
              title: '审核状态',
              key: 'result',
              component: {
                name: "EU-elTag",
                props: {
                  datatype: "WORKFLOW_RESULT"
                }
              }
          }, {
              title: '提交人',
              key: 'createdPerson'
          }, {
              title: '提交时间',
              key: 'createdDate'
          },        
      ],
      rowHandle: {
        width: '300px',
        custom: [
            {
                text: "查看",
                type: "default",
                emit: "row-approval",
                class: "sys-default-btn"
            },
            {
                text: "审核",
                type: "primary",
                emit: "row-approval",
                class: "sys-primary-btn",
                show (index, row) {
                  if (row.review != null && row.review) {
                      return true
                  }
                  return false
                },
            },
            {
                text: "撤销",
                type: "warning",
                emit: "row-approval",
                class: "sys-warning-btn",
                show (index, row) {
                  if (row.revoke != null && row.revoke) {
                      return true
                  }
                  return false
                },
            }
        ]
      }
    };
  },
  props: {
    modal: {
      default: false
    },
    recordNumber: {
      default: ''
    },
    recordIds: {
      default: function() {
        return []
      }
    }
  },
  async mounted() {  
    this.form.keyword = this.recordNumber
    this.approvalSearch()
  },
  created() {
    
  },
  computed: {},
  methods: {
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.approvalSearch();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.approvalSearch();
    },
    async approvalSearch() {
        const params = { ...this.form };
        Object.assign(params, {
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize
        });
        params.keywordFields = ['recordNum', 'modeName']
        params.recordIds = this.recordIds
        this.loading = true
        const result = await api.approvalSearch(params);
        this.loading = false
        if(Array.isArray(result.aaData)) {
            this.tabledata = cloneDeep(result.aaData);                
            this.pagination.total = result.dataCount;                    
        }else {
            this.tabledata = []
            this.pagination.total = 0;
            this.$error(result.msg)
        }
    },
    handleFormReset() {
        this.form.keyword='',
        //this.$refs.form.resetFields();
                 this.pagination.currentPage  =1,
        this.approvalSearch();
    },
    cancel(flag) {
      this.$emit("closemodal", flag);
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      this.cancel(false);
      done();
    },
    rowApproval({row}) {
      this.approvalId = row.id
      this.approvalDetailShow = true
    },
    async closemodal(flag) {
        this.approvalDetailShow = false
        if(flag) {
          this.approvalSearch()
        }
    }
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-message/approvalList.vue"
}
</vue-filename-injector>
