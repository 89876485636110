<template>
  <el-dialog custom-class="jz-modal" :title="title" :visible="modal" :before-close="beforeClose" :close-on-click-modal="closeOnClickModal" v-dialog-drag="vDialogDrag" :append-to-body="appendToBody" :width="width">
    <base-dialog-form :model="form" ref="innerForm" :rules="rules" :label-width="labelWidth">
        <slot></slot>
    </base-dialog-form>
    <slot name="content"></slot>
    <div flex="dir:top">
      <div flex-box="1" flex="dir:top">
        <div class="base-border" flex-box="1" flex="dir:top">
          <slot name="crud"></slot>
        </div>
      </div>
    </div>    
    <span slot="footer" class="dialog-footer">
        <slot name="footer"></slot>
    </span>    
  </el-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
export default {
  name: "base-form-dialog",
  mixins: [mixin],
  data() {
    return {}
  },
  props: {
    title: {
      default: '新增'
    },
    closeOnClickModal: {
      default: false
    },
    vDialogDrag: {
      default: true
    },
    width: {
      default: '80%'
    },
    appendToBody: {
      default: true
    },
    modal: {
      default: false
    },
    form: {
        default: function() {
            return {}
        }
    },
    rules: {
        default: function() {
            return {}
        }
    },
    labelWidth: {
      default: '100px'
    }
  },
  methods: {
    beforeClose(done) {
      this.cancel();
      done();
    },
    cancel() {
      this.$emit("closemodal");
    },
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/base-dialog/base-form-dialog.vue"
}
</vue-filename-injector>
