/**
 * 生产管理
 */
export default function (pre, _import, meta) {
  return [
    {
      path: 'mealplan',
      name: `${pre}production-mealplan`,
      component: _import('operate/production/mealplan/index'),
      props: true,
      meta: {
        ...meta,
        title: '备餐计划'
      }
    },
    {
      path: 'processbase/template',
      name: `${pre}production-template`,
      component: _import('operate/production/template/index'),
      props: true,
      meta: {
        ...meta,
        title: '加工模板管理'
      }
    },
    {
      path: 'processbase/craft',
      name: `${pre}production-craft`,
      component: _import('operate/production/craft/index'),
      props: true,
      meta: {
        ...meta,
        title: '加工工艺管理'
      }
    },
    {
      path: 'processCore/task',
      name: `${pre}production-task`,
      component: _import('operate/production/task/index'),
      props: true,
      meta: {
        ...meta,
        title: '生产任务管理'
      }
    },
    {
      path: 'processCore/task/taskDetail',
      name: `${pre}production-taskDetail`,
      component: _import('operate/production/task/components/addOrEditOrCheck'),
      props: true,
      meta: {
        ...meta,
        title: '新增任务'
      }
    },
    {
      path: 'processCore/task/view',
      name: `${pre}production-view`,
      component: _import('operate/production/task/components/view'),
      props: true,
      meta: {
        ...meta,
        title: '查看任务'
      }
    },
    {
      path: 'picklistcenter/picklists',
      name: `productionpicklists`,
      component: _import('operate/production/picklists/index'),
      props: true,
      meta: {
        ...meta,
        title: '领料单管理'
      }
    },
    {
      path: 'returnMaterial',
      name: `${pre}production-returnMaterial`,
      component: _import('operate/production/returnMaterial/index'),
      props: true,
      meta: {
        ...meta,
        title: '退料单管理'
      }
    },
    {
      path: 'processCore/scheduling',
      name: `${pre}production-scheduling`,
      component: _import('operate/production/scheduling/index'),
      props: true,
      meta: {
        ...meta,
        title: '生产计划管理'
      }
    },
    {
      path: 'sortedpack',
      name: `${pre}production-sortedpack`,
      component: _import('operate/production/sortedpack/index'),
      props: true,
      meta: {
        ...meta,
        title: '订单分装详情'
      }
    },
    {
      path: 'kitchenwaste/kitchenwastecategory',
      name: `${pre}kw-kitchenwastecategory`,
      component: _import('operate/kw/kitchenwastecategory/index'),
      meta: {
        ...meta,
        title: '分类管理'
      }
    },
    {
      path: 'kitchenwaste/inputsmanage',
      name: `${pre}kw-inputsmanage`,
      component: _import('operate/kw/inputsmanage/index'),
      meta: {
        ...meta,
        title: '投入品管理'
      }
    },
    {
      path: 'kitchenwaste/ledger',
      name: `${pre}kw-ledger`,
      component: _import('operate/kw/ledger/index'),
      meta: {
        ...meta,
        title: '台账管理'
      }
    },
    {
      path: 'split',
      name: `${pre}production-split`,
      component: _import('operate/production/split/index'),
      meta: {
        ...meta,
        title: '商品拆分管理'
      }
    },
    {
      path: 'hoheating',
      name: `${pre}production-hoheating`,
      component: _import('operate/production/hoheating/index'),
      meta: {
        ...meta,
        title: '复热管理'
      }
    },
    {
      path: 'kitchenoutput',
      name: `${pre}production-kitchenoutput`,
      component: _import('operate/production/kitchenoutput/index'),
      props: true,
      meta: {
        ...meta,
        title: '厨房产出登记'
      }
    },
    {
      path: 'kitcheninject',
      name: `${pre}production-kitcheninject`,
      component: _import('operate/production/kitcheninject/index'),
      props: true,
      meta: {
        ...meta,
        title: '厨房投入登记'
      }
    },
    {
      path: 'productsCost',
      name: `${pre}productsCost-productsCost`,
      component: _import('operate/production/productsCost/index'),
      meta: {
        ...meta,
        title: '完工产品成本'
      }
    },
    {
      path: 'materialLoss',
      name: `${pre}materialLoss-materialLoss`,
      component: _import('operate/production/materialLoss/index'),
      meta: {
        ...meta,
        title: '材料损耗汇总'
      }
    },
    {
      path: 'processtaskloss',
      name: `${pre}processtaskloss`,
      component: _import('operate/production/processtaskloss/index'),
      meta: {
        ...meta,
        title: '加工损耗汇总'
      }
    },
    {
      path: 'crushed',
      name: `${pre}production-crushed`,
      component: _import('operate/production/crushed/index'),
      meta: {
        ...meta,
        title: '生产任务验收'
      }
    },
    {
      path: 'processCore/ingredientstask',
      name: `${pre}production-ingredientstask`,
      component: _import('operate/production/ingredientstask/index'),
      props: true,
      meta: {
        ...meta,
        title: '配料任务管理'
      }
    },
    {
      path: 'processCore/ingredientstask/ingredientstaskDetail',
      name: `${pre}production-ingredientstaskDetail`,
      component: _import('operate/production/ingredientstask/components/addOrEditOrCheck'),
      props: true,
      meta: {
        ...meta,
        title: '新增/编辑/查看配料任务'
      }
    },
    {
      path: 'processCore/ingredientsscheduling',
      name: `${pre}production-ingredientsscheduling`,
      component: _import('operate/production/ingredientsscheduling/index'),
      props: true,
      meta: {
        ...meta,
        title: '配料生产计划'
      }
    },
    {
      path: 'picklistcenter/pickliststatistics',
      name: `${pre}production-pickliststatistics`,
      component: _import('operate/production/pickliststatistics/index'),
      props: true,
      meta: {
        ...meta,
        title: '领料单汇总'
      }
    }
  ]
}
