/**
 * 配送系统
 */
export default function (pre, _import, meta) {
  return [
    {
      path: 'distribution/distributionVehicle',
      name: `${pre}distribution-distributionVehicle`,
      component: _import('operate/distribution/vehicle/index'),
      meta: {
        ...meta,
        title: '车辆管理'
      }
    },
    {
      path: 'distribution/distributionLine',
      name: `${pre}distribution-distributionLine`,
      component: _import('operate/distribution/line/index'),
      meta: {
        ...meta,
        title: '线路管理'
      }
    },
    {
      path: 'distribution/recyclingtableware',
      name: `${pre}distribution-recyclingtableware`,
      component: _import('operate/distribution/recyclingtableware/index'),
      meta: {
        ...meta,
        title: '餐具回收管理'
      }
    },
    {
      path: 'distribution/distributionNode',
      name: `${pre}distribution-distributionNode`,
      component: _import('operate/distribution/node/index'),
      meta: {
        ...meta,
        title: '节点管理'
      }
    },
    {
      path: 'distribution/distributionOrder',
      name: `distributiondistributionOrder`,
      component: _import('operate/distribution/order/index'),
      meta: {
        ...meta,
        title: '配送单管理'
      }
    }
  ]
}
