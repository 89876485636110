/**
 * 常量
 */
var constant = {
	PAY_TYPE: 'PAY_TYPE',//字典支付类型key
	PAY_STATUS: 'PAY_STATUS',//字典支付状态key
	
	CO_STATUS: 'CO_STATUS',//字典合同状态key
	OD_STATUS: 'OD_STATUS',//字典订单状态key
	OD_PAY_STATUS: 'OD_PAY_STATUS',//字典订单支付状态key
	CUS_GROUP_TYPE: 'CUS_GROUP_TYPE',//字典客户分组类型key
	CUS_GROUP_STATUS: 'CUS_GROUP_STATUS',//字典客户分组状态key
	CUS_QUO_STATUS: 'CUS_QUO_STATUS',//字典客户商品分组状态key
	CUS_PROM_STATUS: 'CUS_PROM_STATUS',//字典价格策略状态key
	PROMOTION_TYPE: 'PROMOTION_TYPE',//字典折扣方式状态key
	DAILY_TYPE: 'DAILY_TYPE',
	ORD_ITEM_SOURCE: 'ORD_ITEM_SOURCE',
	ORD_SOURCE: 'ORD_SOURCE',
	PACK_TYPE: 'ORD_PACK_TYPE',// 字典包装类型key
	PACKAGE_TYPE: 'PACKAGE_TYPE',// 盛装器皿类型key
	PACK_COLOR: 'PACK_COLOR',// 字典包装颜色key
	COMMON_STATE: 'COMMON_STATE',// 字典启用状态key
	CONVEYOR_STATUS: 'CONVEYOR_STATUS',//字典传送系统状态,
	ORD_ITEM_SOURCE: 'ORD_ITEM_SOURCE',

	MSG_SAVE: '保存成功',//保存成功提示
	MSG_DEL: '删除成功',//删除成功提示
	MSG_OPS: '操作成功',//操作成功提示
  TMP_TYPE: 'CLASS_PACKAGE',//套餐类型
}
/**
 * 判断返回成功
 * @param {[type]} res [description]
 */
constant.IS_SUCCESS = function(res) {
	return res && res.state == 0 && res.success
}
export default constant