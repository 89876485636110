import { isFalsy } from '@/libs/util'

export const MONEY_MAX = 10 ** 8 // 金额上限
export const REMARK_MAX = 100 // 备注、摘要字符上限
export const NAME_MAX = 30 // 名字字符上限
export const NUMBER_MAX = 99999999
export const DECIMAL_MAX = 2 // 小数位数
export const SN_MAX = 20 // 序列号长度
// 邮箱
export const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// 网址
export const urlReg = /^[a-zA-z]+:\/\/[^\s]*$/
// 图片网址
export const imgUrlReg = /^[a-zA-z]+:\/\/[^\s]*\.(gif|jpeg|png|jpg|bmp)$/
// 手机号
export const phoneReg = /^1[0-9]{10}$/
// 固话
export const telPhoneReg = /^((\d{3}-)?\d{8}|(\d{4}-)?\d{7,8})$/
// 邮编
export const zipCodeReg = /^[1-9]\d{5}$/
// 税号
export const taxReg = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
// 传真
export const faxReg = /^((\d{3}-)?\d{8}|(\d{4}-)?\d{7})$/
// 银行账号
export const accountReg = /^[1-9][0-9]{11,18}$/
// 密码
export const passwordReg = /^[a-zA-Z0-9_-]{6,15}$/
// 检索码/编码
export const validaCodeReg = /[^a-zA-Z0-9]/
// 商品/sku编码/拼音码
export const productCodeReg = /^[a-zA-Z0-9]+$/
// 一般输入字符过滤
export const validaStrReg = /[^\u4e00-\u9fa5a-zA-Z0-9_-]/
// 文本输入过滤（算了，不过滤了。。。）
// export const validaText =/[^\u4e00-\u9fa5a-zA-Z0-9，。！？_-]/
// 商品分类名称：中文、英文、数字但不包括下划线等符号
export const validaCateReg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
// 商品其他名称：中文、英文、数字 下划线 中横线
export const validaNameReg = /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/
// 0-2位小数
export const floatTwo = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/

// 空格验证
export const validaTrim = (rule, value, cb) => {
  if (String(value).length !== 0 && String(value).trim().length < 1) {
    return cb(new Error('不能填写空格'))
  } else if (String(value).length !== String(value).trim().length) {
    return cb(new Error('首尾不能有空格'))
  } else {
    cb()
  }
}

// 邮箱
export const validaEmail = (rule, value, cb) => {
  if (value) {
    if (!emailReg.test(value)) {
      return cb(new Error('请输入正确的邮箱'))
    } else if (value.length > 50) {
      return cb(new Error('邮箱不要超过50字符'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 网址(非必填验证)
export const validaUrl = (rule, value, cb) => {
  if (value) {
    if (!urlReg.test(value)) {
      return cb(new Error('请输入正确的网址'))
    } else if (value.length > 50) {
      return cb(new Error('网址不要超过50字符'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 网址(必填验证)
export const validaUrlRequire = (rule, value, cb) => {
  if (!value) {
    return cb(new Error('网址不能为空'))
  } else {
    if (!urlReg.test(value)) {
      return cb(new Error('请输入正确的网址'))
    } else if (value.length > 50) {
      return cb(new Error('网址不要超过50字符'))
    } else {
      cb()
    }
  }
}
// 手机号(非必填验证)
export const validaPhone = (rule, value, cb) => {
  if (value) {
    if (!phoneReg.test(value)) {
      cb(new Error('请输入正确的手机号'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 手机号(必填验证)
export const validatPhoneRequire = (rule, value, cb) => {
  if (!value) {
    cb(new Error('手机号不能为空'))
  } else {
    if (!phoneReg.test(value)) {
      cb(new Error('请输入正确的手机号'))
    } else {
      cb()
    }
  }
}

// 固话
export const validaTelPhone = (rule, value, cb) => {
  if (value) {
    if (!telPhoneReg.test(value)) {
      return cb(new Error('请输入正确的固话'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 手机号/固话
export const validaPhoneOrTelphone = (rule, value, cb) => {
  if (value) {
    if (!phoneReg.test(value) && !telPhoneReg.test(value)) {
      return cb(new Error('请输入正确的手机号或者固话'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 邮编
export const validaZipCode = (rule, value, cb) => {
  if (value) {
    if (!zipCodeReg.test(value)) {
      return cb(new Error('请输入正确的邮编'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 税号
export const validaTax = (rule, value, cb) => {
  if (value) {
    if (!taxReg.test(value)) {
      return cb(new Error('正确的税号由大写字母开头，再由14或17或19位数字构成'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 传真
export const validaFax = (rule, value, cb) => {
  if (value) {
    if (!faxReg.test(value)) {
      return cb(new Error('请输入正确的传真号'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 银行账号(非必填验证)
export const validaAccount = (rule, value, cb) => {
  if (value) {
    if (!accountReg.test(value)) {
      return cb(new Error('正确的账号由 12 - 19 位数字构成'))
    } else {
      cb()
    }
  } else {
    cb()
  }
}
// 银行账号(必填验证)
export const validaAccountRequire = (rule, value, cb) => {
  if (!value) {
    return cb(new Error('账号不能为空'))
  } else {
    if (!accountReg.test(value)) {
      return cb(new Error('正确的账号由 12 - 19 位数字构成'))
    } else {
      cb()
    }
  }
}
// 密码
export const validaPassword = (rule, value, cb) => {
  if (!value) {
    return cb(new Error('密码不能为空'))
  } else {
    if (!passwordReg.test(value)) {
      return cb(new Error('请输入正确密码，6-15位数字，大小写字母及中划线，下划线构成'))
    } else {
      cb()
    }
  }
}

// 金额
const amountValidator = (rule, val, cb) => {
  if (val === undefined || val === null || val === '') {
    return cb(new Error('金额不能为空'))
  } else if (Number.isNaN(+val)) {
    return cb(new Error('金额必须为数字'))
  } else if (+val <= 0) {
    return cb(new Error('金额必须大于0'))
  } else if (+val >= MONEY_MAX) {
    return cb(new Error('超过最大金额限制'))
  } else {
    const arr = val.toString().split('.')
    const dec = arr[1]
    if (dec && dec.length > DECIMAL_MAX) {
      return cb(new Error(`小数位数不能超过${DECIMAL_MAX}位`))
    }
    return cb()
  }
}

// 金额非必要
const amountValidatorNeedless = (rule, val, cb) => {
  if (val === undefined || val === null || val === '') {
    return cb()
  } else if (Number.isNaN(+val)) {
    return cb(new Error('金额必须为数字'))
  } else if (+val < 0) {
    return cb(new Error('金额不能为负数'))
  } else if (+val >= MONEY_MAX) {
    return cb(new Error('超过最大金额限制'))
  } else {
    const arr = val.toString().split('.')
    const dec = arr[1]
    if (dec && dec.length > DECIMAL_MAX) {
      return cb(new Error(`小数位数不能超过${DECIMAL_MAX}位`))
    }
    return cb()
  }
}


const phoneValidator = (rule, val, cb) => {
  const reg = /^1\d{10}$/
  if (!isFalsy(val) && !reg.test(val)) {
    return cb(new Error('请输入正确的手机号码'))
  }
  return cb()
}

const withRequired = function (key, text = '', trigger = 'change') {
  let rules = []
  if (key) {
    rules.push(...(this[key] || []))
  }
  const operate = key === 'select' ? '选择' : '输入'
  trigger = key === 'select' ? 'change' : trigger
  rules.unshift({
    required: true,
    message: `请${operate}${text}`,
    trigger
  })
  return rules
}

const numberValidator = (rule, val, cb) => {
  if (isFalsy(val)) {
    return cb()
  } else if (+val !== Math.floor(+val)) {
    return cb(new Error('输入数字必须为整数'))
  } else if (+val > NUMBER_MAX) {
    return cb(new Error(`输入数字大小超过最大值【${NUMBER_MAX}】`))
  } else if (+val <= 0) {
    return cb(new Error('输入数字必须大于0'))
  }
  return cb()
}

const numberIncludesZeroValidator = (rule, val, cb) => {
  if (isFalsy(val)) {
    return cb()
  } else if (+val !== Math.floor(+val)) {
    return cb(new Error('输入数字必须为整数'))
  } else if (+val > NUMBER_MAX) {
    return cb(new Error(`输入数字大小超过最大值【${NUMBER_MAX}】`))
  } else if (+val < 0) {
    return cb(new Error('输入数字必须大于等于0'))
  }
  return cb()
}

// 二位小数
const floatNumberValidator = (rule, val, cb) => {
  if (isFalsy(val)) {
    return cb()
  } else if (!floatTwo.test(val)) {
    return cb(new Error('输入正数,最多2位小数'))
  } else if (+val > NUMBER_MAX) {
    return cb(new Error(`输入数字大小超过最大值【${NUMBER_MAX}】`))
  } else if (+val <= 0) {
    return cb(new Error('输入数字必须大于0'))
  }
  return cb()
}
// const logisticsOrderValidator = (rule, val, cb) => {}

export default {
  amountNeedless: [{ validator: amountValidatorNeedless, trigger: 'blur' }],
  amount: [{ required: true, validator: amountValidator, trigger: 'blur' }],
  time: [{ required: true, message: '请选择日期', trigger: 'blur' }],
  remark: [{ max: REMARK_MAX, message: `字数不能超过${REMARK_MAX}个`, trigger: 'change' }],
  sn: [{ max: SN_MAX, message: `字数不能超过${SN_MAX}个`, trigger: 'change' }],
  name: [{ max: NAME_MAX, message: `字数不能超过${NAME_MAX}个`, trigger: 'change' }],
  number: [{ validator: numberValidator, trigger: 'blur' }],
  floatNumber: [{ validator: floatNumberValidator, trigger: 'blur' }],
  phone: [{ validator: phoneValidator, trigger: 'blur' }],
  email: [{ validator: validaEmail, trigger: 'blur' }],
  numberIncludesZero: [{ validator: numberIncludesZeroValidator, trigger: 'blur' }],
  withRequired,
  validaPhoneOrTelphone: [{ validator: validaPhoneOrTelphone, trigger: 'blur' }]
}
