<template>
    <el-dropdown trigger="click">
        <el-button :disabled="disabled" class="sys-btn" type="primary"  style="margin-bottom: 5px;margin-right: 10px;margin-left: 10px">
        导出excel
        <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="exportExcelClick('all')">导出全部</el-dropdown-item>
        <el-dropdown-item @click.native="exportExcelClick('table')">导出当前</el-dropdown-item>
        <el-dropdown-item @click.native="exportExcelClick('selected')">导出选中</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
  name: "export-excel-dropdown",
  props: {
    disabled: {
        default: false
    }
  },
  methods: {
    exportExcelClick(type) {
      this.$emit('exportExcelClick', type)
    },
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/export-excel-dropdown/index.vue"
}
</vue-filename-injector>
