/**
 * 分拣系统
 */
export default function (pre, _import, meta) {
    return [{
            path: 'sorting/sorter',
            name: `${pre}sorting-sorter`,
            component: _import('operate/sorting/sorter/index'),
            meta: {
                ...meta,
                title: '分拣员管理'
            }
        },
        {
            path: 'sorting/outofstock',
            name: `${pre}sorting-outofstock`,
            component: _import('operate/sorting/outofstock/index'),
            meta: {
                ...meta,
                title: '补货管理'
            }
        },
        {
            path: 'sorting/sortingtask',
            name: `${pre}sorting-sortingtask`,
            component: _import('operate/sorting/sortingtask/index'),
            meta: {
                ...meta,
                title: '分拣任务管理'
            }
        },
        {
            path: 'sorting/sorttaskstatistics',
            name: `${pre}sorting-sorttaskstatistics`,
            component: _import('operate/sorting/sorttaskstatistics/index'),
            meta: {
                ...meta,
                title: '分拣任务统计'
            }
        },
        {
            path: 'sorting/sorttaskmonitor',
            name: `${pre}sorting-sorttaskmonitor`,
            component: _import('operate/sorting/sorttaskmonitor/index'),
            meta: {
                ...meta,
                title: '分拣任务进度'
            }
        },

    ]
}