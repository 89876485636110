import {
  operate,
  supplychain,
  production,
  warehouse,
  develop,
  system
} from './modules/operate'


import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  
  {
    path: '/',
    redirect: {
      name: 'navi'
    },
    component: layoutHeaderAside,
    children: [
      
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          auth: true
        },
        component: _import('operate/home/index'),
        children: [
          {
            path: 'index',
            name: 'index',
            meta: {
              auth: true,title: '首页'
            },
            component: _import('operate/home/index')
          },  
                 
        ]
      },
      {
        path: '/index/selltongji',
        name: 'orderTongji',
        component: _import('operate/order/tongji/index.vue'),
        meta: {
          title: '销售统计'
        }
      }, 
      {
        path: '/index/processtongji',
        name: 'processtongji',
        component: _import('operate/production/tongji/index.vue'),
        meta: {
          title: '产能统计'
        }
      },
      {
        path: '/index/trace',
        name: 'trace',
        component: _import('operate/trace/query.vue'),
        meta: {
          title: '质量追溯'
        }
      },
      // 系统 前端日志
      {
        path: 'log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: _import('system/log')
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: _import('system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('system/function/redirect')
      },

    ]
  },
  operate,
  supplychain,
  production,
  warehouse,
  system,
  develop,

]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  },
  {
    path: '/navi',
    name: 'navi',
    component: _import('navi')
  }
]

/**
 * 错误页面
 */
const errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]