<template>
    <base-dialog :modal="modal" @closemodal="cancel" ref="baseDialog" title="导出" width="30%">
        <el-radio v-model="radio" :label="1">导出选中</el-radio>
        <el-radio v-model="radio" :label="2">导出当前</el-radio>
        <el-radio v-model="radio" :label="3">导出所有</el-radio>
        <div slot="footer" class="dialog-footer flex-x-end">
            <el-button @click="cancel(false)">取消</el-button>
            <el-button type="primary" @click="handleDownload">提交</el-button>
        </div>
    </base-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
import util from "@/libs/util.js"
export default {
  name: "export-excel",
  mixins: [mixin],
  data() {
    return {
        radio: 1
    }
  },
  props: {
    modal: {
      default: false
    },
    currentData: {
      default: function() {
        return []
      }
    },
    selection: {
      default: function() {
        return []
      }
    },
    allData: {
      default: function() {
        return []
      }
    },
    filterVal: {
      default: function() {
        return []
      }
    },
    filename: '',
    header: {
      default: function() {
        return []
      }
    },
    merges: ''
  },
  methods: {
    beforeClose(done) {
      this.cancel();
      done();
    },
    cancel() {
      this.$emit("cancel");
    },
    handleDownload() {
      var dataList = []
      if(this.radio == 1 && !util.listNotNull(this.selection)) {
        this.$warning("暂无选中")
        this.cancel()
        return
      }
      if(this.radio == 1) {
        dataList = this.selection
      }
      if(this.radio == 2) {
        dataList = this.currentData
      }
      if(this.radio == 3) {
        dataList = this.allData
      }
      if(!util.listNotNull(dataList)) {
        this.$warning("暂无数据")
        this.cancel()
        return
      }   
      import('@/plugin/export/Export2Excel').then(excel => {
        const data = this.formatJson(this.filterVal, dataList)
        excel.export_json_to_excel({
          header: this.header,
          data,
          filename: this.filename,
          autoWidth: true,
          bookType: 'xlsx',
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },
  }
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/export-excel/index.vue"
}
</vue-filename-injector>
