/**
 * 溯源系统
 */
export default function (pre, _import, meta) {
    return [
        {
            path: 'suyuan/base',
            name: `${pre}suyuan-base`,
            component: _import('operate/suyuan/base/index'),
            meta: {
                ...meta,
                title: '基地'
            }
        },{
            path: 'suyuan/base/create',
            name: `${pre}suyuan-base-create`,
            component: _import('operate/suyuan/base/create'),
            meta: {
                ...meta,
                title: '创建基地'
            }
        },{
            path: 'suyuan/base/update/:id',
            name: `${pre}suyuan-base-update`,
            component: _import('operate/suyuan/base/update'),
            meta: {
                ...meta,
                title: '编辑基地'
            }
        },{
            path: 'suyuan/block',
            name: `${pre}suyuan-block`,
            component: _import('operate/suyuan/block/index'),
            meta: {
                ...meta,
                title: '地块'
            }
        },{
            path: 'suyuan/block/create',
            name: `${pre}suyuan-block-create`,
            component: _import('operate/suyuan/block/create'),
            meta: {
                ...meta,
                title: '创建地块'
            }
        },{
            path: 'suyuan/block/update/:id',
            name: `${pre}suyuan-block-update`,
            component: _import('operate/suyuan/block/update'),
            meta: {
                ...meta,
                title: '编辑地块'
            }
        },{
            path: 'suyuan/modelTemplate',
            name: `${pre}suyuan-modelTemplate`,
            component: _import('operate/suyuan/modelTemplate/index'),
            meta: {
                ...meta,
                title: '产品模型'
            }
        },{
            path: 'suyuan/plant',
            name: `${pre}suyuan-plant`,
            component: _import('operate/suyuan/plant/index'),
            meta: {
                ...meta,
                title: '种植档案'
            }
        },{
            path: 'suyuan/farming',
            name: `${pre}suyuan-farming`,
            component: _import('operate/suyuan/farming/index'),
            meta: {
                ...meta,
                title: '农事活动'
            }
        },{
            path: 'suyuan/materiel',
            name: `${pre}suyuan-materiel`,
            component: _import('operate/suyuan/materiel/index'),
            meta: {
                ...meta,
                title: '农资档案'
            }
        },{
            path: 'suyuan/recovery',
            name: `${pre}suyuan-recovery`,
            component: _import('operate/suyuan/recovery/index'),
            meta: {
                ...meta,
                title: '采收'
            }
        },{
            path: 'suyuan/processTemplate',
            name: `${pre}suyuan-processTemplate`,
            component: _import('operate/suyuan/processTemplate/index'),
            meta: {
                ...meta,
                title: '初加工模板'
            }
        }
    ]
}