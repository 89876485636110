import request from '@/plugin/axios';
import util from '@/libs/util.js';

var rootURL = '/kitchen/dailymenu';

var obj = {};
obj.page = function (data) {
  return util.baseRequest(data, rootURL + '/page');
};
obj.add = function (data) {
  return util.baseJsonRequest(data, rootURL + '/add');
};
obj.detailById = function (data) {
  return util.baseRequest(data, rootURL + '/detailById');
};
obj.update = function (data) {
  return util.baseJsonRequest(data, rootURL + '/update');
};
obj.updateOrderItemByDailyMenu = function (data) {
  return util.baseJsonRequest(data, rootURL + '/updateOrderItemByDailyMenu');
};
obj.deleteByIds = function (data) {
  return util.baseRequest(data, rootURL + '/deleteByIds');
};
obj.searchByIdAndDateRang = function (data) {
  return util.baseRequest(data, rootURL + '/searchByIdAndDateRang');
};

obj.copy = function (data) {
  return util.baseRequest(data, rootURL + '/copy');
};
/*菜谱数据*/
obj.everydayCookbook = function (data) {
  return util.baseRequest(data, rootURL + '/everydayCookbook');
};
/*删除菜谱中某个菜品*/
obj.removeMapping = function (data) {
  return util.baseRequest(data, rootURL + '/removeMapping');
};
/*食谱 选择可用的  菜品 分页*/
obj.findAvailableProTmpMappingWithBom = function (data) {
  return util.baseRequest(data, '/kitchen/proTmp/findAvailableProTmpMappingWithBom');
};
/*菜SKU ID    编辑时加载菜的 bom*/
obj.findBomDetails = function (data) {
  return util.baseRequest(data, '/kitchen/bom/findBomDetails');
};
/*菜SKU ID    编辑时加载菜的 bom*/
obj.bomConsumptionPage = function (data) {
  return util.baseRequest(data, '/kitchen/bomConsumption/page');
};
/*保存每日bom  ,    接收数组对象*/
// obj.updateMapping = function (data) {
//   return util.baseRequest(data, '/kitchen/dailymenu/updateMapping');
// };
/*保存每日bom  ,    接收数组对象*/
obj.updateMapping = function (data) {
  return util.baseRequest(data, '/kitchen/bomConsumption/update');
};
/*导入excel*/
obj.importExcel = function (data) {
  return util.baseJsonRequest(data, '/kitchen/dailymenu/importExcel');
};
obj.importExcelDaily = function (data) {
  return util.baseJsonRequest(data, '/kitchen/dailymenu/importExcelDaily');
};
// 常用菜
obj.mostFood = function (data) {
  return util.baseRequest(data, '/kitchen/proTmp/mostFood');
};
// 导入原有日期套餐
obj.importData  = function (data) {
  return util.baseRequest(data, '/kitchen/dailymenu/pageT');
};
export default obj;
