import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/CusCustomer'

function baseRequest(data, api) {
	return util.baseRequest(data, rootURL + api)	
}
var obj = {}
obj.add = function(data) {
	return util.baseJsonRequest(data, rootURL + '/create');
}
obj.addressDefaultByCusId = function(data) {
	return util.baseRequest(data, rootURL + '/addressDefaultByCusId')
}
/**
 * 客户非默认地址
 * @param {*} data 
 */
 obj.addressNotDefaultByCusId = async function(customerId) {
	try {
		var res = await util.baseRequest({customerId}, '/kitchen/Address/page')
		return res.aaData.filter(el => {
			if(!el.isDefault) {
				return el
			}
		})
	} catch (error) {
		return []
	}	
}
obj.pageDataList = function(data) {
	return baseRequest(data, '/page');
}
obj.update = function(data) {
	return util.baseJsonRequest(data, rootURL + '/update');
}
obj.confirm = function (data) {
	return baseRequest(data, '/confirmByIds');
}
obj.cancel = function (data) {
	return baseRequest(data, '/cancelByIds');
}
obj.delete = function (data) {
	return baseRequest(data, '/delete');
}
obj.detailById = function (data) {
	return util.baseRequest(data, rootURL + '/queryById')
}
obj.addressAdd = function (data) {
	return util.baseRequest(data, rootURL + '/addressAdd')
}
obj.addressUpdate = function (data) {
	return util.baseRequest(data, rootURL + '/addressUpdate')
}
obj.addressListByCusId = function (data) {
	return util.baseRequest(data, rootURL + '/addressListByCusId')
}
obj.pcMallProductSearch = function (data) {
	return util.baseRequest(data, rootURL + '/pcMallProductSearch')
}
obj.getMemberBySession = function (data) {
	return util.baseRequest(data, rootURL + '/getMemberBySession')
}
obj.officeMemberPage = function (data) {
	return util.baseRequest(data, rootURL + '/officeMemberPage')
}
export default obj