<template>
  <div class="backlog-dialog">
    <!-- 待办 -->
    <el-button class="d2-mr btn-text can-hover" type="text" @click="approvalListShow = true">
      <el-tooltip effect="dark" content="审批消息" placement="bottom">
          <d2-icon name="calendar-check-o" style="font-size: 18px;" />
      </el-tooltip>
    </el-button>
    <!-- 弹窗 -->
    <!-- <el-dialog class="backlog-modal" :modal="false" title="待办事项" :visible.sync="false" :before-close="close" v-dialog-drag="true" append-to-body>
||||||| .r5195
    <el-dialog class="backlog-modal" :modal="false" title="待办事项" :visible.sync="dialogVisible" :before-close="close">
=======
    <el-dialog class="backlog-modal" :modal="false" title="待办事项" :visible.sync="dialogVisible" :before-close="closeSP">
>>>>>>> .r5250
      <div class="backlog-content pd-t-16 pd-l-16 pd-r-16">
        <el-form :inline="true" :model="form" ref="form" style="margin-bottom: -18px;">
          <el-form-item label="单据类型">
            <el-select v-model="form.name" placeholder="请选择单据类型">
              <el-option label="全部" :value="11"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input v-model="form.name" placeholder="输入单据号/创建人等关键字搜索" />
          </el-form-item>
          <el-form-item>
            <el-button class="sys-primary-btn" icon="el-icon-search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="sys-btn" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>
        <d2-crud ref="d2Crud" :columns="columns" :data="data" :options="crudOptions" />
      </div>
      <div slot="footer" class="backlog-dialog-footer flex flex-x-between">
        <div class="fs-14 btn flex flex-y-center">
          <el-checkbox v-model="checked" class="mg-r-8"></el-checkbox>
          <span>只显示待审核事项</span>
        </div>
        <el-pagination
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          :page-sizes="pagination.pageSizeArr"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: -10px;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-dialog> -->

    <approvalList v-if="approvalListShow" :modal="approvalListShow" @closemodal="closemodal" :recordNumber="recordNumber"></approvalList>

  </div>
</template>
<script>
import approvalList from './approvalList.vue'
import mixins from '@/plugin/mixins/mixin'
import page from './mixins'

export default {
  mixins: [mixins, page],
  components:{approvalList},
  data() {
    return {
      recordNumber: '',
      approvalListShow: false,
      form: {
        name: ''
      },
      checked: false,
      crudOptions: {
        height: '321px'
      },
      type: 1,
      columns: [{
        title: '单据号',
        key: 'date',
        width: '180'
      }, {
        title: '单据类型',
        key: 'name',
        width: '180'
      }, {
        title: '审核状态',
        key: 'address'
      }, {
        title: '创建人',
        key: 'address'
      }, {
        title: '创建时间',
        key: 'address'
      }, {
        title: "",
        key: "type",
        component: {
          render: (h, row) => {
            return <span class="row-handle-btn">去审核>></span>
          }
        }
      }],
      data: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }
      ]
    }
  },
  created() {
    //this.initPage()
  },
  methods: {
    handleSizeChange(val) {
      this.pagination.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
    },
    closemodal() {
      this.approvalListShow = false
    },
    openDiag(){
      this.dialogVisible=true;
    }
  }
};
</script>
<style lang="scss">
$textColor: #3281f2;
.backlog-dialog {
  .row-handle-btn {
    cursor: pointer;
    color: $textColor;
  }
  .backlog-content {
    height: 400px;
  }
  .backlog-dialog-footer {
    .btn {
      color: #6c7294;
      cursor: pointer;
    }
  }
  // 弹窗
  .backlog-modal {
    min-width: 980px;
    .el-dialog {
      border: 2px solid #e3e3e3 !important;
      box-shadow: none;

      .el-dialog__header {
        border-bottom: 1px solid #c8d2de !important;
        background: #fff;
        padding: 16px 20px 16px;
        text-align: center;

        // 弹窗头字体颜色
        .el-dialog__title {
          font-size: 14px;
          color: #02183a !important;
          // font-weight: 600 !important;
        }

        // 关闭按钮
        .el-dialog__headerbtn {
          border: 3px solid #c4c4c4 !important;
          border-radius: 100px;
          transition: all 0.2s ease-out;
          font-size: 18px;
          width: 32px;
          height: 32px;
          line-height: 30px;
          top: -12px;
          right: -12px;
          background: #fff;
          &:hover {
            transform: scale(1.2);
          }
        }
        .el-dialog__close {
          font-size: 20px;
          color: #c4c4c4 !important;
          font-weight: 600 !important;
        }
      }

      .el-dialog__body {
        max-height: 500px !important;
        overflow-y: auto !important;
        padding: 0 !important;
      }
      &.minh {
        .el-dialog__body {
          min-height: 500px !important;
        }
      }

      .el-form-item__content {
        & > .el-input,
        & > .el-select,
        & > .el-cascader,
        & > div:not(.el-checkbox-group)
        & > div:not(.avatar-uploader) {
          width: 220px;
        }

        & > div {
          word-break: break-all;
        }
      }

      .el-dialog__footer {
        border-top: 1px solid #e3e3e3;
        background: #ffff;
        padding: 20px;
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-message/backlog.vue"
}
</vue-filename-injector>
