import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/CustomerOrder'

function baseRequest(data, api) {
	return util.baseRequest(data, rootURL + api)	
}
var obj = {}
obj.pcMallProductSearch = function (data) {
	return util.baseRequest(data, rootURL + '/pcMallProductSearch')
}
export default obj