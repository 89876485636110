import store from '@/store'
import axios from 'axios'
import md5 from 'js-md5';
import {
    Message
} from 'element-ui'
import util from '@/libs/util'
import Qs from 'qs'
import errorMeassge from "@/plugin/axios/errorMeassge";

const errMsg = new errorMeassge();

// 创建一个错误
function errorCreate(msg) {
    const error = new Error(msg)
    errorLog(error)
    throw error
}

// 记录和显示错误
function errorLog(error) {
    // 添加到日志
    store.dispatch('zycf/log/push', {
            message: '数据请求异常',
            type: 'danger',
            meta: {
                error
            }
        })
        // 打印到控制台
    if (process.env.NODE_ENV === 'development') {
        util.log.danger('>>>>>> Error >>>>>>')
    }
    if (error.message)
        errMsg.error(error.message);
    // 显示提示
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000,
    //   showClose: true
    // })
}

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 0, // 请求超时时间
    headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
})

/**
 *请求发出前给接口追加参数
 * @param {*} parameters
 */
function AdditionalParameter(config) {

    const userinfo = store.state.zycf.user.userinfo;
    const lastname = config.url.substring(config.url.lastIndexOf('/') + 1);
    switch (lastname) {
        case 'create':
            config.data.createdPersonId = userinfo.userId;
            config.data.createdPerson = userinfo.nickName;
            break;
        case 'update':
            config.data.updatedDate = null;
            config.data.updatedPersonId = userinfo.userId;
            config.data.updatedPerson = userinfo.nickName;
            break;
        default:
            break;
    }
}
//################################取消重复请求逻辑-开始###################################
let pending = [];
//let pendingData = {}
let cancelToken = axios.CancelToken
    // 请求标识；完成请求后也需要执行删除记录，所以添加此参数避免执行无用操作
let removePending = (config, deleted = false) => {
        let url = deleted ? config.url : (config.baseURL.substring(config.baseURL.lastIndexOf('/'), '/') + config.url);
        for (let i in pending) {
            if (pending[i].url === url) { //在当前请求在数组中存在时执行取消函数
                if (!deleted) {
                    console.log('重复请求-->', url);
                }
                pending[i].f(); //执行取消操作
                if (deleted) {
                    pending.splice(i, 1)
                }
            }
        }
    }
    /**
     * 由于我们请求用了代理 直接代理到测试服务器 因此请求响应拦截器的config.url是一致的，不需要标识值区分
     * 如果请求拦截器和响应拦截器的config.url不一致，就需要一个标识值用来区分判断
     */
const getRequestIdentify = (config) => {
    const url = config.url
        // 返回url及请求参数 post方法请求参数为config.data  get方法请求参数为config.params
    if (config.method === 'get' || config.method === 'delete') {
        return encodeURIComponent(url + JSON.stringify(config.params))
    }

    return encodeURIComponent(url + JSON.stringify(config.data))
}

//################################取消重复请求逻辑-结束###################################
// 请求拦截器
service.interceptors.request.use(
    config => {
        /**去除参数里面的属性值左右两边的空格**/
        util.RemoveSpace(config.data);
        util.RemoveSpace(config.params);

        //添加时间戳，防止缓存
        config.params = {
          ...config.params,
          _t: Date.parse(new Date()) / 1000
        }

        if (config.params) {
            config.headers['sign'] = md5(JSON.stringify(config.params));
        }else if (config.data){
            config.headers['sign'] = md5(JSON.stringify(config.data));
        }else {
            config.headers['sign'] = md5(config.url);
        }
        if (config.url.indexOf('login/login') != -1) {
            let authcodekey = util.cookies.get('authcodekey');
            config.headers['authcodekey'] = authcodekey || '';
        }
        if (config.baseURL == '/api/' && config.overapi != 1) {
            const token = util.cookies.get('token');
            if (!token) {
                store.dispatch('zycf/account/logout', {});
                // errorLog("登录超时，请重新登录！")
                // source.cancel('登录超时，请重新登录！'); // 取消其他正在进行的请求
                const error = new Error('登录超时，请重新登录！')
                // source.cancel('登录超时，请重新登录！');
                throw error;
            }
            config.headers['Authorization'] = token
        }
        config.cancelToken = new cancelToken(function executor(c) { //本次axios请求的配置添加cancelToken
            pending.push({
                // url: config.url,
                url: config.baseURL.substring(config.baseURL.lastIndexOf('/'), '/') + config.url,
                f: c
            });
        });
        switch (config.method) {
            case 'get':                
                config.paramsSerializer = (params) => {
                    return Qs.stringify(params, {
                        indices: false
                    });
                }
                break;
            case 'post':
                AdditionalParameter(config);
                config.data = Qs.stringify(config.data, {
                    indices: false
                });
                break;
            case 'put':
                config.data = Qs.stringify(config.data, {
                    indices: false
                });
                break;
            case 'delete':
                config.paramsSerializer = (params) => {
                    return Qs.stringify(params, {
                        indices: false
                    });
                }
                break;
            case 'json':
                AdditionalParameter(config);
                config.data = JSON.stringify(config.data)
                config.headers['Content-Type'] = "application/json;charset=UTF-8"
                config.method = 'post';

                break;
            default:
                config.data = Qs.stringify(config.data, {
                    indices: false
                });
                break;
        }

        return config
    },
    error => {
            // 发送失败
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 请求成功
        // 调用获取验证码时的一个操作，不清楚有何用
        if (response.config.url.indexOf('login/auth/code') != -1) {
            util.cookies.set('authcodekey', response.headers.authcodekey || '');
        }
        // 根据返回结果分别进行处理
        var responseData = response.data
        if (typeof responseData === 'undefined') {
            return response
        }
        // state == 0，或者文件类型和字节类型的，都直接返回
        if (responseData.state == 0 ||
            responseData.__proto__.arrayBuffer === 'function' ||
            typeof responseData.byteLength === 'number') {
            return responseData
        }
        // 以上都不是，则当做接口返回错误结果，并提示信息
        if (typeof responseData.msg === 'string') {
            errMsg.error(responseData.msg)
        }
        switch (responseData.state) {
            case 97:
                store.dispatch('zycf/account/logout', {})
                errMsg.error(responseData.msg)
                break
            case 98:
                store.dispatch('zycf/account/logout', {})
                errMsg.error(responseData.msg)
                break
            default:
                return responseData
        }
    },
    error => {
        // 请求错误
        const status = get(error, 'response.status')
        switch (status) {
            case 404:
                error.message = `请求地址出错: ${error.response.config.url}`;
                break
            case 408:
                error.message = '请求超时';
                break
            case 500:
                error.message = '网络错误';
                break
            case 501:
                error.message = '服务未实现';
                break
            case 502:
                error.message = '网关错误';
                break
            case 503:
                error.message = '服务不可用';
                break
            case 504:
                error.message = '网关超时';
                break
            case 505:
                error.message = 'HTTP版本不受支持';
                break
            default:
                break
        }
        errMsg.error(error.message)
        return {}
    }
)

export default service