<template>
  <el-tooltip effect="dark" content="版本记录" placement="bottom">
    <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
      <d2-icon name="history" style="font-size: 18px;" />
      <!-- <img class="w-20 h-20 can-hover" src="../../../../../public/image/hjk/icon_banben.png" alt /> -->
    </el-button>
  </el-tooltip>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$router.push({ name: 'versionrecord' })
    }
  }
}
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-banben/index.vue"
}
</vue-filename-injector>
