import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/CustomerQuotation'

var obj = {}
obj.page = function(data) {
	return util.baseRequest(data, rootURL + '/page')
}
obj.add = function(data) {
	return util.baseJsonRequest(data, rootURL + '/add')
}
obj.detailById = function(data) {
	return util.baseRequest(data, rootURL + '/detailById')
}
obj.update = function(data) {
	return util.baseJsonRequest(data, rootURL + '/update')
}
obj.delete = function (data) {
	return util.baseRequest(data, rootURL + '/deleteByIds')
}
obj.cancel = function (data) {
	return util.baseRequest(data, rootURL + '/cancelByIds')
}
obj.confirm = function (data) {
	return util.baseRequest(data, rootURL + '/confirmByIds')
}
export default obj