<template>
  <div class="msg-page">
    <!-- 消息 -->
    <el-button class="d2-mr btn-text can-hover" type="text" @click="showDialog">
      <el-tooltip effect="dark" content="消息中心" placement="bottom">
        <el-badge :value="count.newDataCount" :max="500" class="item">
          <d2-icon name="envelope" style="font-size: 18px;" />
        </el-badge>
      </el-tooltip>
    </el-button>
    <!-- 消息弹窗 -->
    <el-dialog v-dialog-drag="true" ref="msgREF" class="msg-modal" :modal="false" title="消息中心" :visible.sync="MSGdialogVisible" :before-close="close">
      <div class="msg-content flex">
        <div class="left">
          <ul>
            <li
              :class="activeStatus==d.id?'active':''"
              v-for="(d,key) in statusList"
              :key="key"
              @click="activeStatusClick(d)"
            >{{d.name}}</li>
          </ul>
        </div>
        <div
          class="right flex-item-1 pd-l-20 pd-r-20 pd-b-20 scroll-y"
          v-infinite-scroll="load"
          :infinite-scroll-immediate="false"
        >
          <!-- 消息列表 -->
          <template v-if="!showDetails">
            <div class="msg-list">
              <li v-for="(d,key) in dataList" :key="key" class="infinite-list-item" style="list-style: none;">
                <div class="msg-item-date">
                  <span>{{d.createdDate}}</span>
                </div>
                <div class="msg-item-text">
                  <div class="title" v-if="d.status===false">
                    <el-badge is-dot>{{d.title}}</el-badge>
                  </div>
                  <div class="title" v-else>{{d.title}}</div>
                  <div class="content">{{d.message}}</div>
                  <div class="btn flex flex-x-end">
                    <span @click="getMsgDetails(d)">详情>></span>
                  </div>
                </div>
              </li>
            </div>
          </template>
          <!-- 消息详情 -->
          <template v-else>
            <div class="msg-details msg-item-text pd-t-20">
              <div class="msg-title pd-l-16 pd-r-16 pd-t-10 pd-b-10 flex flex-x-between flex-y-center">
                <div class="fw-b fs-16">{{massageDetail.title}}</div>
                <div class="back flex flex-y-center" @click="backMsgList">
                  <span class="el-icon-arrow-left mg-t-1 fs-14"></span>
                  <span>返回</span>
                </div>
              </div>
              <div class="padding-20 fs-14" style="background: #F8F8F8;">
                <div class="mg-b-3">发信人：{{massageDetail.fromName}}</div>
                <div class="mg-b-3">时间：{{massageDetail.createdDate}}</div>
                <div class="mg-b-3">收信人：{{massageDetail.remindUserName}}</div>
              </div>
              <div class="fs-14 padding-20">
                {{massageDetail.message}}
              </div>
              <div class="btn flex flex-x-end"  v-if="massageDetail.type==1">
                <span @click="seeOrderList" style="color:#3281f2">去处理>></span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer" class="msg-dialog-footer flex-x-end"  v-if="!showDetails && count.newDataCount>0">
        <div class="fs-14 btn" @click="allRead">全部标记为已读</div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import mixin from './mixins'
import ssocket from "@/plugin/webSocket/myWebSocket";
import util from '@/libs/util'
import dialogDrag from '@/libs/dialog-drag'
export default {
  directives: {
        dialogDrag
    },
  mixins: [mixin, ssocket],
  data() {
    return {
      type: 9,
      userInfo: this.$store.state.zycf.user.userinfo,
      token: util.cookies.get('token'),
        timer:{},
    }
  },
  mounted() {
    this.IntervalSocket();//480000
    this.timer = setInterval(this.IntervalSocket, 480000);
  },
  created() {
    this.initPage()
  },
  methods:{
    IntervalSocket(){
      console.log('定时连接')
      this.initSocket(this.userInfo.userId, this.token);
    },
    cloaseTimer(){
        clearInterval(this.timer);
        this.closeSocket();
    }
  }
};
</script>
<style lang="scss">
$textColor: #3281f2;
.dadge_class {
  margin-right: 106%;
}
.msg-page {
  .msg-content {
    height: 400px;
    .left {
      width: 166px;
      height: 100%;
      background: #f8f8f8;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        cursor: pointer;
        padding: 20px;
        li {
          margin-bottom: 20px;
        }
        li.active {
          color: $textColor;
        }
      }
    }
    .right {
      height: 100%;
      position: relative;
      .top-bar {
        background: #fff;
        position: sticky;
        top: 0;
      }
      .msg-list {
        .msg-item {
          &-date {
            text-align: center;
            margin: 16px 0;
            span {
              background: #fafafa;
              color: #999;
              padding: 3px 10px;
              font-size: 12px;
            }
          }
          &-text {
            background: #f4f4f4;
            padding: 10px;
            .title {
              padding-bottom: 10px;
              font-weight: 600;
              border-bottom: 1px solid #d9d9d9;
            }
            .content {
              padding: 10px 0;
            }
            .btn {
              color: $textColor;
              span:hover {
                cursor: pointer;
               // text-decoration: underline;
              }
            }
          }
        }
      }
      .msg-details {
        .msg-title {
          background: #d7dde1;
        }
        .back {
          color: $textColor;
          cursor: pointer;
         // text-decoration: underline;
        }
      }
    }
  }
  .msg-dialog-footer {
    .btn {
      color: $textColor;
      cursor: pointer;
    }
  }
  // 弹窗
  .msg-modal {
    min-width: 980px;
    .el-dialog {
      border: 2px solid #e3e3e3 !important;
      box-shadow: none;

      .el-dialog__header {
        border-bottom: 1px solid #c8d2de !important;
        background: #fff;
        padding: 16px 20px 16px;
        text-align: center;

        // 弹窗头字体颜色
        .el-dialog__title {
          font-size: 14px;
          color: #02183a !important;
          // font-weight: 600 !important;
        }

        // 关闭按钮
        // .el-dialog__headerbtn {
        //   border: 3px solid #c4c4c4 !important;
        //   border-radius: 100px;
        //   transition: all 0.2s ease-out;
        //   font-size: 18px;
        //   width: 32px;
        //   height: 32px;
        //   line-height: 30px;
        //   top: -12px;
        //   right: -12px;
        //   background: #fff;
        //   &:hover {
        //     transform: scale(1.2);
        //   }
        // }
        // .el-dialog__close {
        //   font-size: 20px;
        //   color: #c4c4c4 !important;
        //   font-weight: 600 !important;
        // }
      }

      .el-dialog__body {
        max-height: 500px !important;
        overflow-y: auto !important;
        padding: 0 !important;
      }
      &.minh {
        .el-dialog__body {
          min-height: 500px !important;
        }
      }

      .el-form-item__content {
        & > .el-input,
        & > .el-select,
        & > .el-cascader,
        & > div:not(.el-checkbox-group)
        & > div:not(.avatar-uploader) {
          width: 220px;
        }

        & > div {
          word-break: break-all;
        }
      }

      .el-dialog__footer {
        border-top: 1px solid #e3e3e3;
        background: #ffff;
        padding: 20px;
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-message/msg.vue"
}
</vue-filename-injector>
