<template>
  <!-- 此组件只是导出弹窗，并没有处理导出逻辑，导出还是在母页面进行处理 -->
  <base-dialog
    :modal="modal"
    @closemodal="cancel"
    ref="baseDialog"
    title="导出"
    width="30%"
  >
    <el-radio v-model="radio" :label="1">导出选中</el-radio>
    <el-radio v-model="radio" :label="2">导出当前</el-radio>
    <el-radio v-model="radio" :label="3">导出所有(搜索条件)</el-radio>
    <div slot="footer" class="dialog-footer flex-x-end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="handleDownload" :loading="exportloading">提交</el-button>
    </div>
  </base-dialog>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
export default {
  name: "export-dialog",
  mixins: [mixin],
  data() {
    return {
      radio: 1,
    };
  },
  props: {
    modal: {
      default: false,
    },
    exportloading: {
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    handleDownload() {
      this.$emit("handleSubmit", this.radio);
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/export-dialog/index.vue"
}
</vue-filename-injector>
