<template>
  <el-collapse v-model="activeName" style="padding: 10px" :value="opened">
    <el-collapse-item name="1">
      <template slot="title">
        <span style="font-weight: bold">单据详情</span>
      </template>
      <el-card class="box-card" style="margin-top: 0px">
        <el-form ref="form" :inline="true" :label-width="labelWidth">
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="订单编号">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.orderNumber }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="订单状态">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.orderStatusName }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="业务员">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.memberName }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="订单来源">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.sourceName }}</label
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="客户">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.customerName }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="配送日期">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.distributionDate }}</label
                >
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="收货人">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.receivePerson }}</label
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-form-item label="收货电话">
                <label
                  class="el-form-item__label"
                  style="vertical-align: baseline"
                  >{{ form.receivePhone }}</label
                >
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="moreCondition">
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-form-item label="收货时间段">
                  <label
                    class="el-form-item__label"
                    style="vertical-align: baseline"
                    >{{ form.distributionTimeBegin }}</label
                  >-
                  <label
                    class="el-form-item__label"
                    style="vertical-align: baseline"
                    >{{ form.distributionTimeEnd }}</label
                  >
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-form-item label="收货地址">
                  <label
                    class="el-form-item__label"
                    style="vertical-align: baseline"
                    >{{ form.receiveAddress }}</label
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="备注" class="full-form-item">
                  <label
                    class="el-form-item__label full-form-item-input"
                    style="vertical-align: baseline"
                    >{{ form.remark }}</label
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                <el-form-item label="订单总金额">
                  <label
                    class="el-form-item__label"
                    style="vertical-align: baseline"
                    >{{
                      form.orderAmount == null
                        ? ""
                        : "￥" + moneyString(form.orderAmount)
                    }}</label
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                <el-form-item label="验收金额">
                  <label
                    class="el-form-item__label"
                    style="vertical-align: baseline"
                    >{{
                      form.acAmount == null
                        ? ""
                        : "￥" + moneyString(form.acAmount)
                    }}</label
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <!-- <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="支付状态">
            <label class="el-form-item__label" style="vertical-align:baseline;">{{payStatusName}}</label>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="支付流水号">
            <label class="el-form-item__label" style="vertical-align:baseline;">{{form.paymentSerialNumber}}</label>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="支付类型">
            <label class="el-form-item__label" style="vertical-align:baseline;">{{payTypeName}}</label>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="支付金额">
            <label class="el-form-item__label" style="vertical-align:baseline;">{{form.payAmount}}</label>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="订单金额合计">
            <label class="el-form-item__label" style="vertical-align:baseline;">{{form.orderAmount}}</label>
          </el-form-item>
        </el-col>
      </el-row> -->

          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <MoreConditionBtn
                v-model="moreCondition"
                text="详情"
              ></MoreConditionBtn>
            </el-col>
          </el-row>
        </el-form>
        <d2-crud
          ref="itemCrud"
          flex-box="1"
          :columns="columns"
          :data="dataList"
          @row-delete="rowDelete"
          @cell-data-change="handleCellDataChange"
          index-row
        ></d2-crud>
        <div slot="footer" class="dialog-footer flex-x-end"></div>
      </el-card>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import mixin from "@/plugin/mixins/mixin";
import { order } from "@api/order";
import constant from "@/libs/constant.js";
import util from "@/libs/util.js";
import { cloneDeep, round } from "lodash";
import validators from "@/libs/validator";
import mountedMixin from "@/views/operate/order/common/mixin/mounted";

/**
 * 提交前对数据的处理
 * @param  {[type]} skuList [description]
 * @return {[type]}         [description]
 */
function itemListFromSkuList(skuList) {
  if (!Array.isArray(skuList) || skuList.length == 0) {
    return [];
  }
  var res = [];
  skuList.forEach(function (el) {
    var item = {};
    item.skuId = el.skuId;
    item.productId = el.productId;
    item.productName = el.productName;
    item.skuName = el.skuName;
    item.price = el.price;
    item.quantity = el.quantity;
    item.itemAmount = el.itemAmount;
    res.push(item);
  });
  return res;
}

export default {
  mixins: [mixin, mountedMixin],
  data() {
    var vm = this;
    return {
      activeName: "1", //展开name为1的折叠板
      labelWidth: "110px",
      payStatusName: "",
      payTypeName: "",
      skuShow: false,
      dataList: [],
      columns: [
        {
          title: "商品名称",
          key: "fullName",
          width: "180px",
        },
        {
          title: "数量",
          key: "quantity",
          component: {
            render: (h, row, index) => {
              var s = row.measureMethod;
              var quantity = row.quantity;
              if (s == "千克") {
                quantity = util.numbertoFixed(row.quantity, 8);
              }
              return (
                <span>
                  {quantity}
                  {s}
                </span>
              );
            },
          },
        },
        {
          title: "单价",
          key: "price",
          component: {
            render: (h, row, index) => {
              var res = util.moneyString(row.price);
              return <span>￥{res}</span>;
            },
          },
        },
        {
          title: "mrp",
          key: "isMrp",
          component: {
            name: "EU-elTag",
            props: {
              datatype: "IS_OR_NO",
            },
          },
        },
        {
          title: "折扣数量",
          key: "discountQuantity",
          component: {
            render: (h, row, index) => {
              var s = row.measureMethod;
              var quantity = row.discountQuantity;
              if (s == "千克") {
                quantity = util.numbertoFixed(row.discountQuantity, 8);
              } else {
                quantity = util.numbertoFixed(row.discountQuantity, 0);
              }
              return (
                <span>
                  {quantity}
                  {s}
                </span>
              );
            },
          },
        },
        {
          title: "折扣比例(%)",
          key: "discountRate",
        },
        {
          title: "总价",
          key: "itemAmount",
          component: {
            render: (h, row, index) => {
              var res = util.moneyString(round(row.itemAmount, 2));
              return <span>￥{res}</span>;
            },
          },
        },
        {
          title: "数据来源",
          key: "sourceName",
          component: {
            render: (h, row, index) => {
              var res = "";
              this.itemSourceList.forEach((el) => {
                if (el.dataKey == row.source) {
                  res = el.dataValue;
                }
              });
              return <span>{res}</span>;
            },
          },
        },
        {
          title: "添菜份数(份)",
          key: "estimatePlus",
          show(row) {
            if (row.skuEndTypeBox == 1) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: "包装类型",
          key: "packType",
          component: {
            render: (h, row, index) => {
              var res = "无";
              this.packTypeList.forEach((el) => {
                if (el.dataKey == row.packType) {
                  res = el.dataValue;
                }
              });
              return <span>{res}</span>;
            },
          },
          show(row) {
            if (row.skuEndTypeBox == 1) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: "用餐人数",
          key: "dailyCount",
        },
        {
          title: "验收价",
          key: "acPrice",
          component: {
            render: (h, row, index) => {
              var res =
                row.acPrice == null ? "" : "￥" + util.moneyString(row.acPrice);
              return <span>{res}</span>;
            },
          },
        },
        {
          title: "验收数量",
          key: "acQuantity",
          component: {
            render: (h, row, index) => {
              var res =
                row.acQuantity == null
                  ? ""
                  : row.acQuantity + row.measureMethod;
              return <span>{res}</span>;
            },
          },
        },
        {
          title: "验收金额",
          key: "acAmount",
          component: {
            render: (h, row, index) => {
              var res =
                row.acAmount == null
                  ? ""
                  : "￥" + util.moneyString(row.acAmount);
              return <span>{res}</span>;
            },
          },
        },
      ],
      form: {
        customerName: "",
        id: null,
        buyerName: "",
        contractAmount: "",
        contractDate: "",
        effectiveDate: "",
        dueDate: "",
        holidayDeliver: 0,
        receivePhone: "",
        payType: "",
        paymentStatus: "",
        receiveAddress: "",
        receivePerson: "",
        payAmount: "",
        distributionTimeBegin: "",
        distributionTimeEnd: "",
        orderRemark: "",
        customerId: null,
        businessPersonIdList: [],
        itemList: [],
        shippingTime: "",
        distributionDate: "",
        orderAmount: "",
        payStatus: "",
        businessPersonList: [],
      },
      businessPersonIdList: [],
      payType: [],
      payStatus: [],
      statusInfo: [],
      itemSourceList: [],
      sourceList: [],
      bussinessName: "",
    };
  },
  props: {
    modal: {
      default: false,
    },
    orderId: {
      default: null,
    },
  },
  async mounted() {
    var res = await order.detailById({
      id: this.orderId,
    });
    this.form = cloneDeep(res.aaData);
    this.payType = this._searchDic(constant.PAY_TYPE);
    this.payStatus = this._searchDic(constant.OD_PAY_STATUS);
    this.payStatusName = this._dicValue(this.form.payStatus, this.payStatus);
    this.payTypeName = this._dicValue(this.form.payType, this.payType);
    this.statusInfo = this._searchDic(constant.OD_STATUS);
    this.itemSourceList = this._searchDic(constant.ORD_ITEM_SOURCE);
    this.packTypeList = this._searchDic(constant.PACK_TYPE);
    this.sourceList = this._searchDic(constant.ORD_SOURCE);
    this.statusInfo.forEach((el) => {
      if (this.form.orderStatus == el.dataKey) {
        this.form.orderStatusName = el.dataValue;
      }
    });
    this.sourceList.forEach((el) => {
      if (this.form.source == el.dataKey) {
        this.form.sourceName = el.dataValue;
      }
    });
    var rows = [];
    if (util.listNotNull(this.form.businessPersonList)) {
      this.bussinessName = this.form.businessPersonList[0].businessName;
    }
    this.dataList = cloneDeep(res.aaData.itemVOList);
  },
  created() {},
  methods: {
    // 合计金额
    totalMoney() {
      let total = 0;
      this.dataList.forEach((item) => {
        total =
          (total * 1000 +
            (Number(item.quantity || 0) *
              1000 *
              (Number(item.price || 0) * 1000)) /
              1000) /
          1000;
      });
      return total;
    },
    moneyString(num) {
      // this.form.orderAmount = this.totalMoney()
      return util.moneyString(num);
    },
    cusListOpen() {
      this.cusListShow = true;
    },
    closemodal(issearch) {
      this.cusListShow = false;
    },
    skuclosemodal() {
      this.skuShow = false;
    },
    cancel(flag) {
      this.$refs.form.resetFields();
      this.$emit("closemodal", flag);
    },
    handleReturnValueBusiness(rows) {
      this.$refs.businessSelect.setList(rows, "name", "id");
    },
    async submit() {
      try {
        await this.$refs["form"].validate();
      } catch (err) {
        return;
      }
      this.form.customerId = this.form.businessPersonIdList[0];
      this.form.customerName = this.$refs.cusSelect.dataRow[0].label;
      this.form.itemList = itemListFromSkuList(this.dataList);
      var param = cloneDeep(this.form);
      param.distributionDate = this._dateFormat({
        date: this.form.distributionDate,
      });
      var result = await order.update(param);
      util.modalSubimtHandle.call(this, result);
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      this.cancel(false);
      done();
    },
    handleReturnValue(row) {
      this.$refs.cusSelect.setList([row], "name", "id");
    },
    openProduct() {
      this.haveChosenRows = cloneDeep(this.dataList);
      this.skuShow = true;
    },
    skuSubmit(rows) {
      this.dataList = cloneDeep(this.dataList.concat(cloneDeep(rows)));
      this.skuclosemodal();
    },
    rowDelete({ index, row }) {
      this.dataList.splice(index, 1);
      this.form.orderAmount = this.totalMoney();
    },
    handleCellDataChange({ rowIndex, key, value, row }) {
      this.dataList[rowIndex][key] = value;
      this.dataList = cloneDeep(this.dataList);
    },
  },
};
</script>
<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-message/components/info.vue"
}
</vue-filename-injector>
