/**
 * 系统路由
 */
export default function (pre, _import, meta) {
  return [
    {
      path: 'visit',
      name: `${pre}visit`,
      component: _import('operate/visit/index'),
      meta: {
        ...meta,
        title: '来访管理'
      }
    },
    {
      path: 'visit/add',
      name: `${pre}visit-add`,
      component: _import('operate/visit/components/Add'),
      props: true,
      meta: {
        cache: true,
        title: '新增来访申请单'
      }
    },
    {
      path: 'visit/edit/:id',
      name: `${pre}visit-edit`,
      component: _import('operate/visit/components/Edit'),
      props: true,
      meta: {
        cache: true,
        title: '登记来访申请单'
      }
    },
    {
      path: 'visit/versionrecord',
      name: `versionrecord`,
      component: _import('operate/versionrecord/index.vue'),
      meta: {
        ...meta,
        title: '版本更新记录'
      }
    },
    {
      path: 'visit/viewdetail/:id',
      name: `${pre}visit-viewdetail`,
      component: _import('operate/visit/components/ViewDetail'),
      props: true,
      meta: {
        cache: true,
        title: '查看来访申请单'
      }
    },
    {
      path: 'disinfection',
      name: `${pre}user-disinfection`,
      component: _import('operate/user/disinfection/index'),
      meta: {
        ...meta,
        title: '消杀记录'
      }
    },
    {
      path: '/system/approve_unit',
      name: `${pre}approve-unit`,
      component: _import('operate/approve/unit.vue'),
      meta: {
        ...meta,
        title: '审批模块'
      }
    },
    {
      path: '/system/approve_condition',
      name: `${pre}approve-condition`,
      component: _import('operate/approve_condition/index.vue'),
      meta: {
        ...meta,
        title: '审批条件'
      }
    },
    {
      path: '/system/iot/video',
      name: `${pre}suyuan-video`,
      component: _import('operate/suyuan/video/index'),
      meta: {
        ...meta,
        title: '监控'
      }
    },
    {
      path: '/system/iot/devicemanage',
      name: `${pre}user-devicemanage`,
      component: _import('operate/user/devicemanage/index'),
      meta: {
        ...meta,
        title: '设备管理'
      }
    },
    {
      path: '/system/iot/devicetype',
      name: `${pre}user-devicetype`,
      component: _import('operate/user/devicetype/index'),
      meta: {
        ...meta,
        title: '设备类型管理'
      }
    },
    {
      path: '/system/iot/kanban',
      name: `${pre}kanban`,
      component: _import('operate/kanban/index'),
      meta: {
        ...meta,
        title: '看板管理'
      }
    },
    {
      path: '/system/iot/conveyorBelt',
      name: `${pre}conveyorBelt-conveyorBelt`,
      component: _import('operate/conveyorBelt/index'),
      meta: {
        ...meta,
        title: '传送系统管理'
      }
    },
    {
      path: '/system/iot/programfile',
      name: `${pre}programfile`,
      component: _import('operate/programfileVersion/index'),
      meta: {
        ...meta,
        title: '系统程序管理'
      }
    },

    {
      path: '/system/watchmsg',
      name: `${pre}user-watchmsg`,
      component: _import('operate/user/watchmsg/index'),
      meta: {
        ...meta,
        title: '消息模块'
      }
    },
    {
      path: '/system/iot/devicekb',
      name: `${pre}devicekb`,
      component: _import('operate/devicekanban/index'),
      meta: {
        ...meta,
        title: '设备看板对照'
      }
    }
  ]
}
