export default {
    data(){
        return{
            socketMSG:'',
        }
    },
    methods: {
        initSocket: function (userId,token) {
            if(typeof(WebSocket) === "undefined"){
                alert("您的浏览器不支持socket")
            }else{
                console.log("您的浏览器支持socket")
                // 实例化socket

                if(isCustomServer){
                    console.log('自定义消息服务地址:'+socketPath+userId+'/'+token)
                    this.socket = new WebSocket(socketPath+userId+'/'+token)
                }else{
                    this.socket = new WebSocket('ws://'+location.host+'/message/'+userId+'/'+token)
                }
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
            }
        },
        open: function () {

        },
        error: function () {
            // const h = this.$createElement;
            // this.$notify({
            //     title: '消息通知',
            //     duration:2500,
            //     message: h('i', { style: 'color: teal'},'连接错误')
            // });
        },

        send: function () {
            this.socket.send(params)
        },
        closeSocket: function () {
            this.destroyed();
        },
        destroyed () {
            // 销毁监听
            this.socket.onclose = this.closeSocket
        },
    },
}