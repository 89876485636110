<template>
  <el-dialog
    custom-class="jz-modal base-selection-dialog"
    :title="title"
    :visible="modal"
    :before-close="beforeClose"
    :close-on-click-modal="closeOnClickmodal"
    :width="width"
    append-to-body
    v-dialog-drag="true"
  >
    <div flex="dir:top">
      <!-- 搜索框 -->
      <div
        flex="dir:left"
        class="mg-b-10"
        v-if="typeof queryApi == 'function' && searchShow"
      >
        <el-input
          v-model="keyword"
          style="width: 240px"
          clearable
          :disabled="loading"
          @keyup.enter.native="handleSearch"
          :placeholder="placeholder"
        ></el-input>
        <slot name="customQuery"></slot>
        <el-button
          class="mg-l-10 sys-primary-btn"
          type="primary"
          :disabled="loading"
          v-if="searchBtnType"
          @click="handleSearch"
          >查询</el-button
        >
      </div>
      <!-- 数据列表 -->
      <div
        class="base-border mg-b-10"
        flex-box="1"
        flex="dir:top"
        v-if="multiple"
        :style="{ height: tableMaxHeight + 'px' }"
      >
        <el-table
          ref="d2Crud"
          :data="dataList"
          v-loading="loading"
          stripe
          border
          style="width: 100%"
          :empty-text=dataNullText
          :options="tableOptions"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
          :max-height="tableMaxHeight"
          highlight-current-row
          :tree-props="{ children: children, hasChildren: hasChildren }"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
            :selectable="selectable"
            fixed
          ></el-table-column>
          <slot></slot>
        </el-table>
      </div>
      <div
        class="base-border mg-b-10"
        flex-box="1"
        flex="dir:top"
        :style="{ height: tableMaxHeight + 'px' }"
        v-else
      >
        <el-table
          ref="d2Crud"
          :data="dataList"
          v-loading="loading"
          stripe
          border
          style="width: 100%"
          :options="tableOptions"
          @current-change="handleCurrentRowChange"
          @row-dblclick="
            () => {
              if (!this.disableDBClick) this.submit();
            }
          "
          index-row
          :max-height="tableMaxHeight"
          highlight-current-row
        >
          <slot></slot>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="paginationShow" class="pagination-container">
      <el-pagination
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :page-sizes="pagination.pageSizeArr"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="typeof queryApi == 'function'"
      ></el-pagination>
    </div>

    <!-- 底部操作栏 -->
    <div slot="footer" class="dialog-footer flex-x-end">
      <el-button @click="cancel(false)" class="sys-btn">取消</el-button>
      <el-button type="primary" @click="submit" class="sys-primary-btn"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import dialogDrag from "@/libs/dialog-drag";
import util from "@/libs/util";
import mixin from "@/plugin/mixins/mixin";
import cloneDeep from "lodash/cloneDeep";
import pullAllBy from "lodash/pullAllBy";
import uniqBy from "lodash/uniqBy";
import assign from "lodash/assign";
import dayjs from "dayjs";

export default {
  name: "base-selection-dialog",
  mixins: [mixin],
  props: {
    searchShow: {
      default: true,
    },
    paginationShow: {
      default: true,
    },
    dataNullText: {
      default: "暂无数据",
    },
    placeholder: {
      // 搜索框的默认提示
      default: "",
    },
    modal: {
      // 控制开关
      default: false,
    },
    searchBtnType: {
      // 查询按钮是否显示
      default: true,
    },
    title: {
      // 标题
      default: "",
    },
    closeOnClickmodal: {
      // 是否点击遮罩层可关闭
      default: false,
    },
    multiple: {
      // 是否多选
      default: false,
    },
    tableMaxHeight: {
      // 列表的最大高度，超出则在列表内出现滚动条
      default: 500,
    },
    tableOptions: {
      // 列表的options
      default: function() {
        return {};
      },
    },
    queryApi: {
      // 查询列表的接口
      default: function() {
        return {};
      },
    },
    haveChosenRows: {
      // 已选择的数据multiple
      default: function() {
        return [];
      },
    },
    haveChosenRowsKey: {
      // 已选择的数据的属性对应内部数据的主键
      default: "value",
    },
    keywordFields: {
      // 查询接口的关键字段名
      default: function() {
        return [];
      },
    },
    businessFiledName: {
      // 需要将列表数据的主键id转换成外部业务字段的字段名
      default: "",
    },
    outerDataList: {
      // 外部列表的数据
      default: function() {
        return [];
      },
    },
    keyField: {
      // 外部数据和内部数据之间关联的字段名
      default: "",
    },
    // 列表主键名，默认为id
    mainId: {
      default: "id",
    },
    submitHandle: {
      // 在执行提交之前需要进行的数据操作
      default: function() {},
    },
    apiParams: {
      default: function() {
        return {};
      },
    },
    //多选情况下实现复选框的默认禁用和启用 方法返回true or false 即可
    handleSelectable: {
      default: function() {},
    },
    uniqueRow: {
      default: false,
    },
    //弹窗宽度
    width: {
      default: "50%",
    },
    //是否禁用双击行事件
    disableDBClick: {
      default: false,
    },
    children: {
      default: function() {
        return [];
      },
    },
    hasChildren: {
      default: false,
    },
    keyWordField: {
      default: "",
    },
    /**
     * 选中项的样式，forbidden或者selection,selection为勾选,默认为forbidden禁用
     */
    selectedType: {
      default: "forbidden",
    },
    distributionDate: {
      // 配送日期
      default: "",
    },
  },
  directives: {
    dialogDrag,
  },
  data() {
    return {
      dataList: [],
      keyword: "",
      loading: false,
      currentRow: {},
      selectRows: [],
      before: [],
      beforeDataList: [],
    };
  },
  async mounted() {
    this.before = cloneDeep(this.haveChosenRows);
    this.beforeDataList = cloneDeep(this.outerDataList);
    this.selectRows = cloneDeep(this.beforeDataList);
    await this.handleSearchTable();
    // if (this.selectedType == "selection") {
    //   this.$nextTick(() => {
    //     if (this.haveChosenRows) {
    //       this.haveChosenRows.forEach((row) => {
    //         this.dataList.forEach((data) => {
    //           if (row.value == data.id) {
    //             this.$refs.d2Crud.toggleRowSelection(data);
    //           }
    //         });
    //       });
    //     }
    //   });
    // }
  },
  watch:{
    modal(){
      this.handleCurrentChange(1)
    }
  },
  methods: {
    //外部调用刷新数据方法
    async outsideRefresh() {
      this.before = cloneDeep(this.haveChosenRows);
      this.beforeDataList = cloneDeep(this.outerDataList);
      this.selectRows = cloneDeep(this.beforeDataList);
      await this.handleSearchTable();
    },
    getRowKey(row) {
      return row[this.mainId];
    },
    handleCurrentRowChange(currentRow, oldCurrentRow) {
      this.currentRow = currentRow;
    },
    async handleSearchTable() {
      const params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        distributionDate: this.distributionDate?dayjs(this.distributionDate).format( "YYYY-MM-DD"):'',
        keyword: this.keyword,
      };
      if (!util.isFalsy(this.keyWordField)) {
        params[this.keyWordField] = this.keyword;
      }
      params.keywordFields = this.keywordFields;
      assign(params, this.apiParams);
      var result = {};
      if (typeof this.queryApi == "function") {
        this.loading = true;
        result = await this.queryApi(params);
        this.dataList = result.aaData;
        this.$nextTick(
          function() {
            if (util.listNotNull(this.dataList)) {
              // this.dataList.forEach(
              //   function (el) {
              //     this.before.forEach(function (em) {}.bind(this));
              //   }.bind(this)
              // );
              //对选中的数据集根据主键id进行去重
              this.selectRows = cloneDeep(uniqBy(this.selectRows, "id"));
              this.pagination.total = result.dataCount;
              this.loading = false;
            } else {
              this.pagination.currentPage = 1;
              this.pagination.total = 0;
              this.loading = false;
            }
          }.bind(this)
        );
      } else {
        this.dataList = cloneDeep(this.outerDataList);
        this.$nextTick(
          function() {
            this.dataList.forEach(
              function(el) {
                this.before.forEach(
                  function(em) {
                    //每次查询之后对外部传入的数据进行勾选操作
                    if (
                      em[this.haveChosenRowsKey] == el[this.haveChosenRowsKey]
                    ) {
                      this.$refs.d2Crud.toggleRowSelection(el, true);
                    }
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }
      // 最后定制渲染
      if (typeof this.$listeners.renderHandle == "function") {
        this.$listeners.renderHandle(this.dataList);
      }
      if (this.selectedType == "selection") {
        this.$nextTick(() => {
          this.selectRows.forEach((row) => {
            this.dataList.forEach((data) => {
              if (row.id == data.id) {
                this.$refs.d2Crud.toggleRowSelection(data, true);
              }
            });
          });
        });
      }
      this.loading = false;
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.handleSearchTable();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.handleSearchTable();
    },
    cancel(flag) {
      this.$emit("closemodal", flag);
    },
    async submit() {
      var businessFiledName = this.businessFiledName;
      if (!this.multiple) {
        this.$emit("return-value", cloneDeep(this.currentRow));
        this.cancel(false);
      } else {
        if (this.selectRows.length === 0) {
          if (this.selectedType == "selection") {
            this.$emit("return-value", []);
            return;
          }
          this.$message({ type: "warning", message: "请选择行" });
        } else {
          if (typeof this.queryApi != "function") {
            this.$emit("return-value", cloneDeep(this.selectRows));
            return;
          }
          if (businessFiledName.length > 0) {
            this.selectRows.forEach((el) => {
              el[businessFiledName] = el[this.mainId];
            });
          }

          if (
            this.beforeDataList.length > 0 &&
            this.keyField.length > 0 &&
            !this.uniqueRow
          ) {
            // 用rows和this.dataList进行比对，增加或者删除，已有的则不变
            // 需要删除的项目
            var delList = [];
            this.beforeDataList.forEach((el) => {
              var i = 0;
              this.selectRows.forEach((row) => {
                if (el[this.keyField] == row[this.keyField]) {
                  i = 1;
                }
              });
              if (i == 0) {
                delList.push(el);
              }
            });
            // 增加的项目
            var addList = [];
            this.selectRows.forEach((el) => {
              var i = 0;
              this.beforeDataList.forEach((row) => {
                if (el[this.keyField] == row[this.keyField]) {
                  i = 1;
                }
              });
              if (i == 0) {
                addList.push(el);
              }
            });
            // 删除操作
            if (this.selectedType == "selection") {
              this.beforeDataList = cloneDeep(
                pullAllBy(this.beforeDataList, delList, this.keyField)
              );
            }
            if (typeof this.submitHandle == "function") {
              await this.submitHandle(addList);
            }
            let rows = cloneDeep(this.beforeDataList.concat(addList));
            this.$emit("return-value", rows);
          } else if (!this.uniqueRow) {
            if (typeof this.submitHandle == "function") {
              await this.submitHandle(this.selectRows);
            }
            let rows = cloneDeep(this.selectRows);
            this.$emit("return-value", rows);
          } else {
            if (typeof this.submitHandle == "function") {
              await this.submitHandle(this.selectRows);
            }
            let rows = cloneDeep(this.beforeDataList.concat(this.selectRows));
            this.$emit("return-value", rows);
          }
        }
      }
    },
    beforeClose(done) {
      this.cancel(false);
      done();
    },
    async handleSearch() {
      this.updateBefore();
      this.handleCurrentChange(1);
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    /**
     * 对外部传入但是已取消勾选的数据进行移除
     * 每次手动查询之后触发
     * @return {[type]} [description]
     */
    updateBefore() {
      var delList = [];
      this.before.forEach(
        function(el) {
          var i = 0;
          this.selectRows.forEach((em) => {
            if (el[this.haveChosenRowsKey] == em.id) {
              i = 1;
            }
          });
          if (i == 0) {
            //移除
            var o = {};
            o[this.haveChosenRowsKey] = el[this.haveChosenRowsKey];
            delList.push(o);
          }
        }.bind(this)
      );
      if (this.selectedType == "selection") {
        this.before = cloneDeep(
          pullAllBy(this.before, delList, this.haveChosenRowsKey)
        );
      }
    },
    selectable(row, index) {
      if (this.selectedType == "selection") {
        return true;
      }
      if (typeof this.handleSelectable == "function") {
        const isAble = this.handleSelectable(row, index);
        if (isAble === false || isAble === "false") {
          return false;
        } else {
          return true;
        }
      } else {
        var f = true;
        this.beforeDataList.forEach((el) => {
          if (el[this.keyField] == row[this.mainId] && !this.uniqueRow) {
            f = false;
          }
        });
        return f;
      }
    },
  },
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/base-selection-dialog/index.vue"
}
</vue-filename-injector>
