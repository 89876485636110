/**
 * 开发人员工具
 */
export default function (pre, _import, meta) {
    return [ // 系统添加模块和按钮
        {
            path: 'modulebuttonmanage',
            name: 'modulebuttonmanage',
            meta: {
                title: '模块按钮配置',
                auth: true,
                cache: true,
            },
            component: _import('system/modulebuttonmanage/index')
        },
        {
            path: 'modulebuttonmanage/addedit/:id',
            name: `modulebuttonmanage-addedit`,
            component: _import('system/modulebuttonmanage/components/ModuleAddEdit'),
            props: true,
            meta: {
                cache: true,
                title: '新增|编辑模块按钮'
            }
        },
        // 日志
        {
            path: 'logbusiness',
            name: 'logbusiness',
            meta: {
                title: '操作日志',
                auth: true,
                cache: true,
            },
            component: _import('system/logbusiness/index')
        }, // 数据字典
        {
            path: 'datadic',
            name: 'datadic',
            meta: {
                title: '数据字典',
                auth: true,
                cache: true,
            },
            component: _import('system/datadic/index')
        }, // 货位
        // {
        //     path: 'location',
        //     name: 'location',
        //     meta: {
        //         title: '货位',
        //         ...meta,
        //     },
        //     component: _import('system/location/index')
        // }

    ]
}