import util from '@/libs/util.js'
import request from '@/plugin/axios'
var rootURL = '/kitchen/productSku'

var obj = {}
obj.page = function (data) {
	return util.baseRequest(data, rootURL + '/page')
}
obj.pageByPrice = function (data) {
	return util.baseRequest(data, rootURL + '/pageByPrice')
}
obj.pageEndSku = function (data) {
	return util.baseRequest(data, rootURL + '/pageEndSku')
}
obj.pageUpdate = function (data) {
	return util.baseRequest(data, rootURL + '/update')
}
obj.updateByJson = function (data) {
	return util.baseJsonRequest(data, rootURL + '/updateByJson')
}
obj.queryById = function (data) {
	return util.baseRequest(data, rootURL + '/queryById')
}
obj.batchUpdate = function (data) {
	return util.baseJsonRequest(data, rootURL + '/batchUpdate')
}
obj.checkExpirationDate = function (data) {
	return util.baseRequest(data, rootURL + '/checkExpirationDate')
}
obj.selectByCondition = function (data) {
	return util.baseRequest(data, rootURL + '/selectByCondition')
}

/**
 *导出
 *
 * @export
 * @param {*} data
 * @returns
 */
 obj.ExportExcelData=function(data){
	return request({
		url: '/kitchen/productSku/download/exportExcel',
		method: 'post',
		responseType: 'blob',
		data: data
	  })
}

/**
 *导出
 *
 * @export
 * @param {*} data
 * @returns
 */
 obj.SaleExportExcel=function(data){
	return request({
		url: '/kitchen/productSku/download/saleExportExcel',
		method: 'post',
		responseType: 'blob',
		data: data
	  })
}

/**
 *导出
 *
 * @export
 * @param {*} data
 * @returns
 */
 obj.SaleIncreaseExportExcel=function(data){
	return request({
		url: '/kitchen/productSku/download/saleIncreaseExportExcel',
		method: 'post',
		responseType: 'blob',
		data: data
	  })
}
export default obj
