<template>
    <el-form :model="model" :inline="inline" ref="baseDialogForm" :rules="rules" :label-width="labelWidth">
      <slot></slot>
    </el-form>
</template>
<script>
    import mixin from "@/plugin/mixins/mixin";
    export default {
        name: "base-dialog-form",
        mixins: [mixin],
        props: {
            model: {
                default: function() {
                    return {}
                }
            },
            inline: {
                default: true
            },
            rules: {
                default: function() {
                    return {}
                }
            },
            labelWidth: {
                default: '110px'
            }
        }
    }
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/base-dialog/base-dialog-form.vue"
}
</vue-filename-injector>
