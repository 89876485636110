export default {
	data() {
	  	return {
		  	labelWidth: '100px',
      	}
	},
	mounted() {
	    this.$nextTick((function() {
	      var els = document.getElementsByClassName('full-form-item')
	      if(typeof els != 'undefined') {
	      	var width = this.labelWidth
		      for(var i = 0; i < els.length; i++) {
		        var el = els[i]
		        var itemContent = el.getElementsByClassName('el-form-item__content')[0]
		        if(typeof itemContent != 'undefined') {
		        	itemContent.style.width = `calc(100% - ${width})`
			        var itemTextarea = itemContent.getElementsByClassName('full-form-item-input')[0]
			        if(typeof itemitemTextareaontent != 'undefined') {
			        	itemTextarea.style.width = '100%'
			        }
		        }
		        
		      }
	      }
	      
	    }).bind(this))
  	},
}