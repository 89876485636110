/**
 * 基础信息路由：包括商品信息、仓库管理、客户管理、供应商管理、设备管理
 */
export default function(pre, _import, meta) {
    return [{
            path: 'ware/locationInfo',
            name: `${pre}warehouse-locationInfo`,
            component: _import('operate/warehouse/locationInfo/index'),
            meta: {
                ...meta,
                title: '货位信息'
            }
        },
        {
            path: 'ware/warehouseInfo',
            name: `${pre}warehouse-warehouseInfo`,
            component: _import('operate/warehouse/warehouseInfo/index'),
            meta: {
                ...meta,
                title: '仓库信息'
            }
        },
        {
            path: 'ware/localType',
            name: `${pre}warehouse-localType`,
            component: _import('operate/warehouse/localType/index'),
            props: true,
            meta: {
                cache: true,
                title: '货架类型'
            }
        },
        {
            path: 'dev/devicemanagedetail',
            name: `${pre}user-devicemanagedetail`,
            component: _import('operate/user/devicemanagedetail/index'),
            meta: {
                ...meta,
                title: '设备产能管理'
            }
        },
        {
            path: 'cus/customer',
            name: `${pre}order-customer`,
            component: _import('operate/order/customer/index'),
            meta: {
                ...meta,
                title: '客户管理'
            }
        },
        {
            path: 'cus/cus_dept',
            name: `${pre}cus-dept`,
            component: _import('operate/order/cusdept/index'),
            meta: {
                ...meta,
                title: '客户部门'
            }
        },
        {
            path: 'sup/supplier',
            name: `${pre}supplier-supplierManage`,
            component: _import('operate/user/supplier/index'),
            meta: {
                ...meta,
                title: '供应商管理'
            }
        },
        {
            path: 'pro/product',
            name: `${pre}product-product`,
            component: _import('operate/product/product/index'),
            meta: {
                ...meta,
                title: '商品信息'
            }
        },
        {
            path: 'satellitekitchen',
            name: `${pre}satellitekitchen-satellitekitchen`,
            component: _import('operate/user/satellitekitchen/index'),
            meta: {
                ...meta,
                title: '卫星厨房'
            }
        }, {
            path: 'pro/skudetection',
            name: `${pre}product-skudetection`,
            component: _import('operate/product/sku/detection'),
            meta: {
                ...meta,
                title: '检测信息设置'
            }
        }, {
            path: 'pro/skuInventory',
            name: `${pre}product-skuInventory`,
            component: _import('operate/product/sku/inventory'),
            meta: {
                ...meta,
                title: '库存信息'
            }
        }, {
            path: 'pro/skuSell',
            name: `${pre}product-skuSell`,
            component: _import('operate/product/sku/sell'),
            meta: {
                ...meta,
                title: '销售信息'
            }
        }, {
            path: 'pro/skuTax',
            name: `${pre}product-skuTax`,
            component: _import('operate/product/sku/tax'),
            meta: {
                ...meta,
                title: '采购信息设置'
            }
        },
        {
            path: 'pro/skuSaleTax',
            name: `${pre}product-skuSaleTax`,
            component: _import('operate/product/sku/saletax'),
            meta: {
                ...meta,
                title: '销售税率设置'
            }
        },
        {
            path: 'nutrients',
            name: `${pre}nutrients-nutrients`,
            component: _import('operate/nutrients/index'),
            meta: {
                ...meta,
                title: '营养成份'
            }
        },
        {
            path: 'pro/productskuNutrients',
            name: `${pre}product-productskuNutrients`,
            component: _import('operate/product/productskuNutrients/index'),
            meta: {
                ...meta,
                title: '营养成份设置'
            }
        },
        {
            path: 'pro/costrange',
            name: `${pre}costrange-costrange`,
            component: _import('operate/product/sku/sellingSrice'),
            meta: {
                ...meta,
                title: '销售价涨幅管理'
            }
        },
        {
            path: 'daybom',
            name: `${pre}daybom-daybom`,
            component: _import('operate/product/daybom/index'),
            meta: {
                ...meta,
                title: '每日BOM'
            }
        },
        {
            path: 'pro//packagingsplit',
            name: `${pre}product-/packagingsplit`,
            component: _import('operate/product/packaging-split/index'),
            meta: {
                ...meta,
                title: '包装拆分'
            }
        },
    ]
}