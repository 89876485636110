import cloneDeep from 'lodash/cloneDeep'
export default {
	data() {
	  return {
		labelWidth: '150px',
	  	skuShow: false,
	    haveChosenRowsSku: [],
	    dataList: [],
	    rowHandle: {
        custom: [
          {
            text: "删除",
            type: "danger",
            emit: "row-delete",
			class: "sys-danger-btn"
          }
        ]
      },
	  }
	},
	methods: {
		openProduct() {
			this.apiParams.tmpId = this.form.tmpId
			this.haveChosenRowsSku = []
			this.dataList.forEach((function(el) {
				this.haveChosenRowsSku.push({
				value: el.tmpMappingId
				})
			}).bind(this))

			this.skuShow = true
		},
	    skuSubmitHandle(rows) {
			
			
	      rows.forEach(el => {
	        el.quantity = 1
	        el.price = el.basePrice
	        el.itemAmount = el.price
			el.proName = el.proName || el.productName
			el.tmpMappingId = el.itemId
	      })
	    },
	    skuSubmit(rows) {
	      	this.dataList = cloneDeep(rows)
      		this.skuclosemodal()
	    },
	    skuclosemodal() {
	      this.skuShow = false
	    },
	    itemListFromSkuList(skuList) {			
	    	if(!Array.isArray(skuList) || skuList.length == 0) {
			    return []
			  }
			  var res = []
			  skuList.forEach(function(el) {
			    var item = {}
			    item.skuId = el.skuId
			    item.productId = el.productId || el.proId
			    item.productName = el.productName || el.proName
			    item.proId = el.productId || el.proId
			    item.proName = el.productName || el.proName
			    item.skuName = el.skuName
			    // item.price = el.price
			    item.quantity = el.quantity
			    // item.itemAmount = el.itemAmount
			    item.distributionTimeBegin = el.distributionTimeBegin
				item.distributionTimeEnd = el.distributionTimeEnd
				item.weight = el.weight
				item.tmpMappingId = el.tmpMappingId
				item.type = el.type
			    res.push(item)
			  })
			  return res
	    },
	    handleCellDataChange({ rowIndex, key, value, row }) {
	      this.dataList[rowIndex][key] = value
	      this.dataList = cloneDeep(this.dataList)
	    },
	    // 合计金额
	    totalMoney() {
	      let total = 0
	      this.dataList.forEach(item => {
	        total =
	          (total * 1000 + (Number(item.quantity || 0) * 1000 * (Number(item.price || 0) * 1000)) / 1000) / 1000
	      })
	      return total
	    },
	    validGroupIndex(rows) {
			rows.forEach(el => {
				var count = 0
				rows.forEach(em => {
					if(em.groupIndex == el.groupIndex) {
						count++
					}
					if(count > 1) {
						throw "同一分组下只能选一种商品【" + el.proName + "】【" + em.proName + "】";
					}
				})
			})
		},
		proTypeName(type) {
			var res = ''
			this.proTypeList.forEach(el => {
				if(el.dataKey == type) {
					res = el.dataValue
				}
			})
			return res
		},
		validDataList(list) {
            list.forEach(el => {  
                if(typeof el.weight !== 'number' || el.weight <= 0) {
                    throw "净含量必须大于0【" + el.proName + "(" + el.skuName + ")" + "】"
                }              
            })          
        },
	}
}