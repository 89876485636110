import request from '@/plugin/axios'

/**
 *获取用户列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetUserList(data) {
    return request({
        url: '/user/user/list',
        method: 'get',
        params: data
    })
}

/**
 *获取角色列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetRoleList(data) {
    return request({
        url: '/permission/tsysRole/list',
        method: 'get',
        params: data
    })
}

/**
 *根据用户ID获取角色列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetRolesByUserId(data) {
    return request({
        url: '/permission/tsysRoleUser/getRolesByUserId',
        method: 'get',
        params: data
    })
}

/**
 *更新用户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateUser(data) {
    return request({
        url: '/user/user/update',
        method: 'post',
        data: data
    })
}

/**
 *新增用户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddUser(data) {
    return request({
        url: '/user/user/create',
        method: 'post',
        data: data
    })
}

/**
 *绑定用户角色
 *
 * @export
 * @param {*} data
 * @returns
 */
export function BindRoleUser(data) {
    return request({
        url: '/permission/tsysRoleUser/binDing',
        method: 'post',
        data: data
    })
}

/**
 *重置密码
 *
 * @export
 * @param {*} data
 * @returns
 */
export function ResetPassword(data) {
    return request({
        url: '/user/user/resetpass',
        method: 'post',
        data: data
    })
}

/**
 *删除用户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteUser(data) {
    return request({
        url: '/user/user/deletes',
        method: 'post',
        data: data
    })
}

/**
 *获取菜单列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetMenusTree(data) {
    return request({
        url: '/permission/tsysRoleModlePermission/initMenusTreeService',
        method: 'get',
        params: data
    })
}
/**
 *删除角色
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteRole(data) {
    return request({
        url: '/permission/tsysRole/delete',
        method: 'post',
        data: data
    })
}

/**
 *更新角色
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateRole(data) {
    return request({
        url: '/permission/tsysRole/update',
        method: 'post',
        data: data
    })
}

/**
 *新增角色
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddRole(data) {
    return request({
        url: '/permission/tsysRole/createRole',
        method: 'post',
        data: data
    })
}

/**
 *绑定角色菜单关系
 *
 * @export
 * @param {*} data
 * @returns
 */
export function BindModule(data) {
    return request({
        url: '/permission/tsysRoleModlePermission/updateModlePermission',
        method: 'post',
        data: data
    })
}

/**
 *更新用户密码
 *
 * @export
 * @param {*} data
 * @returns
 */
export function UpdateMyPassword(data) {
    return request({
        url: '/user/user/updateMyPassword',
        method: 'post',
        data: data
    })
}

/**
 *根据角色ID获取用户角色绑定列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetRoleUserByRoleId(data) {
    return request({
        url: '/permission/tsysRoleUser/queryByRoleId',
        method: 'get',
        params: data
    })
}
/**
 *校验供应商用户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function CheckUserSupplier(data) {
    return request({
        url: '/kitchen/CusSupplier/getCusCupplierByUserId',
        method: 'post',
        data: data
    })
}
export function checkRoleName(data) {
    return request({
        url: '/permission/tsysRole/checkRoleName',
        method: 'post',
        data: data
    })
}

