import request from '@/plugin/axios'

/**
 *获取客户列表
 *
 * @export
 * @param {*} data
 * @returns
 */
export function GetCusSupplierList(data) {
    return request({
        url: '/kitchen/CusSupplier/page',
        method: 'get',
        params: data
    })
}
export function GetCusSupplierMyPageList(data) {
    return request({
        url: '/kitchen/CusSupplier/baseMypage',
        method: 'get',
        params: data
    })
}
/**
 *获取客户列表2-肖月银
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getSupplierBySkuIdList(data) {
    return request({
        url: '/kitchen/CusSupplier/getSupplierBySkuIdList',
        method: 'get',
        params: data
    })
}
/**
 *新增客户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function AddCusSupplier(data) {
    return request({
      url: '/kitchen/CusSupplier/create',
      method: 'post',
      data: data
    })
  }
  
  /**
   *编辑客户
   *
   * @export
   * @param {*} data
   * @returns
   */
  export function UpdateCusSupplier(data) {
    return request({
      url: '/kitchen/CusSupplier/update',
      method: 'post',
      data: data
    })
  }
export function updateCusSupplierOfEntity(data) {
    return request({
        url: '/kitchen/CusSupplier/updateCusSupplier',
        method: 'post',
        data: data
    })
}
/**
 *删除客户
 *
 * @export
 * @param {*} data
 * @returns
 */
export function DeleteCusSupplier(data) {
    return request({
      url: '/kitchen/CusSupplier/delete',
      method: 'post',
      data: data
    })
  }

/**
 * 删除skuMap
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
export function DeleteCusSupplierSkuMap(data) {
    return request({
        url: '/kitchen/SupplierSkuMap/delete',
        method: 'post',
        data: data
    })
}
export function getSupplierBySkuAndProductId(data) {
    return request({
        url: '/kitchen/CusSupplier/getSupplierBySkuAndProductId',
        method: 'post',
        data: data
    })
}

/**
 * 获取供应商采购订单
 * @param data
 * @returns {AxiosPromise}
 */
export function getSupplierPurchaseOrder(data) {
    return request({
        url: '/kitchen/PurchaseOrder/page',
        method: 'post',
        data: data
    })
}
/**
 * 启用或停用供应商
 * @param data
 * @returns {AxiosPromise}
 */
export function usinOrDisableSupllier(data) {
    return request({
        url: '/kitchen/CusSupplier/usinOrDisableSupllier',
        method: 'post',
        data: data
    })
}

/**
 * 基地删除供应商判断
 * @param data
 * @returns {AxiosPromise}
 */
export function checkBaseId(data) {
    return request({
        url: '/kitchen/CusSupplier/checkBaseId',
        method: 'post',
        data: data
    })
}

/**
 * 清空图片
 * @param data
 * @returns {AxiosPromise}
 */
export function deletePic(data) {
    return request({
        url: '/kitchen/CusSupplier/deletePic',
        method: 'post',
        data: data
    })
}

/**
 *根据用户获取权限供应商
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPermissionCusSuppliersByUserId(data) {
    return request({
      url: '/kitchen/cusSupplier/getPermissionCusSuppliersByUserId',
      method: 'get',
      params: data
    })
  }

  /**
 *根据供应商和商品Id查询该供应商关联的商品信息
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getProBySupplierIdAndSkuId(data) {
    return request({
        url: '/kitchen/supplierSkuMap/getProBySupplierIdAndSkuId',
        method: 'get',
        params: data
    })
}
