import {
    GetUserList
} from '@/api/operate.user'
export default {
    data() {
        return {
            RegExp: '/^-?\d*\.?\d+$/',
            TelePhone: /^1[34578]\d{9}$/,
            LicensePlate: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/,
            CarZuowei: /^(\\\d+)|((\\s&&[^\\f\\n\\r\\t\\v])*)$/,
            DriverPhone: /^(1[0-9]{10}$)|(0\\\d{2}\\\d{8}(-\\\d{1,4})?$)|(0\\\d{3}\\\d{7,8}(-\\\d{1,4})?$)$/,
            ThePassword: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
            RiskWarning: /^[0-9]+([.]{0,1}[0-9]+){0,1}$/,
            CoordinateValue: /^(\-\+)?\\\d+(\.\\\d{1,6})?$/,
            CheckArea: /^(\-\+)?\\\d+(\.\\\d{1,2})?$/,
            ContactPhone: /^(1[0-9]{10})|(0\\\d{2}\\\d{8}(-\\\d{1,4})?)|(0\\\d{3}\\\d{7,8}(-\\\d{1,4})?)$/,
            personAge: /^\\\d+$/,
            MobilePhone: /^1[3|4|5|8|7|9][0-9]{9}$/,
            NumberDays: /^100$|^(\d|[1-9]\d)$/,
            ProductCoding: /^[A-Za-z0-9]+$/,
            PriceRegular: /^[0-9]+(.[0-9]{2})?$/,
            ContactPhone1: /^1\d{10}$/,
            ResidentIdentityCard: /^(\d{15}|\d{17}(\d|X))$/,
            ContactPhone2: /^1[34578]\d{9}$/,
            CheckValue: /^((\\s&&[^\\f\\n\\r\\t\\v])*)|(\\\d+)$/,
            ZhengShu: /^\d+$/,
            ContactPhone3: /^[0-9]+$/,
            ContactPhone4: /^1[0-9]{10}$/,
            regPassword: /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{6,16}$/,
            regIDCard: /^(\d{15}|\d{17}(\d|X))$/,
            regFloat: /^(\-\+)?\\\d+(\.\\\d{1,2})?$/,
            priceFloat: /^([1-9]\d*|0)(\.\d{1,2})?$/,
            fuDainTwo: /^(([1-9][0-9]*)\\.([0-9]{2}))|[0]\\.([0-9]{2})$/,
            // regFloat: /^(\-)?\d+(\.\d{1,2})?$/,
            regFloatPlus: /^\d+(\.\d{1,2})?$/,
            regEmail: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            reglocalRFID: /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{24}$/,
            regclaCode: /^[\w\s]*$/,
            regFloat4Plus: /^\d+(\.\d{1,4})?$/,
            regFloat3Plus: /^\d+(\.\d{1,3})?$/,
            positiveInt0: /^(0|\+?[1-9][0-9]*)$/, //正整数和0
            regNumber: /^[0-9]*$/,
            regInt:/^[1-9]\d*$/, //正整数
            regId: /^[0-9A-Z]+$/ ,
        }
    },
    methods: {
        _checkregId(){
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regId.test(val)) {
                        callback(new Error('只能是数字和大写字母组合'))
                    } else {
                        if(!(val.length==18||val.length==19||val.length==20||val.length==17||val.length==15)){
                            callback(new Error('只能是位数字和大写字母组合,长度为18、19、20、17、15位数'))
                        }
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleLength(num) {
            return {
                validator: function (rule, value, callback) {

                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > num) {
                        callback(new Error('长度不可超过' + num + '个字符'))
                    } else {
                        callback();
                    }
                },

            }
        },
        _ruleFixedLength(num) {
            return {
                validator: function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length < num) {
                        callback(new Error('长度必须为' + num + '个字符'))
                    } else {
                        callback();
                    }
                },

            }
        },
        _ruleGtNum(num) {
            return {
                validator: function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (!Number(val)> num) {
                        callback(new Error('必须为大于' + num + '的数字'))
                    } else {
                        callback();
                    }
                },

            }
        },
        _ruleLoginName() {
            return {
                validator: (async function (rule, value, callback) {
                    const d = await GetUserList({
                        loginName: value == null ? '' : (value + '').trim()
                    });
                    if (d.state === 0 && d.aaData && d.aaData.length > 0) {
                        callback(new Error('该用户已存在'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _reglocalRFID() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (!this.reglocalRFID.test(val)) {
                        callback(new Error('长度为24位的字母与数字的组合'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _rulePassword() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (!this.regPassword.test(val)) {
                        callback(new Error('6-16位的数字、字母、字符组合至少两种'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleEmail() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (!this.regEmail.test(val)) {
                        callback(new Error('请输入正确的邮箱格式'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        /**
         *调用接口判断某个属性值是否已经存在
         *
         * @param {*} apiFun 接口方法名
         * @param {*} zhName 属性中文解释
         * @param {*} content 改属性所在的对象，非必传
         * @param {*} otherParams 可根据需要传入自定义的对象参数作为查询条件,非必传
         * @returns
         */
        _ruleExist({
            apiFun,
            zhName,
            content,
            otherParams
        }) {
            return {
                validator: (async function (rule, value, callback) {
                    if (typeof apiFun == "function") {
                        let obj = {}
                        value = value == null ? '' : (value + '').trim();
                        obj[rule.field] = value
                        if (typeof content == "object") {
                            if (content[rule.field] === value) { //判断该字段是否更改过，未更改则不验证，更改则验证
                                callback();
                            } else {
                                Object.assign(obj, typeof otherParams == "object" ? otherParams : {});
                                const d = await apiFun(obj);
                                if (d.state === 0 && d.aaData && d.aaData.length > 0) {
                                    callback(new Error(zhName + '已存在'))
                                } else {
                                    callback();
                                }
                            }
                        } else {
                            Object.assign(obj, typeof otherParams == "object" ? otherParams : {});
                            const d = await apiFun(obj);
                            if (d.state === 0 && d.aaData && d.aaData.length > 0) {
                                callback(new Error(zhName + '已存在'))
                            } else {
                                callback();
                            }
                        }
                    } else {
                        callback(new Error(zhName + '验证失败，传参错误'))
                    }
                }.bind(this)),

            }
        },
        _ruleMobile() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.MobilePhone.test(val)) {
                        callback(new Error('请输入正确的手机号码'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleIDCard() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regIDCard.test(val)) {
                        callback(new Error('请输入正确的身份证号'))
                    } else {
                        callback();
                    }
                }.bind(this)),
                trigger: "blur"    
            }
        },
        _ruleLicensePlate() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.LicensePlate.test(val)) {
                        callback(new Error('请输入正确的车牌号'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleTwoFloat() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regFloatPlus.test(val)) {
                        callback(new Error('请输入大于等于零的数字,最多保留两位小数'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleThreeFloat() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    var a = parseFloat(val);
                    if (val.length > 0 && !this.regFloat3Plus.test(val) || a == 0) {
                        callback(new Error('请输入大于零的数字,最多保留三位小数'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleFourFloat() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    var a = parseFloat(val);
                    if (val.length > 0 && !this.regFloat4Plus.test(val) || a == 0) {
                        callback(new Error('请输入大于零的数字,最多保留四位小数'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleRequired(name) {
            return {
                required: true,
                validator: (function(rule, value, callback) {
                    var val = $.trim(value)
                    if (val.length <= 0) {
                        callback(new Error(name + '不能为空'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleReg(reg, descript) { //通用验证正则表达式，reg 正则表达式  descript 错误描述
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !reg.test(val)) {
                        callback(new Error(descript));
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleNumberAndLetter() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.ProductCoding.test(val)) {
                        callback(new Error('只能是字母与数字的组合'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleclaCode() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regclaCode.test(val)) {
                        callback(new Error('只能包含数字、字母、下划线'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _rulepositInt() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.positiveInt0.test(val)) {
                        callback(new Error('只能是正整数和0'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _ruleInt() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regInt.test(val)) {
                        callback(new Error('请填写大于零的正整数'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
        _checkNumber() {
            return {
                validator: (function (rule, value, callback) {
                    var val = value == null ? '' : (value + '').trim();
                    if (val.length > 0 && !this.regNumber.test(val)) {
                        callback(new Error('只能是数字'))
                    } else {
                        callback();
                    }
                }.bind(this)),

            }
        },
    }
}
