<template>
  <el-dialog
    custom-class="jz-modal base-selection-dialog"
    :title="title"
    :visible="modal"
    :before-close="beforeClose"
    :close-on-click-modal="closeOnClickmodal"
    :width="width"
    append-to-body
    v-dialog-drag="true"
  >
    <div flex="dir:top">
      <!-- 搜索框 -->
      <div
        flex="dir:left"
        class="mg-b-10"
        v-if="typeof queryApi == 'function' && searchShow"
      >
        <el-input
          v-model="keyword"
          style="width: 240px"
          clearable
          :disabled="loading"
          @keyup.enter.native="handleSearch"
          :placeholder="placeholder"
        ></el-input>
        <slot name="customQuery"></slot>
        <el-button
          class="mg-l-10 sys-primary-btn"
          type="primary"
          :disabled="loading"
          @click="handleSearch"
          >查询</el-button
        >
      </div>
      <!-- 数据列表 -->
      <div
        class="base-border mg-b-10"
        flex-box="1"
        flex="dir:top"
        :style="{ height: tableMaxHeight + 'px' }"
      >
        <el-table
          ref="d2Crud"
          :data="dataList"
          v-loading="loading"
          stripe
          border
          style="width: 100%"
          :options="tableOptions"
          @current-change="handleCurrentRowChange"
          @row-dblclick="
            () => {
              if (!this.disableDBClick) this.submit();
            }
          "
          index-row
          :max-height="tableMaxHeight"
          highlight-current-row
        >
          <slot></slot>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="paginationShow" class="pagination-container">
      <el-pagination
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :page-sizes="pagination.pageSizeArr"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="typeof queryApi == 'function'"
      ></el-pagination>
    </div>

    <!-- 底部操作栏 -->
    <div slot="footer" class="dialog-footer flex-x-end">
      <el-button @click="cancel(false)" class="sys-btn">取消</el-button>
      <el-button type="primary" @click="submit" class="sys-primary-btn"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import dialogDrag from "@/libs/dialog-drag";
import util from "@/libs/util";
import mixin from "@/plugin/mixins/mixin";
import cloneDeep from "lodash/cloneDeep";
import pullAllBy from "lodash/pullAllBy";
import uniqBy from "lodash/uniqBy";
import assign from "lodash/assign";

export default {
  name: "single-select-dialog",
  mixins: [mixin],
  props: {
    searchShow: {
      default: true,
    },
    paginationShow: {
      default: true,
    },
    placeholder: {
      // 搜索框的默认提示
      default: "",
    },
    modal: {
      // 控制开关
      default: false,
    },
    title: {
      // 标题
      default: "",
    },
    closeOnClickmodal: {
      // 是否点击遮罩层可关闭
      default: false,
    },
    multiple: {
      // 是否多选
      default: false,
    },
    tableMaxHeight: {
      // 列表的最大高度，超出则在列表内出现滚动条
      default: 500,
    },
    tableOptions: {
      // 列表的options
      default: function() {
        return {};
      },
    },
    queryApi: {
      // 查询列表的接口
      default: function() {
        return {};
      },
    },
    haveChosenRows: {
      // 已选择的数据multiple
      default: function() {
        return [];
      },
    },
    haveChosenRowsKey: {
      // 已选择的数据的属性对应内部数据的主键
      default: "value",
    },
    keywordFields: {
      // 查询接口的关键字段名
      default: function() {
        return [];
      },
    },
    businessFiledName: {
      // 需要将列表数据的主键id转换成外部业务字段的字段名
      default: "",
    },
    outerDataList: {
      // 外部列表的数据
      default: function() {
        return [];
      },
    },
    keyField: {
      // 外部数据和内部数据之间关联的字段名
      default: "",
    },
    // 列表主键名，默认为id
    mainId: {
      default: "id",
    },
    submitHandle: {
      // 在执行提交之前需要进行的数据操作
      default: function() {},
    },
    apiParams: {
      default: function() {
        return {};
      },
    },
    //多选情况下实现复选框的默认禁用和启用 方法返回true or false 即可
    handleSelectable: {
      default: function() {},
    },
    uniqueRow: {
      default: false,
    },
    //弹窗宽度
    width: {
      default: "50%",
    },
    //是否禁用双击行事件
    disableDBClick: {
      default: false,
    },
    children: {
      default: function() {
        return [];
      },
    },
    hasChildren: {
      default: false,
    },
    keyWordField: {
      default: "",
    },
    /**
     * 选中项的样式，forbidden或者selection,selection为勾选,默认为forbidden禁用
     */
    selectedType: {
      default: "forbidden",
    },
  },
  directives: {
    dialogDrag,
  },
  data() {
    return {
      dataList: [],
      keyword: "",
      loading: false,
      currentRow: {},
      selectRows: [],
      before: [],
      beforeDataList: [],
    };
  },
  async mounted() {
    await this.handleSearchTable();
  },
  methods: {
    getRowKey(row) {
      return row[this.mainId];
    },
    handleCurrentRowChange(currentRow, oldCurrentRow) {
      this.currentRow = currentRow;
    },
    async handleSearchTable() {
      const params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        keyword: this.keyword,
      };
      if (!util.isFalsy(this.keyWordField)) {
        params[this.keyWordField] = this.keyword;
      }
      params.keywordFields = this.keywordFields;
      assign(params, this.apiParams);
      if(this.keyword){
        params.keyword = this.keyword;
      }
      var result = {};
      result = await this.queryApi(params);
      this.dataList = result.aaData;
      if (util.listNotNull(this.dataList)) {
        this.pagination.total = result.dataCount;
      } else {
        this.pagination.currentPage = 1;
        this.pagination.total = 0;
      }
      this.loading = false;
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.handleSearchTable();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.handleSearchTable();
    },
    cancel(flag) {
      this.$emit("closemodal", flag);
    },
    async submit() {
      this.$emit("return-value", cloneDeep(this.currentRow));
      this.cancel(false);
    },
    beforeClose(done) {
      this.cancel(false);
      done();
    },
    async handleSearch() {
      this.handleCurrentChange(1);
    },
  },
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/single-select-dialog/index.vue"
}
</vue-filename-injector>
