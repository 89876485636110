// 创建 el-menu-item
export function elMenuItem(createElement, menu) {
  return createElement('el-menu-item', {
    props: {
      index: menu.path
    }
  }, [
    ...menu.icon ? [
      createElement('i', {
        attrs: {
          class: `fa fa-${menu.icon}`
        }
      })
    ] : [],
    ...menu.icon === undefined & !menu.iconSvg ? [
      createElement('i', {
        attrs: {
          class: 'fa fa-file-o'
        }
      })
    ] : [],
    ...menu.iconSvg ? [
      createElement('d2-icon-svg', {
        props: {
          name: menu.iconSvg
        }
      })
    ] : [],
    createElement('span', {
      slot: 'title'
    }, menu.title || '未命名菜单')
  ])
}

// 创建 el-submenu
export function elSubmenu(createElement, menu) {
  return createElement('el-submenu', {
    props: {
      index: menu.path
    }
  }, [
    ...menu.icon ? [
      createElement('i', {
        slot: 'title',
        attrs: {
          class: `fa fa-${menu.icon}`
        },
        on: {
          click: () => handleMenuSelect(this, menu)
        }
      })
    ] : [],
    ...menu.icon === undefined & !menu.iconSvg ? [
      createElement('i', {
        slot: 'title',
        attrs: {
          class: 'fa fa-file-o'
        },
        on: {
          click: () => handleMenuSelect(this, menu)
        }
      })
    ] : [],
    ...menu.iconSvg ? [
      createElement('d2-icon-svg', {
        slot: 'title',
        props: {
          name: menu.iconSvg
        },
        on: {
          click: () => handleMenuSelect(this, menu)
        }
      })
    ] : [],
    createElement('span', {
      slot: 'title',
      on: {
        click: () => handleMenuSelect(this, menu)
      }
    }, menu.title || '未命名菜单'),
    ...menu.children.map((child, childIndex) => (child.children === undefined ? elMenuItem : elSubmenu).call(this, createElement, child))
  ])
}

//为非末级菜单添加页面跳转功能
let handleMenuSelect = function (vm, menu) {
   let firstPath=getFirstPath(menu);
   vm.handleMenuSelect(firstPath,[firstPath]);
}
/**
 * 递归获取第一个菜单的路由
 * @param {*} menu 
 */
let getFirstPath = function (menu) {
  if (menu.hasChildren == 1) {
    return getFirstPath(menu.children[0]);
  } else {
    return menu.path;
  }
}