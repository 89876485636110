import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/CusDept'


var obj = {}
obj.page = function(data) {
	return util.baseRequest(data, rootURL + '/page')
}
obj.pageAllByCusId = async function(cusId) {
	if(util.isFalsy(cusId)) {
		return []
	}
	try {
		var res = await util.baseRequest({cusId, pageSize: -1}, rootURL + '/page')
		return res.aaData
	} catch (error) {
		return []
	}	
}
obj.add = function(data) {
	return util.baseRequest(data, rootURL + '/create')
}
obj.update = function(data) {
	return util.baseRequest(data, rootURL + '/update')
}
obj.confirm = function(data) {
	return util.baseRequest(data, rootURL + '/confirm')
}
obj.cancel = function(data) {
	return util.baseRequest(data, rootURL + '/cancel')
}
obj.delete = function (data) {
	return util.baseRequest(data, rootURL + '/delete');
}
obj.listByCusId = function (data) {
	return util.baseRequest(data, rootURL + '/listByCusId');
}
export default obj