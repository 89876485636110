import request from '@/plugin/axios'
import util from '@/libs/util.js'

var rootURL = '/kitchen/OrderExternal'

var rootSatelliteKitchen = '/kitchen/SatelliteKitchen'

/**
 * 打印采购单
 *
 * @export
 * @param {*} data
 * @returns
 */
export function getPrintData(data) {
  return request({
    url: '/kitchen/OrderExternal/getPrintData',
    method: 'get',
    params: data
  })
}

var obj = {}
obj.getInventBySkuIds = function(data) {
  return util.baseJsonRequest(data, rootURL + '/getInventBySkuIds')
}
obj.mrpBomTree = function(data) {
  return util.baseJsonRequest(data, rootURL + '/mrpBomTree')
}
obj.generateReport = function(data) {
  return util.baseJsonRequest(data, rootURL + '/generateReport')
}
obj.createDemandOrder = function(data) {
  return util.baseJsonRequest(data, rootURL + '/createDemandOrder')
}
obj.createProductByProTmp = function(data) {
  return util.baseJsonRequest(data, rootURL + '/createProductByProTmp')
}
obj.pageMealplanProduct = function(data) {
  return util.baseRequest(data, rootURL + '/pageMealplanProduct')
}
obj.mrpSuggestSubmit = function(data) {
  return util.baseJsonRequest(data, rootURL + '/mrpSuggestSubmit')
}
obj.mrpSuggestSubmitProgressing = function(data) {
  return util.baseJsonRequest(data, rootURL + '/mrpSuggestSubmitProgressing')
}
obj.createTaskByMrp = function(data) {
  return util.baseJsonRequest(data, rootURL + '/createTaskByMrp')
}
obj.orderItemSumDecompose = function(data) {
  return util.baseJsonRequest(data, rootURL + '/orderItemSumDecompose')
}
obj.skuPage = function(data) {
  return util.baseRequest(data, rootURL + '/skuPage')
}
obj.skuSXPage = function(data) {
  return util.baseRequest(data, rootURL + '/skuSXPage')
}
obj.skuSXPageT = function(data) {
  return util.baseRequest(data, rootURL + '/skuSXPageT')
}
obj.satelliteKitchenPage = function(data) {
  return util.baseJsonRequest(data, rootSatelliteKitchen + '/page')
}
obj.queryPageList = function(data) {
  return util.baseRequest(data, rootSatelliteKitchen + '/queryPageList')
}

obj.orderExcelImport = function(data) {
  return util.baseJsonRequest(data, rootURL + '/orderExcelImport')
}

obj.isConfEnable = function(data) {
  return util.baseRequest(data, rootURL + '/isConfEnable')
}
export default obj
