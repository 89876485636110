<template>
  <el-tooltip effect="dark" content="监控系统" placement="bottom">
    <el-button class="d2-mr btn-text can-hover" type="text" @click="handleClick">
      <d2-icon name="video-camera" style="font-size: 18px;" />
    </el-button>
  </el-tooltip>
</template>

<script>
import mixin from '@/plugin/mixins/mixin'
export default {
  mixins: [mixin],
  data() {
    return {
      systemPath: ''
    }
  },
  mounted() {},
  methods: {
    handleClick() {
      let path = this._searchDic('MONITORING_SYSTEM_PATH')
      this.systemPath = path[0].dataValue
      if (!this.systemPath) {
        this.$message({
          type: 'error',
          message: '字典未配置监控系统地址！'
        })
        return
      }
      this.$confirm('将打开监控系统,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          window.open(this.systemPath, '_blank')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-beidou/index.vue"
}
</vue-filename-injector>
