<template>
  <div class="multiple-format__container">
    <el-input :value="singleValue" v-bind="$attrs" :readonly="true" style="width:100%" autocomplete="off" ref="mfInput">
      <template slot="suffix">
        <slot name="suffix"></slot>
      </template>
    </el-input>
    <!-- tag 显示层 -->
    <div ref="mfSelect" class="mf-select">
      <span class="mf-select__content"></span>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'MultipleFormat',
  props: {
    value: [Array, String],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataRow: [],
      dataValue: [],
      singleValue: '',
      tagRemove: null
    }
  },
  methods: {
    /**
     * @description 筛选数据，生成 {value,label} 结构
     * @summary 对外接口
     * @param {Array} rows 源数据
     * @param {String} labelText label 的过滤关键字
     * @param {String} valueText value 的过滤关键字
     * @returns {*} void
     */
    setList(rows, labelText = 'name', valueText = 'id', ...args) {
      if (!Array.isArray(rows)) {
        return
      }
      // 每次选中数据提交，都要清除上一次的数据
      this.clear()
      rows.map(item => {
        if (item[valueText] && item[labelText]) {
          var o = {
            value: item[valueText],
            label: item[labelText]
          }
          if (Array.isArray(args) && args.length > 0) {
            args.forEach(el => {
              o[el] = item[el]
            })
          }
          this.dataRow.push(o)
          this.dataValue.push(item[valueText])
        }
      })
      this.addTag()
      this.$emit('input', cloneDeep(this.dataValue))
      this.$emit('change', cloneDeep(this.dataRow))
    },
    /**
     * @description 生成 tags 显示层，生成并将 tag 挂在在显示层上
     * @param {*} void
     * @returns {*} void
     */
    addTag() {
      let spanBox = this.$refs.mfSelect.children[0]
      this.dataRow.map(item => {
        if (item) {
          spanBox.appendChild(this.createTag(item))
        }
      })
      this.reSetMfInputHeight()
    },
    reSetMfInputHeight() {
      if ((this.$refs.mfSelect.children[0].offsetWidth + 45) >= this.$refs.mfInput.$el.offsetWidth) {
        this.$refs.mfInput.$el.children[0].style.height = this.$refs.mfSelect.children[0].offsetHeight + 4 + 'px'
      } else {
        this.$refs.mfInput.$el.children[0].style.height = '28px'
      }
    },
    /**
     * @description 生成单个 tag，并添加 tag 样式及事件
     * @param {Object} data tag 的内容及 id
     * @returns {Object} 完整的 tag
     */
    createTag(data) {
      let tagParent = document.createElement('span')
      tagParent.className = 'mf-select__inner'
      tagParent.style.marginTop = '2px'
      tagParent.style.marginBottom = '2px'
      tagParent.title = data.label

      let tag = document.createElement('span')
      tag.className = 'mf-select__text'
      tag.textContent = data.label
      tagParent.appendChild(tag)

      if (!this.disabled) {
        let tagIcon = document.createElement('i')
        tagIcon.className = 'el-icon-close mf-select__close'
        tagIcon.style.transition = 'all 0.3s'

        tagIcon.onmouseover = function () {
          this.style.color = "#fff";
          this.style.backgroundColor = '#ff4e4e'
          // this.style.transform = 'scale(1)'
        }
        tagIcon.onmouseout = function () {
          this.style.color = "#93a0ad";
          this.style.backgroundColor = '#e7e5e5'
          // this.style.transform = 'scale(1)'
        }
        tagParent.appendChild(tagIcon)
        if (!tagIcon.addEventListener) {
          return
        }
        // tag 移除事件
        this.tagRemove = () => {
          // 移除当前 tag 节点
          tagParent.parentNode.removeChild(tagParent)
          // 更新数据
          this.dataRow.map((item, index) => {
            if (item.value === data.value) {
              this.dataRow.splice(index, 1)
              this.dataValue.splice(index, 1)
            }
          })
          this.reSetMfInputHeight()
          this.$emit('input', cloneDeep(this.dataValue))
          this.$emit('change', cloneDeep(this.dataRow))
        }

        tagIcon.addEventListener('click', this.tagRemove, false)
      }

      return tagParent
    },
    /**
     * @description 清除数据，关闭 tag 层
     */
    clear() {
      this.dataRow = []
      this.dataValue = []
      // 移除 tag 节点
      let spanBox = this.$refs.mfSelect.children[0]
      if (!spanBox) {
        return
      }
      let leng = spanBox.childNodes.length
      // 循环遍历所有子节点，并删除
      for (let i = 0; i < leng; i++) {
        if (spanBox.childNodes[i] && spanBox.childNodes[i].parentNode) {
          spanBox.childNodes[i].parentNode.removeChild(spanBox.childNodes[i])
          i--
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.multiple-format__container {
  position: relative;
  .el-input--suffix {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    text-align: center;
    .el-input__suffix-inner {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  .mf-select {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 30px;
    transform: translate(0, -50%);
    line-height: normal;
    white-space: normal;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // height: 28px;
    overflow-y: hidden;
    overflow-x: auto;
    .mf-select__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // height: 100%;
      position: relative;
      .mf-select__inner {
        position: relative;
        margin-left: 7px;
        display: flex;
        align-items: center;
        font-size: 12px;
        border-radius: 2px;
        box-sizing: border-box;
        height: 20px;
        padding: 0 2px 0 4px;
        color: #969faa;
        border: 1px solid #eaecee;
        background-color: #f5f5f7;
        &:first-child {
          margin-left: 0;
        }
        .mf-select__text {
          display: inline-block;
          max-width: 60px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
        .mf-select__close {
          color: #93a0ad;
          border-radius: 50%;
          text-align: center;
          transform: scale(0.7);
          cursor: pointer;
          margin-left: 2px;
          height: 16px;
          width: 16px;
          line-height: 16px;
          vertical-align: middle;
          background-color: #e7e5e5;
        }
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/format-input/multiple-format.vue"
}
</vue-filename-injector>
