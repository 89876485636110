import { getNewCount, readMsg, getMsgList, allReadMsg } from '@/api/message'
import ssocket from "@/plugin/webSocket/myWebSocket";
export default {
    mixins: [ssocket],
    watch: {
        activeStatus(val) {
            this.queryForm.status = val
            if (val == -1) {
                this.queryForm.status = null;
            }
            this.queryForm.pageNum = 1;
            this.queryForm.pageSize = 10;
            this.getList()
        }
    },
    data() {
        return {
            // 新消息条数
            count: {
                newDataCount: 0,
                newCount: 0
            },

            dialogVisible: false,
            MSGdialogVisible: false,
            counts: 0,
            // 历史消息
            dataList: [],
            dataCount: 0,
            queryForm: {
                pageNum: 1,
                pageSize: 10,
                status: '',
                remindUserId: this.$store.state.zycf.user.userinfo.userId
            },
            // 显示详情
            showDetails: false,
            massageDetail: '',
            activeStatus: 0,
            statusList: [{
                id: -1,
                name: '全部消息'
            }, {
                id: 0,
                name: '未读'
            }]
        }
    },
    methods: {
        getMessage: function(msg) {
            var obj = JSON.parse(msg.data)
            const h = this.$createElement;
            let _this = this;
            this.initNewMsgCount();
            if (obj.msg) {
                if (obj.msg.indexOf('连接') !== -1) {
                    return
                } else {
                    this.$notify({
                        title: '消息通知',
                        duration: 2500,
                        message: h('i', { style: 'color: teal' }, obj.msg),
                        onClick() {
                            _this.defineCallBack();
                        }
                    });
                }
            }
        },
        initPage() {
            this.getList()
            this.initNewMsgCount()
        },
        //获取全部消息条数
        async initNewMsgCount() {
            let res = await getNewCount({
                remindUserId: this.$store.state.zycf.user.userinfo.userId,
                allowedRepeat: true
            })
            if (res.state === 0) {
                var _this = this;
                _this.$set(_this.count, 'newDataCount', res.aaData)
            }
        },
        //获取消息列表
        async getList() {
            let o = {
                ...this.queryForm,
                orderBy: 'status',
                asc: true,
            }
            let res = await getMsgList(o)
            if (res.state === 0) {
                this.dataList = res.aaData
            }
        },
        //关闭消息弹框
        close() {
            this.MSGdialogVisible = false;
        },
        //关闭审批弹框
        closeSP() {
            this.dialogVisible = false;
        },
        //获取消息详情
        async getMsgDetails(d) {
            this.showDetails = true;
            //通过消息id查询消息详情
            this.massageDetail = d;
            //将新消息删掉，更改状态为未读
            const re = await readMsg(d.id);
            if (re.state == 99) {
                this.$message({ type: "info", message: re.msg });
            }
            this.initPage();
        },
        //返回消息详情界面
        backMsgList() {
            if (this.showDetails) {
                this.showDetails = !this.showDetails
            }
            this.initPage();
        },
        //无线滚动加载列表
        load() {
            this.queryForm.pageSize += 10;
            this.getList();
        },
        //全部读取消息
        async allRead() {
            this.$confirm('是否全部置为已读?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                const re = await allReadMsg();
                if (re.state == 0) {
                    this.$message({ type: "info", message: "消息已全部置为已读" });
                }
                this.initPage();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            });
        },
        //点击查看订单
        seeOrderList() {
            this.MSGdialogVisible = false;
            this.$parent.openBackLog();
        },
        //打开消息弹框
        showDialog() {
            this.MSGdialogVisible = true
            this.showDetails = false;
            this.activeStatus = 0;
            this.queryForm.status = 0;
            this.initPage();
            // this.initNewMsgCount();
        },
        //从详情页面点击右侧菜单
        activeStatusClick(d) {
            this.activeStatus = d.id;
            this.showDetails = false;
        },
        defineCallBack() {
            this.MSGdialogVisible = true;
            this.activeStatus = 0;
            this.queryForm.status = 0;
            this.initPage();
        }
    }

}